import { Button, Card } from 'react-bootstrap';

const STATUS_OPTIONS = {
  OPERATIONAL: 'operational',
  UNDER_MAINTENANCE: 'under_maintenance',
  DEGRADED_PERFORMANCE: 'degraded_performance',
  PARTIAL_OUTAGE: 'partial_outage',
  MAJOR_OUTAGE: 'major_outage',
};

const StatusRow = ({ name, status }) => {
  let statusText = null;
  // eslint-disable-next-line default-case
  switch (status) {
    case STATUS_OPTIONS.OPERATIONAL:
      statusText = <span className='text-success'>● Operational</span>;
      break;
    case STATUS_OPTIONS.UNDER_MAINTENANCE:
      statusText = <span className='text-warning'>● Under maintenance</span>;
      break;
    case STATUS_OPTIONS.DEGRADED_PERFORMANCE:
      statusText = <span className='text-muted'>● Degraded performance</span>;
      break;
    case STATUS_OPTIONS.PARTIAL_OUTAGE:
      statusText = <span className='text-danger'>● Partial System outage</span>;
      break;
    case STATUS_OPTIONS.MAJOR_OUTAGE:
      statusText = <span className='text-danger'>● Major System outage</span>;
  }
  return (
    <div className='d-flex justify-content-between border-bottom-not-last py-3'>
      <h4 className='card-header-title align-items-center d-flex'>{name}</h4>
      {statusText}
    </div>
  );
};

const SystemStatus = ({ status }) => {
  return (
    <Card className='w-100 h-100 info-card'>
      <Card.Header>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='card-header-title text-capitalize'>System status</h4>
          <Button
            className='px-0'
            variant='link'
            href={status?.url}
            target='_blank'
          >
            View all
          </Button>
        </div>
      </Card.Header>
      <Card.Body className='py-3'>
        <div className='d-flex flex-column'>
          {status?.components.map(
            ({ name, status: componentStatus }, index) => (
              <StatusRow key={index} name={name} status={componentStatus} />
            )
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default SystemStatus;
