import { Dropdown, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FILTER_STATE } from "../../../../core/constants";
import { CustomDropdownToggle } from "../../components";

const SimCardTable = ({
  getTableProps,
  headerGroups,
  processing,
  totalSims,
  getTableBodyProps,
  handleSimStateChange,
  prepareRow,
  page,
  selectedRowIds,
  filter,
  setSelectedSim,
  setIsSmsModalOpen,
  setIsDeleteSimModalOpen,
  setIsAssignNetworkModalOpen,
}) => {
  const navigate = useNavigate();
  return (
    <div className={page.length <= 3 ? "" : "table-responsive"}>
      <table className="table table-sm table-hover table-nowrap card-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  style={{ width: "10px" }}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      // This is to span last header because there is additional td (ellipsis menu)
                      colSpan: i === headerGroup.headers.length - 1 ? 2 : 1,
                    }),
                  )}
                >
                  {column.render("Header")}
                  {column.id !== "selection" && (
                    <span className="lis-sort text-muted">
                      {column.isSorted && (
                        <>
                          {column.isSortedDesc && <i className="fe fe-chevron-down" />}
                          {!column.isSortedDesc && <i className="fe fe-chevron-up" />}
                        </>
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {processing && (
          <tbody>
            <tr>
              <td colSpan={100}>
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="dark" />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!processing && totalSims === 0 && (
          <tbody>
            <tr>
              <td colSpan={100}>
                <div className="d-flex justify-content-center">
                  <h3 className="py-2 m-0">
                    {/* TODO Render this without filter */}
                    {Object.keys(filter).length > 0 ? "No SIM was found matching your criteria" : "No data"}
                  </h3>
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!processing && (
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="cursor-pointer"
                        onClick={() => {
                          if (cell.column.id !== "selection") {
                            navigate(`/dashboard/sim-cards/${row.original.iccid}`);
                          }
                        }}
                      >
                        {cell.column.id === "sim_state" && (
                          <>
                            {cell.value === FILTER_STATE.ENABLED && <span className="me-1 text-success">●</span>}
                            {cell.value === FILTER_STATE.DISABLED && <span className="me-1 text-danger">●</span>}
                            {cell.value === FILTER_STATE.INSUFFICIENT_FUNDS && (
                              <span className="me-1 text-orange">●</span>
                            )}
                          </>
                        )}
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  {!!Object.keys(selectedRowIds).length && (
                    <td className="text-end">
                      <Dropdown className="ellipsis-menu-height">
                        <div className="dropdown-ellipses dropdown-toggle" role="button">
                          <div className="ellipsis">
                            <i className="fe fe-more-vertical" />
                          </div>
                        </div>
                      </Dropdown>
                    </td>
                  )}
                  {!Object.keys(selectedRowIds).length && (
                    <td className="text-end">
                      <Dropdown drop="end" className="ellipsis-menu-height">
                        <Dropdown.Toggle as={CustomDropdownToggle}>
                          <div className="dropdown-ellipses dropdown-toggle" role="button">
                            <div className="ellipsis">
                              <i className="fe fe-more-vertical" />
                            </div>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Header>STATE</Dropdown.Header>
                          <Dropdown.Item
                            className="py-1"
                            eventKey="1"
                            onClick={() => handleSimStateChange(row.cells[1].value, "enabled")}
                          >
                            <i className="fe fe-play me-1" />
                            <span>Enable</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="py-1"
                            eventKey="2"
                            onClick={() => handleSimStateChange(row.cells[1].value, "disabled")}
                          >
                            <i className="fe fe-pause me-1" />
                            <span>Disable</span>
                          </Dropdown.Item>
                          <Dropdown.Divider className="mx-3" />
                          <Dropdown.Header>SMS</Dropdown.Header>
                          <Dropdown.Item
                            className="py-1"
                            eventKey="3"
                            onClick={() => {
                              setSelectedSim(row.cells[1].value);
                              setIsSmsModalOpen(true);
                            }}
                          >
                            <i className="fe fe-send me-1" />
                            <span>Send</span>
                          </Dropdown.Item>
                          <Dropdown.Divider className="mx-3" />
                          <Dropdown.Header>NETWORK</Dropdown.Header>
                          <Dropdown.Item
                            className="py-1"
                            eventKey="3"
                            onClick={() => {
                              setSelectedSim(row.cells[1].value);
                              setIsAssignNetworkModalOpen(true);
                            }}
                          >
                            <i className="fe fe-upload-cloud me-1" />
                            <span>Assign</span>
                          </Dropdown.Item>
                          <Dropdown.Divider className="mx-3" />

                          <Dropdown.Item
                            className="py-1"
                            eventKey="3"
                            onClick={() => {
                              setSelectedSim(row.cells[1].value);
                              setIsDeleteSimModalOpen(true);
                            }}
                          >
                            <span className="text-danger">Delete SIM card</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default SimCardTable;
