import { Auth } from "aws-amplify";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import { customerIoTrack } from "src/lib/utils/CustomerIo/customerIoUtils";
import { setItem } from "src/lib/utils/localStorage";
import { EnterCodeForm, ForgotPasswordForm, NewPasswordForm, SignInForm } from "../index";

const FORM_TYPES = {
  SIGN_IN: "SIGN_IN",
  RESET_PASSWORD: "RESET_PASSWORD",
  CODE: "CODE",
  NEW_PASSWORD: "NEW_PASSWORD",
};

const SignIn = () => {
  console.log("signin");
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const code = params.get("code");
  const email = params.get("email");

  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [formType, setFormType] = useState(code ? FORM_TYPES.NEW_PASSWORD : FORM_TYPES.SIGN_IN);

  const handleSubmit = async ({ email, password, newPassword }, { setSubmitting }) => {
    try {
      let currentUser;
      const user = await Auth.signIn(email.toLowerCase(), password);
      currentUser = user;

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        currentUser = await Auth.completeNewPassword(user, newPassword);
      }

      setItem("role", currentUser?.signInUserSession?.accessToken?.payload?.["cognito:groups"]?.[0] || null);
      // customerIoTrack({ name: "signedIn", data: { email: user.attributes.email } });
      setSubmitting(false);
      navigate("/dashboard");
    } catch (error) {
      setSubmitting(false);
      setErrorMessage(error?.message);
    }
  };

  const handleResetPassword = async ({ email }, { setSubmitting }) => {
    setErrorMessage("");
    try {
      const lowercaseEmail = email.toLowerCase();
      const codeDelivery = await Auth.forgotPassword(lowercaseEmail);
      setUser({
        ...codeDelivery,
        user: {
          username: lowercaseEmail,
        },
      });
      setFormType(FORM_TYPES.CODE);
    } catch (error) {
      setErrorMessage(error?.message);
    }
    setSubmitting(false);
  };

  const handleEnterCode = async ({ code, password }, { setSubmitting }) => {
    setErrorMessage("");
    try {
      await Auth.forgotPasswordSubmit(user?.user?.username, code, password);
      setFormType(FORM_TYPES.SIGN_IN);
    } catch (error) {
      setErrorMessage(error?.message);
    }
    setSubmitting(false);
  };

  const handleForgetPasswordClick = (e) => {
    e.preventDefault();
    setFormType(FORM_TYPES.RESET_PASSWORD);
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col sm={12} md={5} lg={6} xl={4} className="px-lg-6 my-5 align-self-center">
          <h1 className="display-4 text-center mb-3">{code ? "Complete registration" : "Welcome back"}</h1>
          <p className="text-muted text-center mb-5">
            {code ? "Please enter your new password below." : "sign in to our dashboard."}
          </p>
          {formType === FORM_TYPES.SIGN_IN && (
            <SignInForm onSubmit={handleSubmit} onForgetPassword={handleForgetPasswordClick} />
          )}
          {formType === FORM_TYPES.RESET_PASSWORD && (
            <ForgotPasswordForm onSubmit={handleResetPassword} goBack={() => setFormType(FORM_TYPES.SIGN_IN)} />
          )}
          {formType === FORM_TYPES.CODE && <EnterCodeForm user={user} onSubmit={handleEnterCode} />}
          {formType === FORM_TYPES.NEW_PASSWORD && (
            <NewPasswordForm code={code} email={email} onSubmit={handleSubmit} />
          )}
          <span className="text-danger">{errorMessage}</span>
        </Col>
        <Col sm={12} md={7} lg={6} xl={8} className=" d-none d-lg-block px-0">
          <div className="bg-cover h-100 min-vh-100 bg-sign-in" />
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
