import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { FormField } from '../../../auth';

const ForgotPasswordForm = ({ onSubmit, goBack }) => {
  return (
    <Formik initialValues={{ email: '' }} onSubmit={onSubmit}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <h3 className='text-center'>Forgot your password?</h3>
          <div className='pb-3 text-center'>
            Enter your Email below and we will send a message to reset your
            password
          </div>
          <FormField
            value={values.email}
            handleChange={handleChange}
            touched={touched.email}
            handleBlur={handleBlur}
            error={errors.email}
            placeholder='name@address.com'
            name='email'
            type='email'
            label='Email'
          />
          <Button
            type='submit'
            variant='primary'
            className='w-100 mb-3 mt-3 lift'
            disabled={isSubmitting}
          >
            Reset my password
          </Button>
          <div className='text-center'>
            <small className='text-muted text-center'>
              <span
                className='cursor-pointer btn-link'
                onClick={(e) => {
                  e.preventDefault();
                  goBack();
                }}
              >
                I know my password
              </span>
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
