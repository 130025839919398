import smallCreditCard from "@assets/img/small-cc.png";
import ErrorMessage from "@components/ErrorMessage";
import Loader from "@components/Loader";
import { STRIPE_PUBLISHABLE_KEY } from "@core/constants";
import { useEditCustomerMutation, useGetCustomerQuery } from "@modules/dashboard/account/account-api-slice";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { IError } from "src/types/types";
import { SelectBox } from "../../components";
import { useCreatePaymentMutation, useGetBillingBalanceQuery, useGetCountriesQuery } from "../billing-api-slice";
import { usePaymentMethods } from "../hooks/usePaymentMethods";
import { CheckoutForm } from "../index";
import AmountSelector from "./AmountSelector";
import BillingDetailsForm from "./BillingDetailsForm";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, {
  locale: "en",
});

const FORM_STEPS = {
  BILLING_DETAILS: "BILLING_DETAILS",
  PAYMENT: "PAYMENT",
  LOADING: "LOADING",
};

const PaymentIntent = ({ isOpen, closeModal, initialVat = 1 }) => {
  // EditCustomer

  const [editCustomer] = useEditCustomerMutation();

  // GetCustomer
  const getCustomerResponse = useGetCustomerQuery();
  const details = getCustomerResponse?.data?.customerDetails ?? {};

  const { company_name, address, invoice, tax, email } = details;

  const { zip, state, city, country, street1, street2 } = address || {};
  const { invoice_ref, invoice_attendee } = invoice || {};
  const { tax_number } = tax || {};
  const { email_invoice } = email || {};

  const [createPayment, createPaymentResponse] = useCreatePaymentMutation();

  const { paymentMethods: methods } = usePaymentMethods();

  const countriesResponse = useGetCountriesQuery();
  const { data: countries } = countriesResponse;

  const balanceResponse = useGetBillingBalanceQuery();
  const { currency_symbol: currencySign, currency = "EUR" } = balanceResponse.data ?? {}; // renaming vars to fit current code

  const initialValue = "";
  const [value, setValue] = useState(initialValue);
  const [values, setValues] = useState<CurrencyInputOnChangeValues>();
  const [isValueValid, setIsValueValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [step, setStep] = useState(FORM_STEPS.LOADING);
  const [vat, setVat] = useState(initialVat);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setStep(FORM_STEPS.BILLING_DETAILS);
  }, []);

  const handleProceed = async () => {
    console.log("processing payment...");
    setProcessing(true);
    setErrorMessage("");

    interface Options {
      amount_in_cents: number;
      currency: string;
      payment_method?: string;
    }
    const options: Options = {
      amount_in_cents: Math.round(values?.float * vat * 100),
      currency,
    };

    if (paymentMethod.value) {
      options.payment_method = paymentMethod.value;
    }

    const response = await createPayment(options);

    if ("data" in response) {
      setClientSecret(response.data.client_secret);
    }

    const error = (createPaymentResponse.error as string) || null;

    if (error) {
      setErrorMessage(error);
    }

    setProcessing(false);
  };

  const handleCloseModal = () => {
    setClientSecret("");
    setValue(initialValue);
    setValues(undefined);
    setIsValueValid(false);
    setPaymentMethod(null);
    setErrorMessage("");
    closeModal();
  };

  const handleBillingFormSubmit = async (
    {
      country,
      attentionTo,
      primaryAddress,
      secondaryAddress,
      zipCode,
      state,
      city,
      vat: tax_number,
      company_name,
      invoice_ref,
      email_invoice,
    },
    { setSubmitting },
  ) => {
    setErrorMessage("");
    const response = await editCustomer({
      company_name,
      address: {
        zip: zipCode,
        country,
        street1: primaryAddress,
        street2: secondaryAddress,
        state,
        city,
      },
      tax: {
        tax_number,
      },
      invoice: {
        invoice_attendee: attentionTo,
        invoice_ref,
      },
      email: {
        email_invoice,
      },
    }); // FIXME: check the situation out here. That payload should not exist anymore

    setSubmitting(false);
    if ("data" in response && "errorMessage" in response.data) {
      setErrorMessage(response.data.errorMessage);
    } else {
      if ("data" in response) {
        setVat(1 + response?.data?.customerDetails.tax);
        setStep(FORM_STEPS.PAYMENT);
      }
    }
  };

  useEffect(() => {
    if (isOpen && Object.keys(details).length) {
      setStep(FORM_STEPS.BILLING_DETAILS);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const formatOptionLabel = ({ label, newMethod }) => {
    if (newMethod) {
      return (
        <div className="d-flex">
          <img className="me-3" src={smallCreditCard} height={24} width={28} alt="cc" />
          <strong className="">{label}</strong>
        </div>
      );
    }
    return <div>{label}</div>;
  };

  return (
    <Modal show={isOpen} onHide={handleCloseModal} centered backdrop="static">
      <div className="d-flex justify-content-end pt-3 me-3">
        <span onClick={handleCloseModal} className="fe fe-x cursor-pointer" />
      </div>
      <Modal.Body className="pt-0 px-5">
        {step === FORM_STEPS.LOADING && <Loader />}
        {step === FORM_STEPS.BILLING_DETAILS && (
          <div>
            <Formik
              initialValues={{
                country: country,
                attentionTo: invoice_attendee,
                primaryAddress: street1,
                secondaryAddress: street2,
                zipCode: zip,
                state: state,
                city: city,
                vat: tax_number,
                company_name,
                invoice_ref,
                email_invoice,
              }}
              validate={(values) => {
                const errors: IError = {};
                if (!values.country) {
                  errors.country = "Required";
                }
                if (!values.primaryAddress) {
                  errors.primaryAddress = "Required";
                }
                return errors;
              }}
              onSubmit={handleBillingFormSubmit}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit} className="w-100">
                  <BillingDetailsForm
                    errors={errors}
                    handleBlur={handleBlur}
                    countries={countries}
                    handleChange={handleChange}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <ErrorMessage message={errorMessage} dismissible onClose={() => setErrorMessage("")} />
                  <div className="d-flex justify-content-center mt-4">
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                      Continue
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {step === FORM_STEPS.PAYMENT && (
          <div>
            <>
              <div className={`${clientSecret ? "mb-3" : ""}`}>
                <AmountSelector
                  value={value}
                  setValue={setValue}
                  setValues={setValues}
                  disabled={(!processing && clientSecret ? true : false) || processing}
                  prefix={currencySign}
                  hasSubtitle={vat !== 1}
                  setIsValueValid={setIsValueValid}
                  subtitleText={`Amount to pay with VAT included ${currencySign}${
                    values?.float ? values.float * vat : 0
                  } `}
                />
                <div className="mt-4">
                  <div className="mb-1">Choose payment method</div>
                  <SelectBox
                    formatOptionLabel={formatOptionLabel}
                    isDisabled={!isValueValid || clientSecret || processing}
                    onChange={(option) => setPaymentMethod(option)}
                    options={[
                      {
                        label: "New payment method",
                        value: null,
                        newMethod: true,
                      },
                    ].concat(
                      methods.map(({ card_id, card }) => ({
                        label: card,
                        value: card_id,
                        newMethod: false, // TODO: Monitor this. It could be a bug
                      })),
                    )}
                  />
                </div>
                <ErrorMessage message={errorMessage} dismissible onClose={() => setErrorMessage("")} />
                <div
                  className={`mt-4 d-flex justify-content-center ${
                    (!processing && clientSecret) || processing ? "d-none" : ""
                  }`}
                >
                  <Button
                    disabled={!isValueValid || !paymentMethod || createPaymentResponse.isLoading}
                    onClick={handleProceed}
                  >
                    Proceed to payment
                  </Button>
                </div>
              </div>
              {processing && <Loader className="mt-4" size={3} />}
              {!processing && clientSecret && (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                  }}
                >
                  <CheckoutForm
                    clientSecret={clientSecret}
                    paymentMethod={paymentMethod}
                    submitText={`Pay ${currencySign}${(values?.float * vat).toFixed(2)}`}
                  />
                </Elements>
              )}
            </>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PaymentIntent;
