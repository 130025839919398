import cs from "classnames";

import React, { useState } from "react";
import { Dropdown, Form, FormControl, InputGroup } from "react-bootstrap";

const CustomToggle = React.forwardRef(({ onClick, value, isChevronDown }, ref) => (
  <Form.Group className="mb-3 me-3 position-relative" ref={ref}>
    <Form.Label className="mb-0">Type</Form.Label>
    <InputGroup
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="p-0"
    >
      <FormControl
        value={value}
        type="string"
        readOnly
        className="cursor-pointer position-relative select-border-radius"
      />
      <div onClick={onClick} className="ip-dropdown-chevron">
        <i className={`fe fe-chevron-${isChevronDown ? "down" : "up"}`} />
      </div>
    </InputGroup>
  </Form.Group>
));

const IPRule = ({
  rule,
  publicIp = [],
  handleChangeRule,
  handlePortChange,
  handleSourceChange,
  index,
  handleDeleteRule,
}) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [isChevronDown, setIsChevronDown] = useState(true);

  const handleDropdownClick = (rule, index) => {
    handleChangeRule(rule, index);
  };
  return (
    <div
      className="d-flex position-relative justify-content-between"
      onMouseEnter={() => {
        setShowDeleteIcon(true);
      }}
      onMouseLeave={() => {
        setShowDeleteIcon(false);
      }}
    >
      {index !== 0 && (
        <div
          className={cs("position-absolute delete-public-ip-rule-icon", {
            "show-delete-icon": showDeleteIcon,
          })}
          onClick={() => handleDeleteRule(index)}
        >
          <i className="fe fe-minus-circle" />
        </div>
      )}
      <Dropdown onToggle={(isVisible) => setIsChevronDown(!isVisible)}>
        <Dropdown.Toggle as={CustomToggle} value={rule.type} isChevronDown={isChevronDown} />
        <Dropdown.Menu>
          {publicIp.map((rule, i) => (
            <Dropdown.Item key={i} onClick={() => handleDropdownClick(rule, index)} eventKey={i}>
              {rule.type}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Form.Group className="mb-3 me-3">
        <Form.Label className="mb-0">Protocol</Form.Label>
        <InputGroup className="p-0">
          <FormControl className="bg-light" disabled value={rule.protocol} type="string" readOnly />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3 me-3">
        <Form.Label className="mb-0">Port Range</Form.Label>
        <InputGroup className="p-0">
          <FormControl
            value={rule.port_range}
            className={!rule.port_input ? "bg-light" : ""}
            disabled={!rule.port_input}
            onChange={(e) => {
              handlePortChange(e.target.value, index);
            }}
            type="string"
            readOnly={!rule.port_input}
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-0">Source</Form.Label>
        <InputGroup className="p-0">
          <FormControl value={rule.source} onChange={(e) => handleSourceChange(e.target.value, index)} type="string" />
        </InputGroup>
      </Form.Group>
    </div>
  );
};

export default IPRule;
