import { useLazyGetSelfQuery } from "@modules/dashboard/account/account-api-slice";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { intercomBoot, intercomUpdate } from "src/lib/utils/Intercom/intercomUtils";

export const useIntercom = () => {
  const location = useLocation();
  const [intercomLaunched, setIntercomLaunched] = useState(false);

  // This is for fetching the user's creation time to send to Intercom
  // in preparation for the release. Not to trigger the mandatory Intercom
  // checklist for users who have already signed up.
  const [getSelf] = useLazyGetSelfQuery();
  
  useEffect(() => {
    if (!intercomLaunched) {
      if (!location.pathname.includes("auth")) {
        (async () => {
          const response = await getSelf();
          const { data } = response;
          const userCreatedUnix = Math.floor(Date.parse(data?.created) / 1000);
          await intercomBoot({ userCreatedUnix });
          console.log("booting intercom");
        })();
        setIntercomLaunched(true);
      }
    }
    if (location.pathname.includes("auth")) {
      setIntercomLaunched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!location.pathname.includes("auth")) {
      (async () =>
        await intercomUpdate({
          last_location: location.pathname,
        }))();
    }
  }, [location]);
};
