import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { useUpdateBillingBalanceMutation } from "@modules/dashboard/billing/billing-api-slice";

import ErrorMessage from "@components/ErrorMessage";
import { Loader } from "@components/Loader";

import AmountSelector from "../AmountSelector";

interface IAutoRecharge {
  isOpen: boolean;
  handleClose: () => void;
  currencySign: string;
  setAutoTopUp: (boolean) => void;
  autoTopUp: boolean;
  topUpAmount: number;
  topUpTreshold: number;
}

const AutoRecharge = ({
  isOpen,
  handleClose,
  currencySign,
  setAutoTopUp,
  autoTopUp,
  topUpAmount,
  topUpTreshold,
}: IAutoRecharge) => {
  const [updateBillingBalance, updateBillingBalanceResponse] = useUpdateBillingBalanceMutation();

  const [processing, setProcessing] = useState(false);
  const [topUpValue, setTopUpValue] = useState<number | string>("");
  const [thresholdValue, setThresholdValue] = useState<number | string>("");

  useEffect(() => {
    if (autoTopUp) {
      setTopUpValue(() => topUpAmount / 100);
      setThresholdValue(() => topUpTreshold / 100);
    }
  }, [autoTopUp, topUpAmount, topUpTreshold]);

  const [topUpValues, setTopUpValues] = useState(
    autoTopUp
      ? {
          float: topUpAmount / 100,
        }
      : {}
  );

  const [thresholdValues, setThresholdValues] = useState(
    autoTopUp
      ? {
          float: topUpTreshold / 100,
        }
      : {}
  );
  const [isTopUpValueValid, setIsTopUpValueValid] = useState(autoTopUp);
  const [isThresholdValueValid, setIsThresholdValueValid] = useState(autoTopUp);
  const [errorMessage, setErrorMessage] = useState("");

  const resetValues = (clear = false) => {
    setTopUpValue(() => (autoTopUp && !clear ? topUpAmount / 100 : ""));
    setTopUpValues(
      autoTopUp && !clear
        ? {
            float: topUpAmount / 100,
          }
        : {}
    );
    setThresholdValue(() => (autoTopUp && !clear ? topUpTreshold / 100 : ""));
    setThresholdValues(
      autoTopUp && !clear
        ? {
            float: topUpTreshold / 100,
          }
        : {}
    );
    setIsTopUpValueValid(autoTopUp && !clear);
    setIsThresholdValueValid(autoTopUp && !clear);
  };

  const onClose = () => {
    setErrorMessage("");
    handleClose();
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    if (topUpValues?.float < thresholdValues?.float) {
      return setErrorMessage("Threshold amount must be lower than top up amount!");
    }
    setProcessing(true);
    await updateBillingBalance({
      auto_topup: true,
      topup_threshold_in_cents: thresholdValues.float * 100,
      topup_amount_in_cents: topUpValues.float * 100,
    });
    setProcessing(false);
    const error = updateBillingBalanceResponse.error;
    if (error) {
      setErrorMessage(
        "There was an error enabling Auto TopUp. Our team has been notified. Please try again later or contact us directly at suppoer@simbase.com."
      );
    } else {
      setAutoTopUp(true);
      toast.success("Successfully enabled Auto Recharge!");
      onClose();
    }
    setProcessing(false);
  };

  const handleDisableAutoRecharge = async () => {
    setProcessing(true);

    await updateBillingBalance({
      auto_topup: false,
    });
    if (updateBillingBalanceResponse.error) {
      setErrorMessage(
        "There was an error disabling Auto TopUp. Our team has been notified. Please try again later or contact us directly at suppoer@simbase.com."
      );
    } else {
      setAutoTopUp(false);
      toast.success("Successfully disabled Auto Recharge!");
      resetValues(true);
      onClose();
    }
    setProcessing(false);
  };

  return (
    <Modal centered show={isOpen} backdrop="static" onHide={onClose}>
      <Modal.Header className="border-0 pb-0 d-flex justify-content-end">
        <span
          onClick={() => {
            onClose();
            resetValues();
          }}
          className="fe fe-x cursor-pointer fs-1 mt-n2 me-n3"
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <h2 className="fw-600 d-flex justify-content-center mb-3">Auto Recharge</h2>
        <h3 className="d-flex justify-content-center mb-3">Set automatic recharge amounts for your account balance</h3>
        <div className="d-flex mt-5">
          <div className="w-100 d-flex flex-column pe-4">
            <div className="text-muted fw-600 mb-3 text-capitalize">Recharge Balance To</div>
            <div className="text-muted">Minimum amount is {currencySign}20.00 and</div>
            <div className="text-muted mb-2">Maximum amount is {currencySign}2000.00</div>
            <AmountSelector
              value={topUpValue}
              setValue={setTopUpValue}
              setValues={setTopUpValues}
              prefix={currencySign}
              setIsValueValid={setIsTopUpValueValid}
              noLabel
              min={20}
              limit={2000}
            />
          </div>
          <div className="w-100 d-flex flex-column ps-4">
            <div className="text-muted fw-600 mb-3 text-capitalize">When balance falls below</div>
            <div className="text-muted">Minimum amount is {currencySign}10.00 and</div>
            <div className="text-muted mb-2">Maximum amount is {currencySign}1990.00</div>
            <AmountSelector
              value={thresholdValue}
              setValue={setThresholdValue}
              setValues={setThresholdValues}
              prefix={currencySign}
              setIsValueValid={setIsThresholdValueValid}
              noLabel
              min={10}
              limit={1990}
            />
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-center">
          {processing && <Loader />}
          {autoTopUp && !processing && (
            <div className="d-flex justify-content-between w-100">
              <div className="w-45">
                <Button
                  className="w-100 export-sims-button-color"
                  variant="light-primary"
                  type="submit"
                  disabled={
                    !(topUpValues?.float && thresholdValues?.float) || !(isTopUpValueValid && isThresholdValueValid)
                  }
                  onClick={handleSubmit}
                >
                  Continue Auto Recharge
                </Button>
              </div>
              <div className="w-45">
                <Button className="w-100 delete-button-color" variant="danger" onClick={handleDisableAutoRecharge}>
                  Disable Auto Recharge
                </Button>
              </div>
            </div>
          )}
          {!autoTopUp && !processing && (
            <Button
              onClick={handleSubmit}
              type="submit"
              className="text-nowrap"
              variant="primary"
              disabled={
                !(topUpValues?.float && thresholdValues?.float) || !(isTopUpValueValid && isThresholdValueValid)
              }
            >
              Enable Auto Recharge
            </Button>
          )}
        </div>
        <ErrorMessage
          className="mt-4"
          alertClassname="mb-0"
          message={errorMessage}
          dismissible
          onClose={() => setErrorMessage("")}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AutoRecharge;
