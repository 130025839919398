import icon from "@assets/img/iot.png";
import { Dropdown } from "@components/Dropdown";
import { Loader } from "@components/Loader";
import { REFRESH_SIMCARDS_AFTER_NETWORK_ASSIGN_TO_NONE } from "@core/constants";
import { useLazyGetSimQuery } from "@modules/dashboard/simCard/simcards-api-slice";
import { useEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Simcard } from "src/types/types";

const AssignNetworkModal = ({
  isOpen,
  handleClose,
  networks,
  iccids,
  assignSimToNetwork,
  removeSimFromNetwork,
  selectedSim,
  fetchSims,
  // details,
  // getSim,
}) => {

  // GetSim
  const [getSim, getSimResponse] = useLazyGetSimQuery();
  const details: Simcard | Record<string, never> = getSimResponse.data ?? {};

  const [processing, setProcessing] = useState(false);
  const [simDetailsProcessing, setSimDetailsProcessing] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<any | object>({}); // TODO: fix any
  const [errorMessage, setErrorMessage] = useState("");
  const timeoutRef = useRef(null);

  const handleSelectNetwork = (option) => {
    setSelectedNetwork(option);
  };

  const onClose = () => {
    setSelectedNetwork({});
    handleClose();
  };

  const handleAssignNetwork = async () => {
    setErrorMessage("");
    setProcessing(true);

    let response;

    // None option is selected and SIMs will be removed from network
    if (!selectedNetwork.key) {
      response = await removeSimFromNetwork({
        // selectedSim is always null
        iccids: selectedSim ? [selectedSim] : iccids,
      });
    } else {
      response = await assignSimToNetwork({
        id: selectedNetwork.key,
        // selectedSim is always null
        iccids: selectedSim ? [selectedSim] : iccids,
      });
    }
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else if (selectedNetwork.key) {
      toast.success(`SIMs assigned to network ${selectedNetwork.value}`);
      onClose();
    } else {
      // Sims removed from network
      toast.success("SIMs successfully removed from network");
      timeoutRef.current = setTimeout(() => {
        fetchSims();
      }, REFRESH_SIMCARDS_AFTER_NETWORK_ASSIGN_TO_NONE);
      onClose();
    }
    setProcessing(false);
  };

  const dropdownOptions = useMemo(() => {
    const noneOption = {
      key: null,
      value: "None",
    };

    const result = [
      ...networks.map(({ network_name, network_id }) => ({
        key: network_id,
        value: network_name || "[NO NAME]",
      })),
    ];

    // Add 'None' option if selected network contains network or
    // multiple sims are selected otherwise hide option
    if (details.network_id || iccids.length > 0) {
      result.push(noneOption);
    }

    return result;
  }, [networks, details.network_id, iccids]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (isOpen && selectedSim) {
        setSimDetailsProcessing(true);
        await getSim(selectedSim);
        setSimDetailsProcessing(false);
      }
    })();
    // eslint-disable-next-line
  }, [isOpen, selectedSim]);

  return (
    <Modal centered show={isOpen} onHide={onClose}>
      <Modal.Body>
        <div className="d-flex justify-content-between border-bottom pb-3">
          <div className="d-flex">
            <div>
              <img src={icon} alt="icon" height={40} />
            </div>
            <div className="d-flex flex-column">
              <h4 className="mb-1">Assign to Private Network</h4>
              <small className="text-muted">Secure and control your IoT deployment</small>
            </div>
          </div>
          <div>
            <button className="btn-close" onClick={onClose}></button>
          </div>
        </div>
        <div>
          <div className="mt-4">
            <Dropdown
              options={dropdownOptions}
              selected={selectedNetwork}
              onClick={handleSelectNetwork}
              formLabel="Select the network SIM cards will be assigned to"
              defaultLabel="Select Network"
              className="w-60 text-nowrap"
              loading={simDetailsProcessing}
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <Button className="btn-light me-3" onClick={onClose}>
              Cancel
            </Button>
            <div>
              <Button
                className="btn-primary me-3"
                disabled={!Object.keys(selectedNetwork).length}
                onClick={handleAssignNetwork}
              >
                {processing ? <Loader variant="light" size={1.5} /> : "Assign"}
              </Button>
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className="mt-3">
            <Alert dismissible onClose={() => setErrorMessage("")} variant="danger my-0">
              {errorMessage}
            </Alert>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AssignNetworkModal;
