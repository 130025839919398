// import { Auth } from "aws-amplify";
import { statusApi } from "@modules/dashboard/home/status-api-slice";
import { configureStore } from "@reduxjs/toolkit";
import { coreApi } from "./rtk-api";

export const store = configureStore({
  devTools: true,
  reducer: {
    [coreApi.reducerPath]: coreApi.reducer,
    [statusApi.reducerPath]: statusApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(statusApi.middleware).concat(coreApi.middleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const resetGlobalState = () => {
  const rtkApis = [statusApi, coreApi];

  rtkApis.forEach((api) => {
    store.dispatch(api.internalActions.resetApiState());
  });
};
