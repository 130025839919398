import { Auth } from "aws-amplify";
import { getItem } from "src/lib/utils/localStorage";

type IntercomSettings = {
  api_base?: string;
  app_id: string;
  name: string;
  email: string;
  user_hash: number;
  original_registration_time?: number;
  role: string;
};

export const intercomBoot = async ({ userCreatedUnix }: { userCreatedUnix?: number }) => {
  const checkIntercomLoaded = async () => {
    if (window) {
      if ("Intercom" in window) {
        clearInterval(intervalId);
        const { Intercom } = window as unknown as any;
        try {
          const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
          const amplifyToken = currentAuthenticatedUser.signInUserSession.idToken.jwtToken;

          const response = await (
            await fetch(`${import.meta.env.VITE_REACT_APP_API}/intercom`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${amplifyToken}`,
              },
            })
          ).json();

          const hash = response.hash;

          const intercomSettings: IntercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: import.meta.env.VITE_INTERCOM_APP_ID,
            name: currentAuthenticatedUser.attributes.name, // Full name
            email: currentAuthenticatedUser.attributes.email, // Email address
            user_hash: hash,
            role: getItem("role"),
          };
          if (userCreatedUnix) {
            intercomSettings.original_registration_time = userCreatedUnix;
          }
          Intercom("boot", intercomSettings);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const intervalId = setInterval(checkIntercomLoaded, 1000);
};

export const intercomUpdate = async (
  data?: Record<string, unknown>,
  additionalCompanyData?: Record<string, unknown>,
) => {
  const checkIntercomLoaded = async () => {
    if (window) {
      if ("Intercom" in window) {
        try {
          clearInterval(intervalId);
          const { Intercom } = window as unknown as any;
          const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
          const email = currentAuthenticatedUser.attributes.email;
          const customerId = currentAuthenticatedUser.attributes["custom:customer_id"];
          const companyData = await getCompanyData(currentAuthenticatedUser.signInUserSession.idToken.jwtToken);
          const role = getItem("role");
          Intercom("update", {
            email,
            role: role,
            ...data,
            company: {
              id: customerId,
              ...companyData,
              ...(additionalCompanyData ? additionalCompanyData : {}),
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const intervalId = setInterval(checkIntercomLoaded, 1000);
};

export const intercomShutdown = () => {
  const { Intercom } = window as unknown as any;
  Intercom("shutdown");
};

const getCompanyData = async (amplifyToken: string): Promise<Record<string, string>> => {
  const response = await fetch(`${import.meta.env.VITE_REACT_APP_API}/customer`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${amplifyToken}`,
    },
  });
  const data = await response.json();
  return {
    name: data.company_name,
    city: data.address?.city ? data.address.city : "",
    country: data.tax?.tax_country ? data.tax.tax_country : "",
  };
};

// This isn't currently used
export const intercomTrackEvent = async (eventName: string, metadata: Record<string, unknown>) => {
  if (window) {
    if ("Intercom" in window) {
      const { Intercom } = window as unknown as any;
      Intercom("trackEvent", eventName, metadata);
    }
  }
};
