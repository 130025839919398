import React, { useState } from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { CustomDropdownToggle } from '../../components';
import cs from 'classnames';

const OtherPlan = ({ plans, handleComplete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});

  const handleDropdownClose = (isOpen) => {
    if (!isOpen) {
      setIsOpen(false);
    }
  };

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan);
    setIsOpen(false);
  };

  const handleSelectPlan = () => {
    handleComplete(selectedPlan);
  };

  const {
    map,
    name,
    mb = 'X',
    sms = 'X',
    sms_to_device = 'X',
    countries = 'X',
    day = 'X',
    currency_symbol = 'X',
  } = selectedPlan;

  return (
    <Card>
      <Card.Body>
        <div>
          <img alt='map' className='w-100 h-auto' src={map || plans[0]?.map} />
        </div>
        <div className='row g-0 align-items-center justify-content-center'>
          <div className='col-auto'>
            <Dropdown
              show={isOpen}
              onToggle={handleDropdownClose}
              autoClose='outside'
            >
              <Dropdown.Toggle as={CustomDropdownToggle}>
                <div className='w-100 d-flex justify-content-center'>
                  <span
                    className={cs('display-4 text-nowrap mt-4 mb-5', {
                      'text-muted': !name,
                    })}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {name || 'Select any plan'}
                    <i className='fe fe-chevron-down fs-1' />
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className='w-100'>
                {plans.map((plan, index) => (
                  <Dropdown.Item
                    key={index}
                    className='px-3'
                    onClick={() => handlePlanClick(plan)}
                  >
                    {plan.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <hr className='m-0' />
        <div className='d-flex justify-content-center'>
          <div className='border-end py-4 d-flex justify-content-center flex-column align-items-center w-100'>
            <h6 className='text-muted mb-2'>SIM SUBSCRIPTION</h6>
            <div className='fw-700 fs-2'>{`${currency_symbol}${day} / day`}</div>
          </div>
          <div className='py-4 d-flex justify-content-center flex-column align-items-center w-100'>
            <h6 className='text-muted mb-2'>DATA RATE</h6>
            <div className='fw-700 fs-2'>{`${currency_symbol}${mb} / MB`}</div>
          </div>
        </div>
        <hr className='m-0' />
        <div className='mb-3'>
          <ul className='list-group list-group-flush'>
            <li className='list-group-item d-flex align-items-center justify-content-between px-0'>
              <small>SMS From Device</small>
              <small>{`${currency_symbol}${sms} / SMS`}</small>
            </li>
            <li className='list-group-item d-flex align-items-center justify-content-between px-0'>
              <small>SMS To Device</small>
              <small>{`${currency_symbol}${sms_to_device} / SMS`}</small>
            </li>
            <li className='list-group-item d-flex align-items-center justify-content-between px-0'>
              <small>Countries</small>
              <small>{countries}</small>
            </li>
          </ul>
        </div>
        <Button
          className='w-100'
          variant='primary'
          onClick={handleSelectPlan}
          disabled={!selectedPlan.name}
        >
          Select plan
        </Button>
      </Card.Body>
    </Card>
  );
};

export default OtherPlan;
