import { useEffect, useRef, useState } from "react";


interface UseHoverReturn {
  ref: React.RefObject<HTMLDivElement>;
  isHovered: boolean;
}

export const useHover = (): UseHoverReturn => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, []); // Dependency array is empty as ref and handlers will not change

  return { ref, isHovered };
}