import { useState } from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { SimReport, Invoices, Overview, Settings } from '../index';
import { OverviewHeader } from '../../components';
import Payments from './Payments';
import Subscriptions from './Subscriptions';

const TABS = {
  OVERVIEW: 'Overview',
  INVOICES: 'Invoices',
  PAYMENTS: 'Payments',
  SIM_REPORT: 'SIM Report',
  SUBSCRIPTIONS: 'Subscriptions',
  SETTINGS: 'Billing Settings',
};

const Billing = () => {
  const [activeTab, setActiveTab] = useState(TABS.OVERVIEW);

  return (
    <div className='d-flex justify-content-center'>
      <div className='d-flex w-75 flex-column mt-5'>
        <OverviewHeader title='Billing' />
        <div className='px-3'>
          <Nav
            variant='tabs'
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
          >
            {Object.values(TABS).map((value) => (
              <NavItem key={value}>
                <NavLink eventKey={value}>{value}</NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <div className='py-4 px-2 h-100'>
          {activeTab === TABS.OVERVIEW && (
            <Overview
              handleEditBillingDetails={() => setActiveTab(TABS.SETTINGS)}
            />
          )}
          {activeTab === TABS.INVOICES && <Invoices />}
          {activeTab === TABS.PAYMENTS && <Payments />}
          {activeTab === TABS.SIM_REPORT && <SimReport />}
          {activeTab === TABS.SUBSCRIPTIONS && <Subscriptions />}
          {activeTab === TABS.SETTINGS && <Settings />}
        </div>
      </div>
    </div>
  );
};

export default Billing;
