import { Loader } from "@components/Loader";
import { LoadingWrapper } from "@components/LoadingWrapper";
import {
  useGetBillingBalanceQuery,
  useUpdateBillingBalanceMutation,
} from "@modules/dashboard/billing/billing-api-slice";
import { useGetDashboardParametersQuery } from "@modules/dashboard/simCard/simcards-api-slice";
import { useEffect, useState } from "react";
import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";
import { useEditCustomerMutation } from "../../account/account-api-slice";
import { SetCurrencyModal } from "../../simCard";
import { useGetHomeQuery } from "../home-api-slice";
import { ActivityFeed, Announcements, GetStarted, Help, SystemStatus } from "../index";
import { useGetStatusQuery } from "../status-api-slice";

const Home = () => {
  // Edit Customer
  const [editCustomer] = useEditCustomerMutation();

  // Status Page
  const { data: status, isFetching: isStatusFetching, isLoading: isStatusLoading } = useGetStatusQuery();
  const isStatusProcessing = useIsProcessing([isStatusFetching, isStatusLoading]);

  // UpdateBillingBalance
  const [updateBillingBalance] = useUpdateBillingBalanceMutation();

  // GetDashboardParams
  useGetDashboardParametersQuery();
  const getDashboardParams = useGetDashboardParametersQuery();
  const { currencies } = getDashboardParams.data || [];

  // GetBillingBalance
  const { data: balance } = useGetBillingBalanceQuery();

  // GetHome
  const { isLoading: isHomeLoading, isFetching: isHomeFetching, data: homeQuery } = useGetHomeQuery();

  const [showCurrencyModal, setShowCurrencyModal] = useState(false);

  useEffect(() => {
    if (balance?.currency_change) {
      setShowCurrencyModal(true);
    }
  }, [balance]);

  const isProcessing = useIsProcessing([isHomeLoading, isHomeFetching, isStatusProcessing]);
  if (isProcessing) {
    return (
      <div className="w-100 h-100 d-flex">
        <Loader />
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-between mt-3 px-3">
      <div className="d-flex flex-column w-55">
        <h4 className="display-4 fw-500">{homeQuery?.headline ?? homeQuery?.headline}</h4>
        <span className="position-absolute z-3" id="inapp-test"></span>
        <small className="mt-3 mb-5">
          Welcome to Simbase. Pick up where you left or learn more about how to get your devices connected
        </small>
        <div>
          <GetStarted items={homeQuery?.get_started.length ? Object.values(homeQuery?.get_started[0]) : []} />
        </div>

        <div>
          <Help data={homeQuery?.help.length ? Object.values(homeQuery?.help[0]) : []} />
        </div>
      </div>
      <div className="d-flex flex-column w-40">
        <div className="mb-4">
          <LoadingWrapper isLoading={isStatusProcessing}>
            <SystemStatus status={status} />
          </LoadingWrapper>
        </div>
        <div>
          <Announcements
            announcements={homeQuery?.announcements.length ? Object.values(homeQuery?.announcements[0]) : []}
          />
        </div>
        <div>
          <ActivityFeed activities={homeQuery?.activity.length ? Object.values(homeQuery?.activity[0]) : []} />
        </div>
      </div>
      <SetCurrencyModal
        isOpen={showCurrencyModal}
        handleClose={() => setShowCurrencyModal(false)}
        currencies={currencies ?? []}
        updateBillingBalance={updateBillingBalance}
        editCustomer={editCustomer}
      />
    </div>
  );
};

export default Home;
