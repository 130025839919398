import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useGetNetworkQuery } from "../network-api-slice";

import { Loader } from "../../../../components";
import { GET_NETWORK_DETAILS_POLL_INTERVAL } from "../../../../core/constants";

const DetailRow = ({ value = "", icon = false, loader = true }) => {
  return (
    <div className="d-flex align-items-center mb-3">
      {loader ? (
        <Loader flexPosition="justify-content-start" className="ms-3" size={1.5} />
      ) : (
        <h2 className="mb-0 text-nowrap ms-3">{value}</h2>
      )}
      {icon && <i className="fe fe-check-circle fs-2 check-icon-style ms-1" />}
    </div>
  );
};

const DeployStep = ({ networkId }: { networkId: string }) => {
  const navigate = useNavigate();
  const id = networkId;

  const getNetworkResponse = useGetNetworkQuery({ id }, { pollingInterval: GET_NETWORK_DETAILS_POLL_INTERVAL });
  const { data: details = {} } = getNetworkResponse;

  const checkNetworkDetailsLoader = (details) => {
    if (!details) {
      return false;
    }
    const { assign_openvpn_subnet, assign_subnet, checks, started, openvpn_server } = details?.deployment_state || {};

    return (
      assign_openvpn_subnet === "Success" &&
      assign_subnet === "Success" &&
      checks === "Success" &&
      openvpn_server === "Success" &&
      started === "Success"
    );
  };

  const handleSeeDetails = () => {
    navigate(`/dashboard/private-network/${id}`);
  };

  const getNetworkStateText = (networkState) => {
    return `Your Private Network is ${networkState === "Deployment in progress" ? "In Progress" : networkState}`;
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <div className="d-flex justify-content-center w-100 me-3 mt-5">
        <Card className="w-75 h-100 mb-0 info-card p-3">
          <Card.Body className="p-5">
            <div className="w-100 d-flex justify-content-center">
              <div className="d-flex w-100 flex-column">
                <h1 className="mb-5 text-nowrap justify-content-center d-flex">
                  {getNetworkStateText(details?.network_state)}
                </h1>
                <div className="d-flex justify-content-center">
                  <div className="d-flex flex-column justify-content-between">
                    <h2 className="mb-0 text-nowrap mb-3">Network details: </h2>
                    <h2 className="mb-0 text-nowrap mb-3">Subnet: </h2>
                    <h2 className="mb-0 text-nowrap mb-3">Internet access: </h2>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <DetailRow icon={checkNetworkDetailsLoader(details)} loader={!checkNetworkDetailsLoader(details)} />
                    <DetailRow loader={!details?.subnet} value={details?.subnet} />
                    <DetailRow
                      value={details?.deployment_state?.internet_access}
                      loader={
                        details?.deployment_state?.internet_access &&
                        details?.deployment_state?.internet_access === "Not started"
                      }
                    />
                  </div>
                </div>
                {details.network_state === "Deployed" && (
                  <>
                    <h2 className="d-flex mt-4 justify-content-center">
                      {`${details.network_name || "Network"} is ready for use`}
                    </h2>
                    <div className="d-flex justify-content-center">
                      <Button onClick={handleSeeDetails}>See details</Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default DeployStep;
