import { coreApi } from "@core/rtk-api";

export const integrationsApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getApiKeys: builder.query<any, void>({
        query: () => `/api-keys`,
      }),
      createApiKey: builder.mutation<any, { key_name: string; read_only: string; allowed_ips: string[] }>({
        query: ({ key_name, read_only, allowed_ips }) => ({
          url: `/api-keys`,
          method: "POST",
          body: { key_name, read_only, allowed_ips },
        }),
      }),
      deleteApiKey: builder.mutation<any, { apiKey: string }>({
        query: ({ apiKey }) => ({
          url: `/api-keys/${apiKey}`,
          method: "DELETE",
        }),
      }),
      getWebhooks: builder.query<any, void>({
        query: () => `/webhooks`,
      }),
      createWebhook: builder.mutation<any, { url: string; type: string }>({
        query: ({ url, type }) => ({
          url: `/webhooks/${type}`,
          method: "POST",
          body: { url },
        }),
      }),
    };
  },
});

export const {
  useCreateWebhookMutation,
  useGetWebhooksQuery,
  useDeleteApiKeyMutation,
  useCreateApiKeyMutation,
  useGetApiKeysQuery,
} = integrationsApi;
