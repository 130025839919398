import { coreApi } from "@core/rtk-api";
import type { BillingBalance, PaymentMethod } from "src/types/types";

export const billingApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getVasDetails: builder.query<any, { id: string; params: any }>({
        query: ({ id, params = {} }) => `/costs/vas/${id}?` + new URLSearchParams(params).toString(),
      }),
      getVasOverview: builder.query<any, { params: any }>({
        query: ({ params = {} }) => "/costs/vas?" + new URLSearchParams(params).toString(),
      }),
      getVas: builder.query<any, void>({
        query: () => "/vas",
      }),
      getPayments: builder.query<any, any>({
        query: ({ cursor = null, count = null }: { cursor: string | null; count: number | null }) =>
          `/billing/balance/payments-v2?
          ${cursor ? `cursor=${cursor}` : ""}
          ${count ? `&count=${count}` : ""}`,
      }),
      getCostReport: builder.query<any, { iccid: string; month: string }>({
        query: ({ iccid, month }) => `/costs/simcards/${iccid}?month=${month}`,
      }),
      getCosts: builder.query<any, string>({
        query: (month: string) => `/costs/simcards?month=${month}`,
      }),
      updateBillingBalance: builder.mutation<any, any>({
        query: (data) => ({
          url: `/billing/balance`,
          method: "PATCH",
          body: data,
        }),
        invalidatesTags: ["BillingBalance"],
      }),
      deletePaymentMethod: builder.mutation<string, { id: string }>({
        query: ({ id }) => {
          return {
            url: `/billing/methods/${id}`,
            method: "DELETE",
          };
        },
      }),
      updatePaymentMethod: builder.mutation<string, { id: string }>({
        query: ({ id }) => {
          return {
            url: `/billing/methods/${id}`,
            method: "PATCH",
          };
        },
        transformResponse(_, __, arg) {
          return arg.id;
        },
      }),
      getPaymentMethods: builder.query<{ methods: PaymentMethod[] }, void>({
        query: () => `/billing/methods`,
      }),
      getBillingBalance: builder.query<BillingBalance, void>({
        query: () => `/billing/balance`,
        providesTags: ["BillingBalance"],
      }),
      getBillingInvoice: builder.query<any, void>({
        query: () => `/billing/invoice`,
      }),
      createBillingInvoice: builder.mutation<any, any>({
        query: ({ data }) => ({
          url: `/billing/invoice`,
          method: "POST",
          body: data,
        }),
      }),
      getCountries: builder.query<any, void>({ query: () => "/countries" }),
      getInvoicePdf: builder.query<string, any>({
        query: ({ id }) => ({
          url: `/invoices/${id}/pdf`,
          responseHandler: async (response) => {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            return url;
          },
        }),
      }),
      getInvoices: builder.query<any, void>({ query: () => "/invoices" }),
      createFuturePayment: builder.mutation<any, void>({
        query: () => ({
          url: "billing/setup_intents",
          method: "POST",
          body: {},
          transformResponse: (response) => {
            return { payload: response };
          },
        }),
      }),
      createPayment: builder.mutation<any, { amount_in_cents: number; currency: string; payment_method?: string }>({
        query: ({ amount_in_cents, currency, payment_method }) => ({
          url: "/billing/payment_intents",
          method: "POST",
          body: {
            amount_in_cents,
            currency,
            payment_method,
          },
        }),
      }),
    };
  },
});

export const {
  useGetVasDetailsQuery,
  useLazyGetVasOverviewQuery,
  useLazyGetVasQuery,
  useLazyGetPaymentsQuery,
  useLazyGetCostReportQuery,
  useLazyGetCostsQuery,
  useUpdateBillingBalanceMutation,
  useDeletePaymentMethodMutation,
  useLazyGetPaymentMethodsQuery,
  useUpdatePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useGetBillingBalanceQuery,
  useLazyGetBillingBalanceQuery,
  useGetBillingInvoiceQuery,
  useCreateBillingInvoiceMutation,
  useGetCountriesQuery,
  useLazyGetInvoicePdfQuery,
  useGetInvoicesQuery,
  useCreateFuturePaymentMutation,
  useCreatePaymentMutation,
} = billingApi;
