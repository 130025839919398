import {  useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { ErrorMessage, Loader } from "../../../../components";

interface ISubscriptionModal {
  isOpen?: boolean;
  title?: string;
  description?: string;
  message?: string;
  handleCloseModal: () => void;
  handleSubmit: (id: string, text: string) => void;
  id?: string;
}

const SubscriptionModal = ({
  isOpen = true,
  title,
  description,
  message,
  handleCloseModal,
  handleSubmit,
  id,
}: ISubscriptionModal) => {
  const [text, setText] = useState("");
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async () => {
    if (text.length === 0) {
      return setErrorMessage("Message cannot be empty!");
    }
    setProcessing(true);
    await handleSubmit(id, text);
    setProcessing(false);
  };



  return (
    <Modal
      autoFocus
      centered
      show={isOpen}
      // onHide={() => {}}
      backdrop={true}
    >
      <Modal.Header>
        <h3 className="header-title">{title}</h3>
        <div className="d-flex justify-content-end">
          <span onClick={handleCloseModal} className="fe fe-x cursor-pointer" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">{description}</div>
        <Form className="mt-1">
          <Form.Group>
            <Form.Control
              placeholder={message}
              className="form-control text-area-no-resize"
              as="textarea"
              value={text}
              onChange={(e) => {
                setErrorMessage("");
                setText(e.target.value);
              }}
              rows={5}
            />
          </Form.Group>
        </Form>
        <div className="d-flex justify-content-end mt-3">
          <Button onClick={onSubmit} variant="primary" className="px-3">
            <div className="d-flex align-items-center gap-2">
              <div>Submit</div>
              {processing && (
                <div>
                  <Loader variant="light" size={1.2} />
                </div>
              )}
            </div>
          </Button>
        </div>
        <ErrorMessage className="my-0" message={errorMessage} dismissible onClose={() => setErrorMessage("")} />
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionModal;
