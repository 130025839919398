import { useState, useEffect } from "react";
import { useGetBillingBalanceQuery } from "../billing-api-slice";

export const useAutoTopUp = (): {
  autoTopUp: boolean;
  setAutoTopUp: (value: boolean) => void;
  topUpAmount: number;
  topUpTreshold: number;
  isTopUpFetchSuccess: boolean;
  isTopUpFetchFetching: boolean;
} => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const balanceResponse = useGetBillingBalanceQuery({ refetchOnMountOrArgChange: true });
  const { auto_topup, topup_amount_in_cents, topup_threshold_in_cents } =
    balanceResponse.data ?? {};
  const [autoTopUp, setAutoTopUp] = useState<boolean>(false);

  useEffect(() => {
    setAutoTopUp(() => auto_topup);
  }, [auto_topup, balanceResponse.isSuccess]);

  return {
    isTopUpFetchSuccess: balanceResponse.isSuccess,
    isTopUpFetchFetching: balanceResponse.isFetching,
    autoTopUp,
    setAutoTopUp,
    topUpAmount: topup_amount_in_cents as unknown as number,
    topUpTreshold: topup_threshold_in_cents as unknown as number,
  };
};
