interface ISubnet {
  name: string;
  rate: string;
  unit: string;
}

interface IPrivateNetwork {
  subnet_14: ISubnet;
  subnet_254: ISubnet;
  subnet_510: ISubnet;
  subnet_1022: ISubnet;
}

const mapPrivateNetworksToDropdownOptions = (privateNetworks: IPrivateNetwork) => {
  const result = [];
  for (const [key, value] of Object.entries(privateNetworks)) {
    result.push({
      key,
      value: value.name, // FIXME: need to type this
    });
  }
  return result;
};

export default mapPrivateNetworksToDropdownOptions;
