import { ReactNode, useMemo } from "react";
// import PropTypes from "prop-types";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { Button, Card, Dropdown, Spinner } from "react-bootstrap";
import { PAGE_SIZE_OPTIONS } from "../../../core/constants";
import { Select, TransparentInput } from "../simCard";
import { CustomDropdownToggle, FilterDropdown } from "./index";
import ListPageOptions from "../simCard/components/ListPageOptions";
import { ExportToCsv } from "export-to-csv";
import cs from "classnames";
import Loader from "@components/Loader";

interface IColumn {
  Header: string;
  accessor: string;
  isVisible?: boolean;
}

interface IActionItem {
  title: string;
  handleClick: (row: any) => void;
}

interface ISimpleTableProps {
  executingActionRow?: number | null;
  columns: IColumn[];
  data: any[];
  title?: string;
  titleFontWeight?: string;
  hasExport?: boolean;
  hasPerPage?: boolean;
  hasActions?: boolean;
  hasSearch?: boolean;
  actionItems?: IActionItem[];
  noDataText?: string;
  processing?: boolean;
  filters?: any[];
  updateFilter?: (key: any, selectedOption: any) => void;
  resetFilter?: () => void;
  customActionButton?: ReactNode;
  autoResetPage?: boolean;
  autoResetSortBy?: boolean;
  initialPageSize?: number;
  hasPagination?: boolean;
  cellProps?: any;
}

// TODO: Add interface making sure to mark all props as required or optional
const SimpleTable = ({
  columns,
  data,
  title,
  titleFontWeight = "500",
  hasExport = true,
  hasPerPage = true,
  hasActions = false,
  hasSearch = false,
  actionItems,
  noDataText = "No data",
  processing = false, // TODO: Check if this works
  filters = [],
  executingActionRow = null,
  updateFilter,
  resetFilter,
  customActionButton,
  autoResetPage = true,
  autoResetSortBy = true,
  initialPageSize = PAGE_SIZE_OPTIONS[0].value,
  hasPagination = true,
  cellProps = {},
}: ISimpleTableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns: useMemo(() => columns, [columns]),
      data: useMemo(() => data, [data]),
      autoResetPage,
      autoResetSortBy,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        hiddenColumns: columns
          .filter((column) => column.isVisible === false)
          .map((column) => column.accessor),
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleExport = () => {
    const csvExporter = new ExportToCsv({
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    });
    if (data.length) {
      csvExporter.generateCsv(data);
    }
  };
  
  if (processing) return <Loader />;

  return (
    <Card className="w-100 h-100 mb-0">
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          {hasSearch && (
            <div className="flex-grow-1">
              <TransparentInput
                placeholder="Search"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                icon="search"
              />
            </div>
          )}
          {!!title && (
            <h4
              className={cs(
                "card-header-title text-capitalize align-items-center d-flex",
                `fw-${titleFontWeight}`
              )}
            >
              {title}
            </h4>
          )}
        </div>
        {hasPerPage && (
          <div className="pe-4">
            <Select
              options={PAGE_SIZE_OPTIONS}
              selected={PAGE_SIZE_OPTIONS.find(({ value }) => value === pageSize)}
              onClick={({ value }) => setPageSize(value)}
            />
          </div>
        )}
        {hasExport && (
          <div>
            <Button className="btn btn-sm btn-white" onClick={handleExport}>
              <i className="fe fe-download" /> Export
            </Button>
          </div>
        )}
        {filters.length > 0 && (
          <FilterDropdown filters={filters} updateFilter={updateFilter} resetFilter={resetFilter} />
        )}
        {customActionButton}
      </Card.Header>
      <div className="overflow-auto flex-grow-1">
        <table
          className="table table-sm table-hover table-nowrap card-table overflow-hidden"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    style={{ width: "10px" }}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        // This is to span last header because there is additional td (ellipsis menu)
                        colSpan: i === headerGroup.headers.length - 1 ? 2 : 1,
                      })
                    )}
                  >
                    {column.render("Header")}
                    <span className="lis-sort text-muted">
                      {column.isSorted && (
                        <i className={`fe fe-chevron-${column.isSortedDesc ? "down" : "up"}`} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {processing && (
            <tbody>
              <tr>
                <td colSpan={100}>
                  {" "}
                  {/* This was colSpan="100%", TS gave an error so i changed it to this. SO answer: https://stackoverflow.com/questions/398734/colspan-all-columns */}
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="dark" />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!processing && data.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={100}>
                  <div className="d-flex justify-content-center">
                    <h3 className="py-2 m-0">{noDataText}</h3>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!processing && (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="">
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell", cellProps)}</td>;
                    })}
                    {hasActions && (
                      <td className="text-end ">
                        {row.index === executingActionRow ? (
                          <Loader size={1} animation="grow" flexPosition="justify-content-end" />
                        ) : (
                          <Dropdown
                            drop={data.length === 1 || data.length === 2 ? "end" : null}
                            className="ellipsis-menu-height"
                          >
                            <Dropdown.Toggle as={CustomDropdownToggle}>
                              <div className="dropdown-ellipses dropdown-toggle" role="button">
                                <div className="ellipsis">
                                  <i className="fe fe-more-vertical" />
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {actionItems.map(({ title, handleClick }, index) => (
                                <Dropdown.Item
                                  key={index}
                                  className="py-1"
                                  onClick={() => handleClick(row)}
                                >
                                  <span>{title}</span>
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      {hasPagination && (
        <div className="card-footer d-flex justify-content-between card-footer-width">
          <ul
            className="list-pagination-prev pagination pagination-tabs card-pagination"
            onClick={previousPage}
          >
            <li className="page-item">
              <button className="page-link ps-0 pe-4 border-end d-flex">
                <i className="fe fe-arrow-left me-1" /> Prev
              </button>
            </li>
          </ul>
          <ListPageOptions currentPageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} />
          <ul
            className="list-pagination-next pagination pagination-tabs card-pagination"
            onClick={nextPage}
          >
            <li className="page-item">
              <button className="page-link ps-4 pe-0 border-start d-flex">
                Next <i className="fe fe-arrow-right ms-1" />
              </button>
            </li>
          </ul>
        </div>
      )}
    </Card>
  );
};

export default SimpleTable;
