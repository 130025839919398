import { Button, Modal } from 'react-bootstrap';

const ConfirmModal = ({
  isOpen,
  title = 'Confirm action',
  subtitle,
  handleConfirm,
  handleCancel,
}) => {
  return (
    <Modal size='sm' centered show={isOpen}>
      <Modal.Header>
        <h3 className='header-title'>{title}</h3>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4 className='header-subtitle pb-2'>{subtitle}</h4>
          <div className='d-flex justify-content-between mt-4'>
            <Button className='me-2' variant='danger' onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant='light-primary'
              type='submit'
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
