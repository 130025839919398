import React, { useState } from 'react';
import { Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';

const FormField = ({
  label = '',
  value,
  handleBlur,
  handleChange,
  error,
  touched,
  placeholder = '',
  type = 'string',
  name = label,
  hasForgotPassword = false,
  onForgetPassword = () => {},
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <Form.Group className='mb-3'>
      {hasForgotPassword ? (
        <Row>
          <Col>
            <Form.Label>Password</Form.Label>
          </Col>
          <div className='col-auto'>
            <span
              onClick={onForgetPassword}
              className='form-text small text-muted cursor-pointer'
            >
              Forgot password?
            </span>
          </div>
        </Row>
      ) : (
        <Form.Label>{label}</Form.Label>
      )}
      <InputGroup className='p-0'>
        <FormControl
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          type={
            type === 'password'
              ? isPasswordVisible
                ? 'text'
                : 'password'
              : type
          }
          name={name}
          placeholder={placeholder}
        />
        {type === 'password' && (
          <InputGroup.Text
            className='cursor-pointer'
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            <i className={`fe fe-eye${!isPasswordVisible ? '-off' : ''}`} />
          </InputGroup.Text>
        )}
      </InputGroup>
      <span className='text-danger'>{error && touched ? error : ''}</span>
    </Form.Group>
  );
};

export default FormField;
