const ListPageOptions = ({ currentPageIndex, pageCount, gotoPage }) => {
  const toDisplay = [];
  for (let i = 0; i < pageCount; i++) {
    toDisplay.push({
      page: i + 1,
      active: currentPageIndex === i,
    });
  }

  return (
    <ul className="list-pagination pagination pagination-tabs card-pagination overflow-auto ">
      {toDisplay.map(({ page, active }) => (
        <li key={page} className={active ? "active" : ""} onClick={() => gotoPage(page - 1)}>
          <button className="page">{page}</button>
        </li>
      ))}
    </ul>
  );
};

export default ListPageOptions;
