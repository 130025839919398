import { useState } from "react";
import { Button, Card } from "react-bootstrap";

import { useChangePlanMutation } from "../../simCard/simcards-api-slice";

import Loader from "@components/Loader";

const Plan = ({
  name,
  mb,
  currency,
  map,
  sms_to_device,
  sms,
  countries,
  handleComplete,
  day,
  currency_symbol,
  isSelected = false,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSelectPlan = async () => {
    setIsLoading(() => true);
    await handleComplete({
      name,
      mb,
      currency,
      map,
      countries,
      sms,
      sms_to_device,
      day,
      ...rest,
    });
    setIsLoading(() => false);
  };

  return (
    <Card>
      <Card.Body>
        <div>
          <img className="w-100 h-auto" src={map} alt="map" />
        </div>
        <div className="d-flex justify-content-center">
          <h3 className="display-4 mt-4 mb-5">{name}</h3>
        </div>
        <hr className="m-0" />
        <div className="d-flex justify-content-center">
          <div className="border-end py-4 d-flex justify-content-center flex-column align-items-center w-100">
            <h6 className="text-muted mb-2">SIM SUBSCRIPTION</h6>
            <div className="fw-700 fs-2">{`${currency_symbol}${day} / day`}</div>
          </div>
          <div className="py-4 d-flex justify-content-center flex-column align-items-center w-100">
            <h6 className="text-muted mb-2">DATA RATE</h6>
            <div className="fw-700 fs-2">{`${currency_symbol}${mb} / MB`}</div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="mb-3">
          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
              <small>SMS From Device</small>
              <small>{`${currency_symbol}${sms} / SMS`}</small>
            </li>
            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
              <small>SMS To Device</small>
              <small>{`${currency_symbol}${sms_to_device} / SMS`}</small>
            </li>
            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
              <small>Countries</small>
              <small>{countries}</small>
            </li>
          </ul>
        </div>
        <Button
          disabled={isSelected}
          className="w-100"
          variant={isSelected ? "light" : "primary"}
          onClick={handleSelectPlan}
        >
          {isLoading ? <Loader size={1} className="text-white" /> : isSelected ? "Selected" : "Select plan"}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Plan;
