import Select from 'react-select';

const SelectBox = ({ ...rest }) => {
  return (
    <Select
      styles={{
        control: (provided) => ({
          ...provided,
          color: 'red',
          padding: '0px 0',
          borderRadius: '0.375rem',
          borderColor: '#D2DDEC',
          '&:hover': null,
        }),
        input: (provided) => ({
          ...provided,
          padding: '6px 0px',
          margin: '0px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#B1C2D9',
        }),
      }}
      className='basic-single w-100'
      classNamePrefix='select'
      {...rest}
    />
  );
};

export default SelectBox;
