import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";

import { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  useDeletePublicIpMutation,
  useDeployPublicIpMutation,
  useGetDashboardParametersQuery,
  useLazyGetIpPricingQuery,
} from "../simcards-api-slice";

import icon from "@assets/img/ipSimCardIcon.png";

import { Loader } from "../../../../components";
import { PUBLIC_IP_SOURCE_REGEX } from "../../../../core/constants";
import IPRule from "./IPRule";

const PublicIPModal = ({ isOpen, handleClose, isManage, iccid, ruleFirewall }) => {
  const getDashboardParamsResponse = useGetDashboardParametersQuery();
  const publicIp = getDashboardParamsResponse.data?.publicIp ?? {};

  // DeployPublicIp
  const [deployPublicIp] = useDeployPublicIpMutation();
  const [deletePublicIp, deletePublicIpResponse] = useDeletePublicIpMutation();

  // GetIpPricing
  const [getIpPricing, getIpPricingResponse] = useLazyGetIpPricingQuery();
  const ipPricing = getIpPricingResponse.data?.ipPricing ?? {};

  const ipPricingIsProcessing = useIsProcessing([getIpPricingResponse.isFetching, getIpPricingResponse.isLoading]);

  const [rules, setRules] = useState([]);
  const [sourceErrorFields, setSourceErrorFields] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (ruleFirewall) {
      // TODO: When backend returns default rule remove this
      if (!ruleFirewall.length) {
        setRules([publicIp[0]]);
      } else {
        setRules(ruleFirewall);
      }
    }
    // eslint-disable-next-line
  }, [ruleFirewall]);

  useEffect(() => {
    getIpPricing();
    // eslint-disable-next-line
  }, []);

  const { currency_symbol, public_ip = {} } = ipPricing;
  const { rate, unit } = public_ip;

  const handleChangeRule = (newRule, index) => {
    setErrorMessage("");
    setRules(
      rules.map((rule, i) => {
        if (i === index) {
          return newRule;
        }
        return rule;
      }),
    );
  };

  const handleAddRule = () => {
    setErrorMessage("");
    setRules(rules.concat({}));
  };

  const handlePortChange = (port, index) => {
    setErrorMessage("");
    setRules(
      rules.map((rule, i) => {
        if (i === index) {
          return {
            ...rule,
            port_range: port,
          };
        }
        return rule;
      }),
    );
  };

  const handleSourceChange = (source, index) => {
    setErrorMessage("");
    setRules(
      rules.map((rule, i) => {
        if (i === index) {
          return {
            ...rule,
            source,
          };
        }
        return rule;
      }),
    );
  };

  const handleDeleteRule = (index) => {
    setErrorMessage("");
    setRules(rules.filter((_, i) => i !== index));
  };

  const handleAssignPublicIP = async () => {
    setErrorMessage("");
    rules.forEach((rule, i) => {
      if (!PUBLIC_IP_SOURCE_REGEX.test(rule.source)) {
        sourceErrorFields.push(i);
      }
    });
    if (sourceErrorFields.length) {
      setErrorMessage("IP has to match the CIDR annotation");
      setSourceErrorFields([]);
      return;
    }
    const response = await deployPublicIp({ iccid, data: { rules } });
    if ("errorMessage" in response) {
      setErrorMessage(response.errorMessage as string);
    } else {
      handleClose();
      // toast.success(response?.payload?.data?.Message); // TODO: reinstate this
    }
  };

  const doesEmptyRuleRowExists = () => {
    let result = false;
    rules.forEach((rule) => {
      if (!rule.type) {
        result = true;
      }
    });
    return result;
  };

  const handleDeletePublicIp = async () => {
    // setConfirmModalProcessing(true);
    await deletePublicIp({ iccid });
    // setConfirmModalProcessing(false);
    toast.success("Deleted");
    setIsDeleteConfirmModalOpen(false);
    handleClose();
  };

  return (
    <Modal size="lg" dialogClassName="w-50" centered show={isOpen} onHide={handleClose}>
      {isDeleteConfirmModalOpen ? (
        <>
          <Modal.Header>
            <h3 className="header-title fw-600">Delete Public IP</h3>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="fs-4">
                Are you sure you would like to delete the Public IP address for this SIM? The IP will no longer be
                available for the SIM and cannot be recovered.
              </div>
              <div className="d-flex justify-content-between mt-4">
                {deletePublicIpResponse.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div className="w-45">
                      <Button className="w-100" variant="secondary" onClick={() => setIsDeleteConfirmModalOpen(false)}>
                        Cancel
                      </Button>
                    </div>
                    <div className="w-45">
                      <Button className="w-100" variant="danger" type="submit" onClick={handleDeletePublicIp}>
                        Confirm
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </>
      ) : (
        <Modal.Body>
          {ipPricingIsProcessing ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex justify-content-between border-bottom pb-3">
                <div className="d-flex">
                  <div>
                    <img src={icon} alt="icon" height={40} />
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="mb-1">Assign a Public Fixed IP</h4>
                    <small className="text-muted">{`${currency_symbol}${rate} per ${unit}, Cancel Anytime`}</small>
                  </div>
                </div>
                <div>
                  <button className="btn-close" onClick={handleClose}></button>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between mt-3">
                  <h4 className="mb-1">Configure firewall rules</h4>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleAddRule}
                    disabled={doesEmptyRuleRowExists()}
                  >
                    Add Inbound rule
                  </Button>
                </div>
                <div className="d-flex mt-3 mb-2 d-flex flex-column">
                  {rules.map((rule, index) => (
                    <IPRule
                      key={index}
                      rule={rule}
                      handleChangeRule={handleChangeRule}
                      publicIp={publicIp}
                      index={index}
                      handlePortChange={handlePortChange}
                      handleSourceChange={handleSourceChange}
                      handleDeleteRule={handleDeleteRule}
                    />
                  ))}
                </div>
                <div className="d-flex justify-content-between">
                  <Button className="btn-light me-3" onClick={handleClose}>
                    Cancel
                  </Button>
                  <div>
                    {isManage && (
                      <Button className="btn-danger me-3" onClick={() => setIsDeleteConfirmModalOpen(true)}>
                        Delete
                      </Button>
                    )}
                    <Button className="btn-primary" onClick={handleAssignPublicIP}>
                      {isManage ? "Save changes" : "Assign Public IP"}
                    </Button>
                  </div>
                </div>
              </div>
              {errorMessage && (
                <div className="mt-3">
                  <Alert dismissible onClose={() => setErrorMessage("")} variant="danger my-0">
                    {errorMessage}
                  </Alert>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
};

export default PublicIPModal;
