import PropTypes from "prop-types";

import { Dropdown } from "react-bootstrap";

import { CustomDropdownToggle } from "../../components";

const Select = ({ options, selected, onClick }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomDropdownToggle}>
        <span>{selected?.label}</span>
        <i className="fe fe-chevron-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item key={option.value} onClick={() => onClick(option)}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onClick: PropTypes.func,
};

export default Select;
