import { ConfirmationModal } from "@components/ConfirmationModal";
import { Loader } from "@components/Loader";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteAccountMutation, useGetSelfQuery, useUpdateUserMutation } from "../account-api-slice";

export interface IUpdateUser {
  name?: string;
  email?: string;
  password?: string;
}

const Profile = () => {
  // UpdateUser
  const [updateUser, updateUserResponse] = useUpdateUserMutation();
  const [deleteAccount] = useDeleteAccountMutation();

  // GetSelf
  const getSelfResponse = useGetSelfQuery();
  const { data: self } = getSelfResponse ?? {};

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    setIsConfirmModalOpen(false);
    await deleteAccount(); // TODO: untested
    await Auth.signOut();
    navigate("/auth");
  };

  if (!getSelfResponse.isSuccess) {
    return <Loader />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore it works
  const updateUserErrorMessage = updateUserResponse.error?.data.errorMessage ?? "";

  return (
    <>
      <Formik
        initialValues={{ name: self?.name, email: self?.email }}
        onSubmit={async (values, { setSubmitting }) => {
          const params: IUpdateUser = {};

          if (values.name) {
            params.name = values.name;
          }
          if (values.email) {
            params.email = values.email;
          }
          await updateUser(params);
          toast.success("Details updated!");
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <InputGroup className="p-0">
                <FormControl
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="string"
                  name="name"
                />
              </InputGroup>
              <span className="text-danger">{errors.name && touched.name && (errors.name as React.ReactNode)}</span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Email</Form.Label>
              <InputGroup className="p-0">
                <FormControl
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="string"
                  name="email"
                />
              </InputGroup>
              <span className="text-danger">{errors.email && touched.email && (errors.email as React.ReactNode)}</span>
            </Form.Group>
            <Button className="mt-3 lift" variant="primary" type="submit" disabled={isSubmitting}>
              Save changes
            </Button>
          </form>
        )}
      </Formik>
      <Formik
        initialValues={{ confirmPassword: "", password: "" }}
        validate={(values) => {
          interface IErrors {
            password?: string;
            confirmPassword?: string;
          }
          const errors: IErrors = {};
          if (!values.password) {
            errors.password = "Required";
          } else if (!/[0-9]+/.test(values.password)) {
            errors.password = "Password must contain at least one number";
          } else if (!/[A-Z]+/.test(values.password)) {
            errors.password = "Password must contain at least one uppercase letter";
          } else if (!/[a-z]+/.test(values.password)) {
            errors.password = "Password must contain at least one lowercase letter";
          } else if (
            // eslint-disable-next-line
            !/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(values.password)
          ) {
            errors.password = "Password must contain at least one special character";
          } else if (values.password.length < 8) {
            errors.password = "Password must have at least 8 characters";
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
          }
          if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Passwords should match";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const response = await updateUser({
            password: values.password,
          });

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!response.error) {
            toast.success("Password changed!");
          }

          setSubmitting(false);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <hr className="my-5" />
            <Row className="justify-content-between align-items-center mb-5">
              <Col sm={12} md={9} className="col-xl-7">
                <h2 className="text-capitalize mb-1">Change your password</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} className="order-md-2">
                <Card className="bg-light border ms-md-4">
                  <Card.Body>
                    <p className="mb-2">Password requirements</p>
                    <p className="small text-muted mb-2">
                      To create a new password, you have to meet all of the following requirements:
                    </p>
                    <ul className="small text-muted ps-4 mb-0">
                      <li>Minimum 8 character</li>
                      <li>At least one uppercase and lowercase letter</li>
                      <li>At least one number</li>
                      <li>At least one special character</li>
                      <li>Can’t be the same as a previous password</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>New password</Form.Label>
                  <InputGroup>
                    <FormControl
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      name="password"
                    />
                  </InputGroup>
                  <span className="text-danger">{errors.password && touched.password && errors.password}</span>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm new password</Form.Label>
                  <InputGroup>
                    <FormControl
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      name="confirmPassword"
                    />
                  </InputGroup>
                  <span className="text-danger">
                    {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                  </span>
                </Form.Group>
                <Button className="w-100 btn-primary lift mt-3" variant="primary" type="submit" disabled={isSubmitting}>
                  Update password
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      {updateUserResponse.isError && (
        <div className="d-flex justify-content-center mt-3">
          <Alert className="w-100 mb-2" variant="danger">
            {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              updateUserErrorMessage
            }
          </Alert>
        </div>
      )}
      <hr className="my-5" />
      <Row className="justify-content-between">
        <Col sm={12} md={6}>
          <h4>Delete your account</h4>
          <p className="small text-muted mb-md-0">
            Please note, deleting your account is a permanent action and will no be recoverable once completed.
          </p>
        </Col>
        <Col sm="auto">
          <Button variant="danger" onClick={() => setIsConfirmModalOpen(true)}>
            Delete
          </Button>
        </Col>
      </Row>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        title="Delete account"
        subtitle={`Are you sure you want to delete your account?`}
        handleCancel={() => setIsConfirmModalOpen(false)}
        handleConfirm={handleDeleteAccount}
      />
    </>
  );
};

export default Profile;
