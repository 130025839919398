import { coreApi } from "@core/rtk-api";
import { User } from "src/types/types";
import { IUpdateUser } from "./components/Profile";

export const accountApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getCustomer: builder.query<any, void>({
        query: () => `/customer`,
        transformResponse: (data: any) => ({
          customerDetails: data,
        }),
      }),
      editCustomer: builder.mutation<any, any>({
        query: (data) => ({
          url: `/customer`,
          method: "PATCH",
          body: data,
        }),
        transformResponse: async (data: any) => ({
          customerDetails: data,
        }),
      }),
      getUsers: builder.query<any, void>({
        query: () => `/users`,
      }),
      getSubscriptions: builder.query<any, void>({
        query: () => `/subscriptions`,
      }),
      deleteUser: builder.mutation<any, string>({
        query: (id) => ({
          url: `/users/${id}`,
          method: "DELETE",
        }),
      }),
      editUser: builder.mutation<any, { name: string; email: string; role: string; id: string }>({
        query: ({ name, email, role, id }) => ({
          url: `/users/${id}`,
          method: "PATCH",
          body: { name, email, role },
        }),
      }),
      getSelf: builder.query<User, void>({
        query: () => `/users/self`,
      }),
      createUser: builder.mutation<any, { name: string; email: string; role: string }>({
        query: ({ name, email, role }) => ({
          url: `/users`,
          method: "POST",
          body: { name, email, role },
        }),
      }),
      createCustomRequest: builder.mutation<any, any>({
        query: (data) => ({
          url: `/customrequest`,
          method: "POST",
          body: data,
        }),
      }),
      updateUser: builder.mutation<any, IUpdateUser>({
        query: ({ name, email, password }) => ({
          url: `/users`,
          method: "PATCH",
          body: { name, email, password },
        }),
      }),
      updateSelf: builder.mutation<any, any>({
        query: (data) => ({
          url: `/users`,
          method: "PATCH",
          body: data,
        }),
      }),
      deleteAccount: builder.mutation<any, void>({
        query: () => ({
          url: `/users/self`,
          method: "DELETE",
        }),
      }),
    };
  },
});

export const {
  useDeleteAccountMutation,
  useUpdateSelfMutation,
  useUpdateUserMutation,
  useCreateCustomRequestMutation,
  useCreateUserMutation,
  useGetSelfQuery,
  useLazyGetSelfQuery,
  useEditUserMutation,
  useDeleteUserMutation,
  useGetSubscriptionsQuery,
  useGetCustomerQuery,
  useEditCustomerMutation,
  useGetUsersQuery,
} = accountApi;
