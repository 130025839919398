import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { SelectBox } from '../../components';

const BillingDetailsForm = ({
  values,
  countries,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  return (
    <div>
      <h3 className='fw-500 mb-2'>Billing details</h3>
      <small className='form-text text-muted'>
        Your billing address is the location from where you consume Simbase
        services and is used for taxation purposes. The billing details will
        appear on your invoices.
      </small>
      <Form.Group className='mb-3'>
        <Form.Label className='mb-0'>Country</Form.Label>
        <InputGroup className='p-0'>
          <SelectBox
            defaultValue={() => {
              const currentCountry = countries.find(
                ({ iso }) => iso === values.country
              );
              return currentCountry
                ? {
                    value: currentCountry.iso,
                    label: currentCountry.name,
                  }
                : null;
            }}
            onChange={(option) => setFieldValue('country', option.value)}
            options={countries.map(({ name, iso }) => ({
              label: name,
              value: iso,
            }))}
            name='country'
          />
        </InputGroup>
        <span className='text-danger'>
          {errors.country && touched.country && errors.country}
        </span>
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label className='mb-0'>Attention to</Form.Label>
        <InputGroup className='p-0'>
          <FormControl
            value={values.attentionTo}
            onBlur={handleBlur}
            onChange={handleChange}
            type='string'
            name='attentionTo'
          />
        </InputGroup>
        <span className='text-danger'>
          {errors.attentionTo && touched.attentionTo && errors.attentionTo}
        </span>
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Invoice Email Address</Form.Label>
        <InputGroup className='p-0'>
          <FormControl
            value={values.email_invoice}
            onBlur={handleBlur}
            onChange={handleChange}
            type='string'
            name='email_invoice'
          />
        </InputGroup>
        <span className='text-danger'>
          {errors.email_invoice &&
            touched.email_invoice &&
            errors.email_invoice}
        </span>
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label className=''>Address</Form.Label>
        <small className='form-text text-muted'>Address line 1</small>
        <InputGroup className='p-0'>
          <FormControl
            value={values.primaryAddress}
            onBlur={handleBlur}
            onChange={handleChange}
            type='string'
            name='primaryAddress'
          />
        </InputGroup>
        <span className='text-danger'>
          {errors.primaryAddress &&
            touched.primaryAddress &&
            errors.primaryAddress}
        </span>
        <small className='form-text text-muted mt-2'>Address line 2</small>
        <InputGroup className='p-0'>
          <FormControl
            value={values.secondaryAddress}
            onBlur={handleBlur}
            onChange={handleChange}
            type='string'
            name='secondaryAddress'
          />
        </InputGroup>
        <span className='text-danger'>
          {errors.secondaryAddress &&
            touched.secondaryAddress &&
            errors.secondaryAddress}
        </span>
      </Form.Group>
      <div className='d-flex'>
        <Form.Group className='mb-3 w-100 pe-3'>
          <Form.Label className='mb-0'>ZIP / Postal Code</Form.Label>
          <InputGroup className='p-0'>
            <FormControl
              value={values.zipCode}
              onBlur={handleBlur}
              onChange={handleChange}
              type='string'
              name='zipCode'
            />
          </InputGroup>
          <span className='text-danger'>
            {errors.zipCode && touched.zipCode && errors.zipCode}
          </span>
        </Form.Group>
        <Form.Group className='mb-3 w-100 ps-3'>
          <Form.Label className='mb-0'>State/Province</Form.Label>
          <InputGroup className='p-0'>
            <FormControl
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
              type='string'
              name='state'
            />
          </InputGroup>
          <span className='text-danger'>
            {errors.state && touched.state && errors.state}
          </span>
        </Form.Group>
      </div>
      <Form.Group className='mb-3'>
        <Form.Label className='mb-0'>City</Form.Label>
        <InputGroup className='p-0'>
          <FormControl
            value={values.city}
            onBlur={handleBlur}
            onChange={handleChange}
            type='string'
            name='city'
          />
        </InputGroup>
        <span className='text-danger'>
          {errors.city && touched.city && errors.city}
        </span>
      </Form.Group>
      <hr className='my-5' />
      <h3 className='fw-500 mb-2'>Tax Identification Number</h3>
      <small className='form-text text-muted'>
        Are you exempt from EU VAT? Or need your TIN/GST on invoices? Enter your
        Tax ID and correct taxes apply automatically.
      </small>
      <Form.Group className='mb-3'>
        <Form.Label className='mb-0'>Tax Identification Number</Form.Label>
        <InputGroup className='p-0'>
          <FormControl
            value={values.vat}
            onBlur={handleBlur}
            onChange={handleChange}
            type='string'
            name='vat'
          />
        </InputGroup>
        <span className='text-danger'>
          {errors.vat && touched.vat && errors.vat}
        </span>
      </Form.Group>
    </div>
  );
};

export default BillingDetailsForm;
