import { Button, Card } from "react-bootstrap";
// import createPrivateNetwork from "@/assets/img/create-private-network.png";
import createPrivateNetwork from "../../../../assets/img/create-private-network.png";

const WelcomeStep = ({ onNextClick, url }) => {
  return (
    <Card className="welcome-card">
      <Card.Body>
        <div className="d-flex justify-content-center mb-5">
          <img height={250} src={createPrivateNetwork} alt="..." />
        </div>
        <div className="mb-1 text-gray-600 fw-normal fs-2 text-center mb-2">
          We'll create a secure private network, so you can configure how
          traffic is handled for your SIMs. Secure access and traffic control is
          just a few clicks away.
        </div>
        <div className="fs-4 text-center text-nowrap fw-500 mt-5">
          If you need help, here's our &nbsp;
          <a
            rel="noreferrer"
            href={url}
            target={"_blank"}
            className="fw-900 fs-3 cursor-pointer"
          >
            documentation
          </a>
          .
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Button onClick={onNextClick} className="welcome-next-button">
            Next
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default WelcomeStep;
