import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SignUpForm } from "../index";

const SignUp = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async ({ name, email, password }, { setSubmitting }) => {
    setErrorMessage("");
    const lowerEmail = email.toLowerCase();
    try {
      await Auth.signUp({
        username: lowerEmail,
        password,
        attributes: {
          name,
        },
      });
      navigate("/auth/signup/confirm", { state: { email: lowerEmail } });
    } catch (error) {
      setErrorMessage(error?.message);
    }
    setSubmitting(false);
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col sm={12} md={5} lg={6} xl={4} className="px-lg-6 my-5 align-self-center">
          <h1 className="display-4 text-center mb-3">Create a free account</h1>
          <p className="text-muted text-center mb-5">Start connecting your devices.</p>
          <SignUpForm onSubmit={handleSubmit} />
          <span className="text-danger">{errorMessage}</span>
        </Col>
        <Col sm={12} md={7} lg={6} xl={8} className="d-none d-lg-block px-0">
          <div className="bg-cover h-100 min-vh-100 bg-sign-in" />
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
