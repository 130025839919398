import batchSimImage from "@assets/img/batch.png";
import individualSimImage from "@assets/img/individual.png";
import { useLazyGetBillingBalanceQuery } from "@modules/dashboard/billing/billing-api-slice";
import { useGetTagsQuery } from "@modules/dashboard/simCard/simcards-api-slice";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { intercomUpdate } from "src/lib/utils/Intercom/intercomUtils";
import { useActivateBatchSimsMutation, useActivateSimsMutation, useGetPlansQuery } from "../activateSims-api-slice";
// import { Creators as BillingCreators } from "../../billing/reducer";
import { AddSims, Plans, SimOption } from "../index";

const WIZARD_STEPS = {
  INITIAL: "INITIAL",
  ADD_SIMS: "ADD_SIMS",
  CHOOSE_PLAN: "CHOOSE_PLAN",
};

const SIM_OPTION = {
  INDIVIDUAL: "INDIVIDUAL",
  PACK: "PACK",
};

const DescriptionText = ({ step, simOption }) => {
  if (step === WIZARD_STEPS.INITIAL) {
    return (
      <div>
        Register your SIM to activate it and begin using it.
        <br />
        Add SIMs individually or batch register a pack of SIMs all at once.
      </div>
    );
  }
  if (step === WIZARD_STEPS.ADD_SIMS && simOption === SIM_OPTION.INDIVIDUAL) {
    return (
      <div>
        Find the ICCID and Serial Number on the back of your SIM card.
        <br />
        Add both codes below and optionally assign tags.
      </div>
    );
  }
  if (step === WIZARD_STEPS.ADD_SIMS && simOption === SIM_OPTION.PACK) {
    return (
      <div>
        Find the Batch ID and Serial Number on the back of your SIM card.
        <br />
        Add both codes below and all SIMs from your pack are registered at once.
      </div>
    );
  }
  if (step === WIZARD_STEPS.CHOOSE_PLAN) {
    return (
      <div>
        Only pay for the data you need.
        <br />
        Switch plans and coverage at any time after registration.
      </div>
    );
  }
};

const RegisterSims = () => {
  // ActivateSims
  const [activateSims] = useActivateSimsMutation();
  const [activateBatchSims] = useActivateBatchSimsMutation();

  // GetPlans
  const getPlansResponse = useGetPlansQuery({});
  const plans = getPlansResponse?.data?.plans ?? [];

  // GetTags
  const getTagsResponse = useGetTagsQuery();
  const { tags: allTags } = getTagsResponse.data ?? [];

  // GetBillingBalance
  const [getBillingBalance] = useLazyGetBillingBalanceQuery();
  const navigate = useNavigate();

  const [step, setStep] = useState(WIZARD_STEPS.INITIAL);
  const [tags, setTags] = useState([]);
  const [sims, setSims] = useState([]);
  const [simOption, setSimOption] = useState(null);

  const resetData = () => {
    setTags([]);
    setSims([]);
    setSimOption(null);
  };

  const handleXClick = () => {
    resetData();
    navigate("/dashboard/sim-cards");
  };

  const handleSimOptionClick = (option) => {
    setSimOption(option);
    setStep(WIZARD_STEPS.ADD_SIMS);
  };

  const handleAddSims = () => {
    setStep(WIZARD_STEPS.CHOOSE_PLAN);
  };

  const handleComplete = async ({ plan_id }) => {
    if (simOption === SIM_OPTION.INDIVIDUAL) {
      await activateSims({
        sims: sims.map(({ iccid, serialNumber }) => ({
          iccid,
          code: serialNumber,
        })),
        plan_id,
        tags,
      });
    } else {
      await activateBatchSims({
        batches: sims.map(({ iccid, serialNumber }) => ({
          batch_id: iccid,
          code: serialNumber,
        })),
        plan_id,
        tags,
      });
    }
    await getBillingBalance();
    setStep(WIZARD_STEPS.INITIAL);
    navigate("/dashboard/sim-cards", {
      state: { registerSimsModalOpen: true },
    });
    intercomUpdate({}, { has_registered_sims: true });
  };

  const handleCancel = () => {
    resetData();
    setStep(WIZARD_STEPS.INITIAL);
  };

  return (
    <div className="h-100">
      <Modal fullscreen show={true}>
        <Modal.Body className="p-0 bg-dark bg-ellipses register-sims-bg">
          <div className="d-flex flex-column header-text-container">
            <div className="d-flex justify-content-end close-button">
              <span onClick={handleXClick} className="fe fe-x cursor-pointer text-white" />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h6 className={`mb-4 text-uppercase text-center text-white`}>
                {step === WIZARD_STEPS.INITIAL && "Step 1 of 3"}
                {step === WIZARD_STEPS.ADD_SIMS && "Step 2 of 3"}
                {step === WIZARD_STEPS.CHOOSE_PLAN && "Step 3 of 3"}
              </h6>
              <h1 className="display-4 text-center text-white strong">
                {step === WIZARD_STEPS.CHOOSE_PLAN ? "Select Plan" : "Register SIMs"}
              </h1>
              <div className="d-flex justify-content-center">
                <div className="fs-16 text-center text-white w-50">
                  <DescriptionText simOption={simOption} step={step} />
                </div>
              </div>
            </div>
          </div>
          {step === WIZARD_STEPS.INITIAL && (
            <div className="sim-option-cards options">
              <div className="d-flex justify-content-center w-100">
                <div className="w-30">
                  <SimOption
                    onClick={() => handleSimOptionClick(SIM_OPTION.INDIVIDUAL)}
                    title="Individual SIMs"
                    buttonText="Register SIMs individually"
                    image={individualSimImage}
                  />
                </div>
                <div className="w-30 ms-4">
                  <SimOption
                    onClick={() => handleSimOptionClick(SIM_OPTION.PACK)}
                    title="SIM packs"
                    buttonText="Register batch of SIMs"
                    image={batchSimImage}
                  />
                </div>
              </div>
            </div>
          )}
          {step === WIZARD_STEPS.ADD_SIMS && (
            <div className="sim-option-cards sims">
              <div className="d-flex justify-content-center w-100">
                <div className="w-75">
                  {/* <LoadingWrapper isLoading={getTagsResponse.isFetching}> */}
                  <AddSims
                    allTags={allTags}
                    tags={tags}
                    setTags={setTags}
                    sims={sims}
                    setSims={setSims}
                    handleAddSims={handleAddSims}
                    handleCancel={handleCancel}
                    isIndividual={simOption === SIM_OPTION.INDIVIDUAL}
                  />
                  {/* </LoadingWrapper> */}
                </div>
              </div>
            </div>
          )}
          {step === WIZARD_STEPS.CHOOSE_PLAN && (
            <div className="sim-option-cards plans">
              <div className="d-flex justify-content-center w-100">
                <div className="w-75">
                  <div className="d-flex w-100 justify-content-between">
                    <Plans plans={plans} handleComplete={handleComplete} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegisterSims;
