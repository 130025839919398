import { ConfirmationModal } from "@components/ConfirmationModal";
import { Loader } from "@components/Loader";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { Alert, Badge, Button, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import SimpleTable from "../../components/SimpleTable";
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditCustomerMutation,
  useEditUserMutation,
  useGetCustomerQuery,
  useGetUsersQuery,
} from "../account-api-slice";
import { EditOrganization, EditUser, InviteMember } from "../index";

const Organization = ({ setTab }) => {
  // CreateUser
  const [createUser] = useCreateUserMutation();

  // EditUser
  const [editUser] = useEditUserMutation();



  // DeleteUser
  const [deleteUser] = useDeleteUserMutation();

  // GetCustomer
  const getCustomerResponse = useGetCustomerQuery();
  const { refetch: refetchCustomer } = getCustomerResponse;
  const customerDetails = getCustomerResponse.data?.customerDetails || {};

  // GetUsers
  const getUsersResponse = useGetUsersQuery();
  const { refetch: refetchUsers } = getUsersResponse;
  const users = getUsersResponse.data?.users || [];

  // EditCustomer
  const [editCustomer] = useEditCustomerMutation();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isEditOrganizationModalOpen, setIsEditOrganizationModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(null);

  const columns = [
    { Header: "Id", accessor: "user_id", isVisible: false },
    {
      Header: "MEMBER",
      accessor: "name",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ value }) => {
        return value === "active" ? (
          <Badge className="pt-2" bg="success">
            Active
          </Badge>
        ) : (
          <Badge className="pt-2" bg="warning">
            Inactive
          </Badge>
        );
      },
    },
    {
      Header: "LAST LOGIN",
      accessor: "last_login",
    },
    {
      Header: "ROLE",
      accessor: "role",
    },
  ];


  const handleEdit = async ({ name, email, role }) => {
    const response = await editUser({ id: activeUser, name, email, role });
    // @ts-expect-error this object structure is so if there's an error
    if (response?.error?.data?.errorMessage) {
      // @ts-expect-error this object structure is so if there's an error
      setErrorMessage(response?.error?.data?.errorMessage as string);
    }

    
    setIsEditUserModalOpen(false);
    setActiveUser(false);
  };

  const handleDelete = async () => {
    const response = await deleteUser(activeUser);
    // @ts-expect-error this object structure is so if there's an error
    if (response?.error?.data?.errorMessage) {
      // @ts-expect-error this object structure is so if there's an error
      setErrorMessage(response?.error?.data?.errorMessage as string);
    }
    toast.success("User deleted!");
    setIsDeleteConfirmModalOpen(false);
    setActiveUser(null);
  };

  const handleCreateUser = async (values) => {
    setErrorMessage("");

    const response = await createUser(values);
    // @ts-expect-error this object structure is so if there's an error
    if (response?.error?.data?.errorMessage) {
      // @ts-expect-error this object structure is so if there's an error
      setErrorMessage(response?.error?.data?.errorMessage as string);
    } else {
      refetchUsers();
    }
    setIsInviteModalOpen(false);
  };

  const handleOrganizationEdit = async ({ name }) => {
    await editCustomer({ company_name: name });
    refetchCustomer();
    setIsEditOrganizationModalOpen(false);
  };

  if (getUsersResponse.isFetching) {
    return <Loader />;
  }

  return (
    <div className="d-flex flex-column">
      <div>
        <Card className="h-100">
          <Card.Body>
            <Row className="align-items-center gx-0 h-100">
              <Col>
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">Organization name</h6>
                <h2 className="header-title">{customerDetails.company_name}</h2>
              </Col>
              <Col className="col-auto">
                <Button
                  className=""
                  variant="outline-primary"
                  size="sm"
                  onClick={() => setIsEditOrganizationModalOpen(true)}
                >
                  Edit name
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div>
        <SimpleTable
          title="Team"
          hasPagination={false}
          initialPageSize={1000}
          autoResetPage={false}
          autoResetSortBy={false}
          data={users}
          columns={columns}
          hasPerPage={false}
          hasExport={false}
          customActionButton={
            <Button className="text-nowrap" variant="primary" size="sm" onClick={() => setIsInviteModalOpen(true)}>
              Invite member
            </Button>
          }
          hasActions
          actionItems={[
            {
              title: "Edit",
              handleClick: (row) => {
                setActiveUser(row.values.user_id);
                setIsEditUserModalOpen(true);
              },
            },
            {
              title: "Delete",
              handleClick: async (row) => {
                const user = await Auth.currentAuthenticatedUser();
                if (user?.attributes?.email === row.values.email) {
                  setTab("profile");
                } else {
                  setActiveUser(row.values.user_id);
                  setIsDeleteConfirmModalOpen(true);
                }
              },
            },
          ]}
        />
      </div>
      <InviteMember
        isOpen={isInviteModalOpen}
        handleClose={() => setIsInviteModalOpen(false)}
        handleCreate={handleCreateUser}
      />
      <EditUser
        isOpen={isEditUserModalOpen}
        handleClose={() => setIsEditUserModalOpen(false)}
        handleEdit={handleEdit}
        user={users.find(({ user_id }) => user_id === activeUser)}
      />
      <ConfirmationModal
        isOpen={isDeleteConfirmModalOpen}
        handleCancel={() => {
          setIsDeleteConfirmModalOpen(false);
          setActiveUser(null);
        }}
        handleConfirm={handleDelete}
        subtitle={`Are you sure you want to delete user ${users.find(({ user_id }) => user_id === activeUser)?.email}`}
        title="Delete Team Member"
      />
      <EditOrganization
        isOpen={isEditOrganizationModalOpen}
        organization={customerDetails}
        handleClose={() => setIsEditOrganizationModalOpen(false)}
        handleEdit={handleOrganizationEdit}
      />
      {errorMessage && (
        <div className="mt-3">
          <Alert dismissible onClose={() => setErrorMessage("")} variant="danger my-0">
            {errorMessage}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Organization;
