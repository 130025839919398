import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import { CustomDropdownToggle } from './index';
import { Select } from '../simCard';
import { useState } from 'react';

const FilterDropdown = ({ filters, updateFilter, resetFilter }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDropdownClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const activeFiltersCount = filters.filter(
    ({ selectedOption }) => Object.keys(selectedOption).length > 0
  ).length;

  return (
    <Dropdown show={isOpen} onToggle={handleDropdownClose} autoClose='outside'>
      <Dropdown.Toggle as={CustomDropdownToggle}>
        <Button size='sm' onClick={() => setIsOpen(true)} className='btn-white'>
          <i className='fe fe-sliders me-1' /> Filter
          <span
            className={`badge bg-primary ms-1 ${
              activeFiltersCount === 0 ? 'd-none' : ''
            } `}
          >
            {activeFiltersCount}
          </span>
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu className='py-2' style={{ width: 280 }}>
        <Card.Header>
          <h4 className='card-header-title'>Filters</h4>
          <small
            className='btn-link text-black-50 cursor-pointer'
            onClick={resetFilter}
          >
            Clear filters
          </small>
        </Card.Header>
        <Card.Body className='pb-0'>
          <div className='list-group list-group-flush mt-n4'>
            {filters.map(({ name, column, options, selectedOption }) => (
              <div className='list-group-item' key={column}>
                <Row>
                  <Col>
                    <small>{name}</small>
                  </Col>
                  <Col sm='auto'>
                    <Select
                      options={options}
                      selected={options.find(
                        ({ value }) => value === selectedOption.value
                      )}
                      onClick={(value) => updateFilter(column, value)}
                    />
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Card.Body>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilterDropdown;
