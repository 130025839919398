import { useGetBillingBalanceQuery } from "@modules/dashboard/billing/billing-api-slice";
import { useGetHomeQuery } from "@modules/dashboard/home/home-api-slice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../../../assets/img/logo.png";
import { ConfigureStep, WelcomeStep } from "../index";
import {
  useCreateNetworkMutation,
  useGetPricingQuery,
  useLazyGetAllNetworksQuery,
  useLazyGetNetworkQuery,
} from "../network-api-slice";
import CancelAndExitModal from "./CancelAndExitModal";
import DeployStep from "./DeployStep";

const STEPS = {
  WELCOME: "WELCOME",
  CONFIGURE: "CONFIGURE",
  DEPLOY: "DEPLOY",
};

const CreateNetwork = () => {
  useGetPricingQuery();
  useGetBillingBalanceQuery();

  // GetNetwork
  const [getNetwork] = useLazyGetNetworkQuery();

  // CreateNetwork
  const [createNetwork] = useCreateNetworkMutation();
  const [networkId, setNetworkId] = useState("");

  const [step, setStep] = useState(STEPS.WELCOME);
  const [deployProcessing, setDeployProcessing] = useState(false);
  const [isCancelAndExitModalOpen, setIsCancelAndExitModalOpen] = useState(false);
  const navigate = useNavigate();
  const homeData = useGetHomeQuery();
  const { urls } = homeData.data || {};

  const [getAllNetworks] = useLazyGetAllNetworksQuery();

  const handleDeploy = async (data) => {
    setDeployProcessing(true);
    const networkData = await createNetwork(data);
    if ("error" in networkData) {
      setDeployProcessing(false);
      toast("Please add balance before deploying a private network", { type: "error" });
      return;
    } else if ("data" in networkData) {
      const { network_id } = networkData?.data || {};
      setNetworkId(() => network_id);
      await getNetwork({ id: network_id });
      await getAllNetworks();
    }
    setDeployProcessing(false);
    setStep(STEPS.DEPLOY);
  };

  const handleCancelAndExit = () => {
    navigate("/dashboard/sim-cards");
  };

  return (
    <div className="d-flex flex-column h-100 position-relative">
      <div className="network-steps">
        <div className="d-flex justify-content-center align-items-center position-relative">
          <img src={logo} height="auto" width={160} alt="logo" />
          <div className="display-5 mt-2 ms-3 ">Private Network</div>
          {step === STEPS.CONFIGURE && (
            <>
              <div
                className="position-absolute top-0 start-0 d-flex cursor-pointer title-actions-left"
                onClick={() => setStep(STEPS.WELCOME)}
              >
                <div>
                  <i className="fe fe-arrow-left fs-2 fw-400 " />
                </div>
                <div className="mt-1">Back</div>
              </div>
              <div
                className="position-absolute top-0 end-0 d-flex cursor-pointer title-actions-right"
                onClick={() => setIsCancelAndExitModalOpen(true)}
              >
                <div className="mt-1">Cancel and Exit</div>
              </div>
            </>
          )}
        </div>
        <div className="d-flex justify-content-center display-4 my-5">
          {step === STEPS.WELCOME && <span>Welcome to Simbase Private Network</span>}
          {step === STEPS.CONFIGURE && <span>Configure your network</span>}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center w-75 mt-5">
          {step === STEPS.WELCOME && (
            <WelcomeStep onNextClick={() => setStep(STEPS.CONFIGURE)} url={urls?.private_networks} />
          )}
          {step === STEPS.CONFIGURE && <ConfigureStep onNextClick={handleDeploy} deployProcessing={deployProcessing} />}
          {step === STEPS.DEPLOY && <DeployStep networkId={networkId} />}
        </div>
      </div>
      <CancelAndExitModal
        isOpen={isCancelAndExitModalOpen}
        onConfirmClick={handleCancelAndExit}
        onBackClick={() => setIsCancelAndExitModalOpen(false)}
      />
    </div>
  );
};

export default CreateNetwork;
