import { Button, Card } from "react-bootstrap";
import autoRecharge from "@assets/img/autoRecharge.png";
import { usePaymentMethods } from "../../hooks/usePaymentMethods";
import { useAutoTopUp } from "../../hooks/useAutoTopUp";
import AutoRecharge from "../modals/AutoRecharge";
import { useState } from "react";
import { useGetBillingBalanceQuery } from "../../billing-api-slice";
import Loader from "@components/Loader";

const AutoRechargeCard = () => {
  const { paymentMethods } = usePaymentMethods();
  const { isTopUpFetchSuccess, autoTopUp, setAutoTopUp, topUpAmount, topUpTreshold } =
    useAutoTopUp();
  const [autoRechargeModalOpen, setAutoRechargeModalOpen] = useState<boolean>(false);
  const balanceResponse = useGetBillingBalanceQuery();
  const { currency_symbol: currencySymbol } = balanceResponse.data ?? {}; // renaming vars to fit current code



  return (
    <>
      <Card className="w-100 me-4">
        <Card.Header>
          <h4 className="card-header-title text-capitalize">Auto recharge</h4>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center h-100">
            <div className="me-3">
              <img src={autoRecharge} alt="auto-recharge" height={64} />
            </div>
            <div className="d-flex flex-column justify-content-between mx-3 h-100">
              <div className="text-body">
                Enable Auto-Recharge to automatically add balance to your account should it drop below a set threshold
              </div>
              {!paymentMethods.find(({ preferred = false }) => preferred === true) && (
                <strong className="fs-5">To use this feature you need to set a default payment method</strong>
              )}
              {isTopUpFetchSuccess ? (
                <div className="mt-1">
                  {autoTopUp ? (
                    <span>
                      <span className="me-1 text-success">●</span>
                      Enabled
                    </span>
                  ) : (
                    <span>
                      <span className="me-1 text-danger">●</span>
                      Disabled
                    </span>
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </div>
            <div className="ms-3">
              <Button
                disabled={!autoTopUp && !paymentMethods.length} // find(({ preferred = false }) => preferred === true)
                onClick={() => {
                  setAutoRechargeModalOpen(true);
                }}
                size="sm"
              >
                {!autoTopUp ? "Enable" : "Disable"}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      <AutoRecharge
        isOpen={autoRechargeModalOpen}
        handleClose={() => setAutoRechargeModalOpen(false)}
        setAutoTopUp={setAutoTopUp}
        autoTopUp={autoTopUp}
        topUpAmount={topUpAmount}
        topUpTreshold={topUpTreshold}
        currencySign={currencySymbol}
      />
    </>
  );
};

export default AutoRechargeCard;
