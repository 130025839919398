import React from 'react';
import { Button, Card } from 'react-bootstrap';

const SimOption = ({ title, buttonText, onClick, image }) => (
  <Card className='h-100'>
    <Card.Body className='p-3'>
      <div className='d-flex flex-column justify-content-between h-100'>
        <h4 className='text-center mt-3 mb-0 text-capitalize'>{title}</h4>
        <div className='d-flex justify-content-center w-100'>
          <img
            alt='card'
            src={image}
            className='w-75 cursor-pointer p-2'
            onClick={onClick}
          />
        </div>
        <div>
          <Button className='mb-3 w-100' variant='light' onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default SimOption;
