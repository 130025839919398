import { Col, Container, Row } from 'react-bootstrap';

const OverviewHeader = ({ title }) => (
  <div className='header mb-3'>
    <Container fluid>
      <div className='header-body'>
        <Row className='align-items-end'>
          <Col>
            <h6 className='header-pretitle'>Overview</h6>
            <h1 className='header-title'>{title}</h1>
          </Col>
        </Row>
      </div>
    </Container>
  </div>
);

export default OverviewHeader;
