import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';

const TransparentInput = ({
  placeholder,
  value,
  onChange,
  icon,
  darkIcon = false,
  ...rest
}) => {
  return (
    <InputGroup
      className='input-group-flush input-group-merge input-group-reverse'
      {...rest}
    >
      <FormControl
        placeholder={placeholder}
        aria-label='search'
        value={value}
        onChange={onChange}
      />
      <span className='input-group-text'>
        <i className={`fe fe-${icon} ${darkIcon ? 'text-dark' : ''}`} />
      </span>
    </InputGroup>
  );
};

TransparentInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  darkIcon: PropTypes.bool,
  icon: PropTypes.string,
};

export default TransparentInput;
