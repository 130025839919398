import { useGetCustomerQuery } from "@modules/dashboard/account/account-api-slice";
import { Button, Card, Badge } from "react-bootstrap";
import { useGetCountriesQuery } from "../../billing-api-slice";

const BillingSettingsCard = ({ handleEditBillingDetails }) => {
  const getCustomerResponse = useGetCustomerQuery();
  const details = getCustomerResponse.data?.customerDetails ?? {}; // details is the legacy name
  
  const countriesResponse = useGetCountriesQuery();
  const { data: countries } = countriesResponse;

  return (
    <Card className="w-100">
      <Card.Header>
        <h4 className="card-header-title text-capitalize">Billing settings</h4>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column">
          <div>
            <strong className="text-body">{details?.company_name}</strong>
            <br />
            {details?.address?.street1 || details?.address?.street2 ? (
              <>{`${details?.address?.street1 || ""} ${details?.address?.street2 || ""}`}</>
            ) : (
              <span className="text-muted fst-italic">Address</span>
            )}
            <br />
            {details?.address?.zip && <span>{details?.address?.zip}, </span>}
            {details?.address?.city && <span>{details?.address?.city}, </span>}
            {details?.address?.state && <span>{details?.address?.state}</span>}
            {details?.address?.zip || details?.address?.city || details?.address?.state ? null : (
              <span className="text-muted fst-italic">City, Postal/ZIP, State/Province</span>
            )}
            <br />
            {countries.find(({ iso }) => details?.address?.country === iso)?.name || (
              <span className="text-muted fst-italic">Country</span>
            )}
            <br />
          </div>
          <div>
            {details?.tax?.tax_number_valid === "True" && <Badge bg="success">VAT verified</Badge>}
            {details?.tax?.tax_number_valid === "False" && <Badge bg="danger">VAT not verified</Badge>}
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex justify-content-end">
          <Button onClick={handleEditBillingDetails} variant="primary">
            Edit
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default BillingSettingsCard;
