import { coreApi } from "@core/rtk-api";

export const activateSimsApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getPlans: builder.query<any, { iccid?: string }>({
        query: ({ iccid = null }) => ({ url: iccid ? `/rateplans/sim/${iccid}` : "/rateplans" }),
        transformResponse: (data: any) => {
          const plans = [];
          Object.values(data).forEach((plan) => {
            plans.push(plan);
          });
          return { plans };
        },
      }),
      verifySn: builder.query<any, { iccid: string; serialNumber: string }>({
        query: ({ iccid, serialNumber }) => `/simcards/${iccid}/validate-sn/${serialNumber}`,
      }),
      verifyBatchSn: builder.query<any, { batchId: string; serialNumber: string }>({
        query: ({ batchId, serialNumber }) => `/simcards/batch/${batchId}/sn/${serialNumber}`,
      }),
      activateSims: builder.mutation<any, { plan_id: string; sims: { iccid: string; code: string }[]; tags: string[] }>(
        {
          query: ({ plan_id, sims, tags }) => ({
            url: "/simcards/activate",
            method: "PATCH",
            body: { plan_id, sims, tags },
          }),
          invalidatesTags: ["Simcards"],
        },
      ),
      activateBatchSims: builder.mutation<
        any,
        { plan_id: string; batches: { batch_id: string; code: string }[]; tags: string[] }
      >({
        query: ({ plan_id, batches, tags }) => ({
          url: "/simcards/batch/activate",
          method: "PATCH",
          body: { plan_id, batches, tags },
        }), // TODO: untested
        invalidatesTags: ["Simcards"],
      }),
    };
  },
});

export const {
  useGetPlansQuery,
  useLazyGetPlansQuery,
  useLazyVerifySnQuery,
  useLazyVerifyBatchSnQuery,
  useActivateSimsMutation,
  useActivateBatchSimsMutation,
} = activateSimsApi;
