import  { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { isNumber } from 'chart.js/helpers';

const dropdownOptions = [
  {
    key: '/0',
    value: '/0',
  },
  {
    key: '/24',
    value: '/24',
  },
  {
    key: '/32',
    value: '/32',
  },
];

const IpSegment = ({ value, id, handleChange, isDotVisible = true }) => {
  return (
    <div className='d-flex align-items-end'>
      <Form.Control type='text' value={value} id={id} onChange={handleChange} />
      <div className={`px-2 mb-n2 ${!isDotVisible && 'invisible'}`}>●</div>
    </div>
  );
};

const NewApiKey = ({ isOpen, handleClose, handleCreate, processing }) => {
  const [name, setName] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]);
  const [ip, setIp] = useState(['0', '0', '0', '0']);

  const handleIpChange = (e, index) => {
    const value = Math.round(e.target.value);
    let newIp = [...ip];

    if (isNumber(value) && value >= 0 && value <= 255) {
      newIp[index] = value.toString();
      setIp(newIp);
    }
  };

  const handleCreateApiKey = async (e) => {
    e.preventDefault();
    await handleCreate({
      allowed_ips: `${ip.join('.')}${selectedOption.key}`,
      key_name: name,
      read_only: isReadOnly,
    });
    setName('');
    setIp(['', '', '', '']);
    setSelectedOption(dropdownOptions[0]);
    setIsReadOnly(true);
  };

  return (
    <Modal centered show={isOpen} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <h4 className='card-header-title text-capitalize'>New API key</h4>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleCreateApiKey}>
          <Form.Group className='mb-3' onClick={(e) => e.stopPropagation()}>
            <Form.Label>Friendly name</Form.Label>
            <InputGroup className='p-0'>
              <FormControl
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Label className='m-0'>Key type</Form.Label>
          <h4 className='header-subtitle pb-2'>
            The read only key cannot manage core resources
          </h4>
          <div className='btn-group-toggle pt-3 pb-5'>
            <input
              type='radio'
              className='btn-check'
              checked={isReadOnly}
              onChange={() => {}}
            />
            <label
              className='btn btn-white me-1'
              onClick={() => setIsReadOnly(true)}
            >
              <i className='fe fe-check-circle' /> Read only
            </label>
            <input
              type='radio'
              className='btn-check'
              name='options'
              checked={!isReadOnly}
              onChange={() => {}}
            />
            <label
              className='btn btn-white'
              onClick={() => setIsReadOnly(false)}
            >
              <i className='fe fe-check-circle' /> Read and write
            </label>
          </div>
          <Form.Label className='m-0'>Whitelist Source IP</Form.Label>
          <h4 className='header-subtitle pb-2'>
            The source (inbound rules) for the traffic in a range of IPv4
            addresses, in CIDR block notation
          </h4>
          <div className='d-flex w-75'>
            {ip.map((segment, index) => (
              <IpSegment
                key={index}
                id={`ip-${index}`}
                value={segment}
                handleChange={(e) => handleIpChange(e, index)}
                isDotVisible={index !== ip.length - 1}
              />
            ))}
            <div>
              <DropdownButton
                variant='outline-secondary'
                title={selectedOption.value}
              >
                {dropdownOptions.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    onClick={() => setSelectedOption(option)}
                    href='#'
                  >
                    {option.value}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>
          <div className='d-flex mt-5'>
            <Button
              variant='primary'
              type='submit'
              onClick={handleCreateApiKey}
            >
              {processing ? (
                <>
                  <span
                    className='spinner-border spinner-border-sm me-3'
                    role='status'
                  />
                  Loading...
                </>
              ) : (
                'Create API Key'
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NewApiKey;
