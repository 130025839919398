import { Auth as AmplifyAuth } from "aws-amplify";

import { useEffect } from "react";
// import { connect } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { PERMISSIONS } from "../../core/constants";
import { checkPermission } from "../../lib/utils/permissions";
// import { Creators } from "../auth";
import { Account } from "./account";
import { RegisterSims } from "./activateSims";
import { Billing } from "./billing";
import { SideBar } from "./components";
import { Home } from "./home";
import { Integrations } from "./integrations";
import { ConfigureIPSec, CreateNetwork, Network, NetworkDetails } from "./network";
import { Details, SimCards } from "./simCard";

function RequirePermissions({ children, permissions }) {
  let isAllowed = false;
  permissions.forEach((permission) => {
    if (checkPermission(permission)) {
      isAllowed = true;
    }
  });
  return isAllowed ? children : <Navigate replace to="/dashboard/home" />;
}

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  // const { url } = useMatch();

  useEffect(() => {
    (async () => {
      // Check if user is signed in
      try {
        await AmplifyAuth.currentAuthenticatedUser();
      } catch (err) {
        navigate("/auth");
      }
    })();
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <div className="d-flex h-100">
      <div className="sidebar-container border-end sticky-top vh-100">
        <SideBar />
      </div>
      <div className="flex-grow-1 mh-100 p-3 main-content">
        <Routes>
          <Route
            path="/sim-cards/:iccid"
            element={
              <RequirePermissions permissions={[PERMISSIONS.VIEW_SIMCARDS]}>
                <Details />
              </RequirePermissions>
            }
          />
          <Route
            path="/sim-cards"
            element={
              <RequirePermissions permissions={[PERMISSIONS.VIEW_SIMCARDS]}>
                <SimCards />
              </RequirePermissions>
            }
          />
          <Route
            path="/profile"
            element={
              <RequirePermissions
                permissions={[
                  PERMISSIONS.VIEW_ACCOUNT_PROFILE,
                  PERMISSIONS.VIEW_ACCOUNT_ORGANIZATION,
                  PERMISSIONS.VIEW_ACCOUNT_API,
                ]}
              >
                <Account />
              </RequirePermissions>
            }
          />
          <Route
            path="/billing"
            element={
              <RequirePermissions permissions={[PERMISSIONS.VIEW_BILLING]}>
                <Billing />
              </RequirePermissions>
            }
          />
          <Route path="/activate-sims" element={<RegisterSims />} />
          <Route path="/home" element={<Home />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/private-network/create" element={<CreateNetwork />} />
          <Route path="/private-network" element={<Network />} />
          <Route path="/private-network/:id/" element={<NetworkDetails />} />
          <Route path="/private-network/:id/ipsec-configure" element={<ConfigureIPSec />} />
          <Route path="*" element={<Navigate replace to="/dashboard/home" />} />
        </Routes>
      </div>
      <ToastContainer autoClose={4000} />
    </div>
  );
};

export default Router;
