import { useState } from 'react';
import { OverviewHeader } from '../../components';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { PERMISSIONS } from '../../../../core/constants';
import { Api, Webhooks } from '../index';

const tabs = [
  { key: 'api', label: 'API', permission: PERMISSIONS.VIEW_ACCOUNT_API },
  {
    key: 'webhooks',
    label: 'Webhooks',
  },
  // TODO Remove until feature is implemented
  // {
  //   key: 'apps',
  //   label: 'Apps',
  // },
];

const Integrations = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <div className='d-flex justify-content-center'>
      <div className='d-flex w-75 flex-column mt-5'>
        <OverviewHeader title='Integrations' />
        <div className='px-3'>
          <Nav
            variant='tabs'
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
          >
            {tabs.map(({ key, label }) => (
              <NavItem key={key}>
                <NavLink eventKey={key}>{label}</NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <div className='py-4 px-2 h-100'>
          {activeTab === tabs[0].key && <Api />}
          {activeTab === tabs[1].key && <Webhooks />}
          {/* TODO Remove for now */}
          {/*{activeTab === tabs[2].key && <Apps />}*/}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
