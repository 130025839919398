import { Card, Col, Row } from 'react-bootstrap';

const Activity = ({ title, subtitle, time, icon }) => {
  return (
    <div className='list-group-item'>
      <Row>
        <Col sm='auto'>
          <div className='avatar avatar-sm'>
            <div className='avatar-title fs-lg bg-primary-soft rounded-circle text-primary'>
              <i className={`fe fe-${icon}`} />
            </div>
          </div>
        </Col>
        <Col className='ms-n2'>
          <h5 className='mb-1 fw-700'>{title}</h5>
          <p className='small text-gray-700 mb-0'>{subtitle}</p>
          <small className='text-muted'>{time}</small>
        </Col>
      </Row>
    </div>
  );
};

const ActivityFeed = ({ activities = [] }) => {
  return (
    <Card className='w-100 h-100 mb-0'>
      <Card.Header>
        <h4 className='card-header-title text-capitalize'>Activity Feed</h4>
      </Card.Header>
      <Card.Body>
        <div className='list-group list-group-flush list-group-activity my-n3 activity-feed-container'>
          {activities.map(({ title, body, timestamp, icon }, index) => (
            <Activity
              key={index}
              icon={icon}
              title={title}
              subtitle={body}
              time={timestamp}
            />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ActivityFeed;
