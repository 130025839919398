import { Button } from "react-bootstrap";

import { TagDropdown } from "../index";

const ActionBar = ({
  simcards,
  show = false,
  selectedRowIds,
  handleSimStateChange,
  setIsSmsModalOpen,
  setIsAssignNetworkModalOpen,
  handleApplyTags,
  setIsDeleteSimModalOpen,
  showAssignToPrivateNetwork = false,
  tags,
  page,
}) => {
  if (!show) {
    return null;
  }
  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex justify-content-between align-items-center action-menu-bar p-2 w-100">
        <div className="d-flex">
          <div className="d-flex align-items-center pe-4 ps-3 sims-selected">
            {`${Object.keys(selectedRowIds).length} SIMs selected:`}
          </div>
          <div
            className="d-flex align-items-center text-white px-2 cursor-pointer"
            onClick={() => handleSimStateChange(null, "enabled")}
          >
            <i className="fe fe-play pe-2" /> Enable
          </div>
          <div
            className="d-flex align-items-center text-white px-2 cursor-pointer"
            onClick={() => handleSimStateChange(null, "disabled")}
          >
            <i className="fe fe-pause pe-2" /> Disable
          </div>
          <div className="pipe" />
          <div
            className="d-flex align-items-center text-white px-2 cursor-pointer"
            onClick={() => {
              setIsSmsModalOpen(true);
            }}
          >
            <i className="fe fe-send pe-2 icon" /> Send SMS
          </div>
          {showAssignToPrivateNetwork && (
            <>
              <div className="pipe" />
              <div
                className="d-flex align-items-center text-white px-2 cursor-pointer"
                onClick={() => {
                  setIsAssignNetworkModalOpen(true);
                }}
              >
                <i className="fe fe-upload-cloud pe-2 icon" /> Assign to Private Network
              </div>
            </>
          )}
        </div>
        <div className="d-flex">
          <TagDropdown
            simcards={simcards}
            singleSim={false}
            tags={tags}
            page={page}
            selectedRowIds={selectedRowIds}
            handleApply={handleApplyTags}
          />
          <Button
            variant="danger"
            className="me-2 p-2 delete-button-color"
            onClick={() => setIsDeleteSimModalOpen(true)}
          >
            <div className="d-flex align-items-center">
              <i className="fe fe-trash-2 pe-2 icon" /> Delete SIM Cards
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
