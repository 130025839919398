import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Loader from "./Loader";

export const ConfirmationModal = ({
  isOpen,
  title = "Confirm action",
  subtitle = "",
  handleConfirm = () => {},
  handleCancel = () => {},
  leftButtonText = "Cancel",
  rightButtonText = "Confirm",
}) => {
  const [processing, setProcessing] = useState(false);
  useEffect(() => setProcessing(false), [isOpen]);

  const onCancelClick = async (): Promise<void> => {
    setProcessing(true);
    await handleCancel();
  };

  const onConfirmClick = async (): Promise<void> => {
    setProcessing(true);
    await handleConfirm();
  };

  return (
    <Modal dialogClassName="w-35" centered show={isOpen} onHide={handleCancel}>
      <Modal.Header>
        <h3 className="header-title fw-600">{title}</h3>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="fs-4">{subtitle}</div>
          <div className="d-flex justify-content-between mt-4">
            {processing ? (
              <Loader />
            ) : (
              <>
                <div className="w-45">
                  <Button className="w-100" variant="secondary" onClick={onCancelClick}>
                    {leftButtonText}
                  </Button>
                </div>
                <div className="w-45">
                  <Button className="w-100" variant="danger" type="submit" onClick={onConfirmClick}>
                    {rightButtonText}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
