// https://stackoverflow.com/questions/70098392/react-chartjs-2-with-chartjs-3-error-arc-is-not-a-registered-element
import { BarElement, CategoryScale, Chart, LinearScale } from "chart.js";

import { useEffect, useMemo, useState } from "react";
import { Card, Nav, NavItem, NavLink, Spinner } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

import { useLazyGetDataUsageQuery, useLazyGetUsageGraphQuery } from "../simcards-api-slice";

import SimpleTable from "../../components/SimpleTable";
import { SimpleInfoCard } from "../index";
import HeaderCards from "./HeaderCards";

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);

const mapData = (data) => {
  let labels = [];
  let values = [];

  data.forEach(({ date, volume, month }) => {
    labels.push(date?.substring(5) || month);
    values.push(volume);
  });

  return {
    labels,
    datasets: [
      {
        data: values,
        borderWidth: 1,
        borderColor: "#2C7BE5",
        backgroundColor: "#2C7BE5",
        barPercentage: 0.2,
        borderRadius: 100,
        skipNull: true,
      },
    ],
  };
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  hover: {
    intersect: true,
  },
  plugins: {
    filler: {
      propagate: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      intersect: true,
      callbacks: {
        label: (context) => {
          const { raw } = context || {};
          return raw + " MB";
        },
        labelColor: (context) => {
          const { backgroundColor, borderColor } = context.dataset || {};
          return {
            borderColor,
            backgroundColor,
          };
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      reverse: true,
    },
    y: {
      title: {
        display: true,
        text: "MB",
      },
      grid: {
        display: false,
      },
    },
  },
};

const Usage = ({
  tags,
  iccid,
  // processing,
  allTags,
  sim_state,
  device_name,
  // updateSimStatus,
  changeSimName,
  // getUsageGraph,
  // usageGraph,
  last_update,
  zero_sessions,
  sessions,
  usage_data,
  // graphProcessing,
  // dataUsage,
  // getDataUsage,
  // dataUsageProcessing,
}) => {
  // const [dataUsagePage, setDataUsagePage] = useState(0);
  const [graphType, setGraphType] = useState("daily");

  const columns = [
    {
      Header: "START DATE",
      accessor: "start",
    },
    {
      Header: "STOP DATE",
      accessor: "stop",
    },
    {
      Header: "DATA USAGE",
      accessor: "usage",
    },
    {
      Header: "MOBILE NETWORK OPERATOR",
      accessor: "carrier",
    },
    {
      Header: "RADIO",
      accessor: "radio",
    },
    {
      Header: "LOCATION",
      accessor: "country",
    },
  ];

  const [getUsageGraph, getUsageGraphResponse] = useLazyGetUsageGraphQuery();

  useEffect(() => {
    getUsageGraph({ iccid, type: graphType === "daily" ? "day" : "month" });
  }, [getUsageGraph, iccid, graphType]);

  // const getDataUsageResponse = useLazyGetDataUsageQuery({ iccid})
  // const dataUsage = getDataUsageResponse?.data?.dataUsage || [];

  const [getDataUsage, getDataUsageResponse] = useLazyGetDataUsageQuery();
  const dataUsage = getDataUsageResponse?.data?.dataUsage || [];

  useEffect(() => {
    getDataUsage({ iccid });
  }, [getDataUsage, iccid]);

  const memoizedUsageGraphData = useMemo(
    () => mapData(getUsageGraphResponse?.data?.usageGraph || []),
    [getUsageGraphResponse.data?.usageGraph]
  );

  return (
    <div className="d-flex flex-column">
      <HeaderCards
        tags={tags}
        iccid={iccid}
        // processing={processing}
        allTags={allTags}
        sim_state={sim_state}
        device_name={device_name}
        // updateSimStatus={updateSimStatus}
        changeSimName={changeSimName}
      />
      <div className="d-flex py-3">
        <div className="w-70">
          <Card className="mb-0">
            <Card.Header>
              <h4 className="card-header-title text-capitalize">Data used</h4>
              <Nav variant="tabs" activeKey={graphType} onSelect={(tab) => setGraphType(tab)}>
                <NavItem>
                  <NavLink eventKey="daily">Daily</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="monthly">Monthly</NavLink>
                </NavItem>
              </Nav>
            </Card.Header>
            <Card.Body>
              <div className="chart">
                {getUsageGraphResponse.isLoading ? (
                  <div className="d-flex justify-content-center h-100 align-items-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Bar data={memoizedUsageGraphData} options={options} />
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex flex-column justify-content-between ps-3 w-40">
          <div className="w-100 pb-2">
            <SimpleInfoCard label="usage this month" title={usage_data} icon="bar-chart-2" />
          </div>
          <div className="w-100 py-2">
            <SimpleInfoCard label="data sessions this month" title={sessions} icon="activity" />
          </div>
          <div className="w-100 py-2">
            <SimpleInfoCard label="zero sessions this month" title={zero_sessions} icon="alert-circle" />
          </div>
          <div className="w-100 pt-2">
            <SimpleInfoCard label="last data sessions" title={last_update} icon="clock" />
          </div>
        </div>
      </div>
      <div>
        <SimpleTable
          title="Data records (CDRs)"
          data={dataUsage}
          processing={getDataUsageResponse.isLoading}
          noDataText="No data usage yet"
          columns={columns}
          // currentPage={dataUsagePage}
          // setCurrentPage={setDataUsagePage}
        />
      </div>
    </div>
  );
};
export default Usage;
