import { Button, Card } from "react-bootstrap";

const Plan = ({
  handleOpenChangePlan,
  details: { name, sms_to_device, mb, sms, day, countries, currency_symbol } = {},
}) => {
  return (
    <Card className="w-100">
      <Card.Body className="d-flex flex-column">
        <h6 className="text-muted mb-0">ACTIVE PLAN</h6>
        <div className="d-flex justify-content-center">
          <h3 className="display-4 mt-4 mb-5">{name}</h3>
        </div>
        <hr className="m-0" />
        <div className="d-flex justify-content-center">
          <div className="border-end py-4 d-flex justify-content-center flex-column align-items-center w-100">
            <h6 className="text-muted mb-2">SIM SUBSCRIPTION</h6>
            <div className="fw-700 fs-2">{`${currency_symbol}${day} / day`}</div>
          </div>
          <div className="py-4 d-flex justify-content-center flex-column align-items-center w-100">
            <h6 className="text-muted mb-2">DATA RATE</h6>
            <div className="fw-700 fs-2">{`${currency_symbol}${mb} / MB`}</div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="mb-3">
          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
              <small>SMS From Device</small>
              <small>{`${currency_symbol}${sms} / SMS`}</small>
            </li>
            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
              <small>SMS To Device</small>
              <small>{`${currency_symbol}${sms_to_device} / SMS`}</small>
            </li>
            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
              <small>Countries</small>
              <small>{countries}</small>
            </li>
          </ul>
        </div>
        <div className="d-flex flex-grow-1 align-items-end justify-content-center">
          <div className="w-100">
            <Button className="w-100" variant="primary" onClick={handleOpenChangePlan}>
              Change Plan & Coverage
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Plan;
