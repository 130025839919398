import cs from "classnames";

import { useState } from "react";
import { Button, Card, FormControl, InputGroup } from "react-bootstrap";

const Webhook = ({ isLastHook = false, url: currentUrl = "", title, type, handleClick }) => {
  const [url, setUrl] = useState(currentUrl);

  const onClick = () => {
    handleClick({
      url,
      type,
    });
  };

  return (
    <div className="position-relative">
      <p className="webhook-title position-absolute">{title}</p>
      <Card className={cs("webhook-card", { "mb-0": isLastHook })}>
        <Card.Body>
          <InputGroup className="p-0">
            <FormControl value={url} onChange={(e) => setUrl(e.target.value)} type="string" name="primaryAddress" />
            <Button onClick={onClick}>Save & Test</Button>
          </InputGroup>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Webhook;
