import { useGetHomeQuery } from "@modules/dashboard/home/home-api-slice";
import { Navigate } from "react-router-dom";

import { Loader } from "../../../../components";
import { useGetAllNetworksQuery } from "../network-api-slice";
import Networks from "./Networks";

const Network = () => {
  useGetHomeQuery();
  const getAllNetworkResponse = useGetAllNetworksQuery();
  const networks = getAllNetworkResponse.data || [];

  if (getAllNetworkResponse.isFetching) {
    return <Loader />;
  }

  if (!getAllNetworkResponse.isFetching && networks.length === 0) {
    // Redirect to create network if there aren't any networks
    return <Navigate replace to="/dashboard/private-network/create" />;
  }

  return <Networks />;
};

export default Network;
