import { Alert, Modal } from 'react-bootstrap';
import { Plans } from '../../activateSims';

const ChangePlanModal = ({
  isOpen,
  handleClose,
  plans,
  handleComplete,
  errorMessage = '',
  currentPlan,
}) => {
  return (
    <Modal
      dialogClassName='max-w-90 w-90'
      centered
      show={isOpen}
      onHide={handleClose}
    >
      <Modal.Header className='border-0 pb-0 d-flex justify-content-end'>
        <span
          onClick={handleClose}
          className='fe fe-x cursor-pointer fs-1 mt-n2 me-n3'
        />
      </Modal.Header>
      <Modal.Body className='pt-2'>
        <div className='d-flex w-100 justify-content-between'>
          <Plans
            plans={plans}
            handleComplete={handleComplete}
            currentPlan={currentPlan}
          />
        </div>
        {errorMessage && (
          <div className='my-3'>
            <Alert variant='danger'>{errorMessage}</Alert>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ChangePlanModal;
