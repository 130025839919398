import { OverviewHeader } from "../../components";
import NetworksTable from "./NetworksTable";

const Networks = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex w-75 flex-column mt-5">
        <OverviewHeader title="Private Networks" />
        <div className="py-4 px-2 h-100">
          <NetworksTable />
        </div>
      </div>
    </div>
  );
};

export default Networks;
