import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { IError } from "src/types/types";
import { FormField } from "../../index";

const NewPasswordForm = ({ code, email, onSubmit }) => {
  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      onSubmit={({ password }, { setSubmitting }) =>
        onSubmit({ email, password: code, newPassword: password }, { setSubmitting })
      }
      validate={(values) => {
        const errors: IError = {};
        if (!values.password) {
          errors.password = "Required";
        } else if (!/[0-9]+/.test(values.password)) {
          errors.password = "Password must contain at least one number";
        } else if (!/[A-Z]+/.test(values.password)) {
          errors.password = "Password must contain at least one uppercase letter";
        } else if (!/[a-z]+/.test(values.password)) {
          errors.password = "Password must contain at least one lowercase letter";
        } else if (
          // eslint-disable-next-line
          !/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(values.password)
        ) {
          errors.password = "Password must contain at least one special character";
        } else if (values.password.length < 8) {
          errors.password = "Password must have at least 8 characters";
        }
        if (!values.confirmPassword) {
          errors.confirmPassword = "Required";
        }
        if (values.confirmPassword !== values.password) {
          errors.confirmPassword = "Passwords must match";
        }
        return errors;
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <FormField
            value={values.password}
            handleChange={handleChange}
            touched={touched.password}
            handleBlur={handleBlur}
            error={errors.password}
            placeholder="Enter your password"
            name="password"
            type="password"
            label="Password"
          />
          <FormField
            value={values.confirmPassword}
            handleChange={handleChange}
            touched={touched.confirmPassword}
            handleBlur={handleBlur}
            error={errors.confirmPassword}
            placeholder="Repeat your password"
            name="confirmPassword"
            type="password"
            label="Confirm password"
          />
          <Button type="submit" variant="primary" className="w-100 mb-3 mt-3 lift" disabled={isSubmitting}>
            Send
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default NewPasswordForm;
