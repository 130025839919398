import { useHover } from "src/lib/utils/hooks";

import { forwardRef, useEffect, useRef } from "react";

interface ICheckbox {
  charsLimit?: number;
  indeterminate?: boolean;
  label?: string;
  readOnly?: boolean;
  checked?: boolean;
  labelClassname?: string;
  onClick?: (label: any) => void;
  hasAction?: boolean;
  actionIcon?: JSX.Element;
  handleAction?: (e: any) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, ICheckbox>(
  (
    {
      charsLimit = 100,
      indeterminate,
      label = "",
      readOnly = false,
      checked = false,
      labelClassname,
      onClick,
      hasAction = false,
      actionIcon,
      handleAction,
      ...rest
    },
    ref,
  ) => {
    const { ref: hoverRef, isHovered } = useHover();
    const defaultRef = useRef<HTMLInputElement | null>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (typeof resolvedRef === "object" && resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate || false;
      }
    }, [resolvedRef, indeterminate]);

    const renderLabel = () => {
      if (typeof label === "string" && label.length > charsLimit) {
        return label.slice(0, charsLimit) + "..";
      }
      return label;
    };

    return (
      <div ref={hoverRef} className="form-check" onClick={() => onClick(label)}>
        {readOnly ? (
          <input
            className="form-check-input"
            type="checkbox"
            checked={true}
            readOnly={readOnly}
            ref={resolvedRef}
            {...rest}
          />
        ) : (
          <input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            onChange={() => onClick(label)}
            ref={resolvedRef}
            {...rest}
          />
        )}
        <label className={`form-check-label ${labelClassname}`}>{renderLabel()}</label>
        {hasAction && (
          <span className={`float-end ${isHovered ? "visible" : "invisible"} cursor-pointer`} onClick={handleAction}>
            {actionIcon}
          </span>
        )}
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";
