import { Loader } from "@components/Loader";
import { ToggleButton } from "@components/ToggleButton";
import { SIM_STATE } from "@core/constants";
import TagBadges from "@modules/dashboard/components/TagBadges";
import copyToClipboard from "copy-to-clipboard";
import { useEffect, useRef, useState } from "react";
import { Badge, Card, Col, FormControl, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";
import { getItem, setItem } from "src/lib/utils/localStorage";
import { TagDropdown } from "../index";
import { useAddTagToSimsMutation, useRemoveTagToSimsMutation, useUpdateSimStatusMutation } from "../simcards-api-slice";

const HeaderCards = ({ device_name, sim_state, iccid, tags, allTags, changeSimName }) => {
  const [addTagToSims, addTagToSimsResponse] = useAddTagToSimsMutation();
  const [removeTagToSims, removeTagToSimsResponse] = useRemoveTagToSimsMutation();
  const [deviceName, setDeviceName] = useState(device_name);
  useEffect(() => {
    setDeviceName(() => device_name);
  }, [device_name]);

  const [simEdit, setSimEdit] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [simNameProcessing, setSimNameProcessing] = useState(false);
  const [simState, setSimState] = useState<SIM_STATE>();
  const nameRef = useRef<HTMLInputElement | null>();

  const [updateSimStatus, updateSimStatusResponse] = useUpdateSimStatusMutation();
  const [freezeSimStateChange, setFreezeSimStateChange] = useState<boolean>(false);

  useEffect(() => {
    setSimState(() => sim_state);
  }, [sim_state]);

  useEffect(() => {
    if (updateSimStatusResponse.isSuccess) {
      setSimState(() => updateSimStatusResponse.data?.sim_state);
    }
  }, [updateSimStatusResponse.isSuccess]);

  useEffect(() => {
    if (showOverlay) {
      setTimeout(() => {
        setShowOverlay(false);
      }, 2000);
    }
  }, [showOverlay]);

  const handleSimStateChange = () => {
    const newState = simState === SIM_STATE.ENABLED ? SIM_STATE.DISABLED : SIM_STATE.ENABLED;
    updateSimStatus({
      iccids: [iccid],
      sim_state: newState,
    });
    setFreezeSimStateChange(true);
    toast.success("Sim state change submitted. You will be able to submit a new one in 30 seconds.");
    setItem("freezeSimStateChange", Math.floor(Date.now() / 1000) + 30);
    setTimeout(() => {
      setFreezeSimStateChange(false);
    }, 30000);
  };

  useEffect(() => {
    const freezeSimStateChangeTime = getItem("freezeSimStateChange");
    if (freezeSimStateChangeTime) {
      if (Math.floor(Date.now() / 1000) > freezeSimStateChangeTime) {
        setFreezeSimStateChange(false);
      } else {
        setFreezeSimStateChange(true);
      }
    }
  }, []);

  const handleSimNameChange = async () => {
    setSimNameProcessing(true);
    await changeSimName({ deviceName, iccid });
    setSimNameProcessing(false);
    setSimEdit(false);
  };

  const handleApplyTags = async (tagsToAdd, tagsToRemove, iccids) => {
    if (tagsToAdd.length) {
      await addTagToSims({ iccids, tags: tagsToAdd });
    }
    if (tagsToRemove.length) {
      await removeTagToSims({ iccids, tags: tagsToRemove });
    }
  };

  const isProcessingTagUpdate = useIsProcessing([addTagToSimsResponse.isLoading, removeTagToSimsResponse.isLoading]);

  return (
    <div className="d-flex">
      <div className="w-100 pe-3">
        <Card className="mb-0 h-100">
          <Card.Body>
            <Row className="align-items-center gx-0 h-100">
              <Col>
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">name</h6>
                <div className="d-flex align-items-center justify-content-between">
                  <FormControl
                    className={`fw-bold py-0 header-card-title ${!simEdit ? "border-0 ps-0" : "ps-2"}`}
                    onClick={() => {
                      if (!simEdit) {
                        setSimEdit(true);
                      }
                    }}
                    onBlur={async () => {
                      await handleSimNameChange();
                      setSimEdit(false);
                    }}
                    ref={nameRef}
                    value={deviceName || ""}
                    onChange={(e) => setDeviceName(e.target.value)}
                    type="text"
                  />
                  <div className="ms-2 d-flex align-items-end">
                    {simNameProcessing ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <>
                        {!simEdit && (
                          <span
                            onClick={() => {
                              setSimEdit(true);
                              nameRef.current.focus();
                            }}
                            className="h2 fe fe-edit text-muted mb-0 cursor-pointer"
                          />
                        )}
                        {simEdit && (
                          <span
                            onClick={() => handleSimNameChange()}
                            className={`h2 fe fe-save text-muted mb-0 cursor-pointer`}
                            tabIndex={0}
                            id="save-name-change"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div className="w-100 px-3">
        <Card className="mb-0 h-100">
          <Card.Body>
            <Row className="align-items-center gx-0 h-100">
              <Col>
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">state</h6>
                <div className="d-flex justify-content-between">
                  {simState === SIM_STATE.ENABLED && (
                    <Badge className="d-flex align-items-center" bg="success">
                      Enabled
                    </Badge>
                  )}
                  {simState === SIM_STATE.DISABLED && (
                    <Badge className="d-flex align-items-center" bg="danger">
                      Disabled
                    </Badge>
                  )}
                  {simState === SIM_STATE.BLOCKED && (
                    <Badge className="d-flex align-items-center" bg="warning">
                      Blocked
                    </Badge>
                  )}
                  {updateSimStatusResponse.isLoading ? (
                    <div className="me-3">
                      <Loader size={1.5} className="pe-2" />
                    </div>
                  ) : (
                    <ToggleButton
                      handleChange={handleSimStateChange}
                      value={simState === SIM_STATE.ENABLED}
                      disabled={simState === SIM_STATE.INSUFFICIENT_FUNDS || freezeSimStateChange}
                    />
                  )}
                </div>
                {/* <span className="small">{freezeSimStateChange && "Please wait 30 seconds before changing the state again"}</span> */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div className="w-100 px-3">
        <Card className="mb-0 h-100">
          <Card.Body>
            <Row className="align-items-center gx-0 h-100">
              <Col>
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">iccid</h6>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="header-title">{iccid}</h2>
                  <OverlayTrigger
                    placement="top"
                    show={showOverlay}
                    overlay={(props) => <Tooltip {...props}>Copied to clipboard!</Tooltip>}
                  >
                    <span
                      onClick={() => {
                        copyToClipboard(iccid);
                        if (!showOverlay) {
                          setShowOverlay(true);
                        }
                      }}
                      className={`h2 fe fe-clipboard text-muted mb-1 cursor-pointer`}
                    />
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div className="w-100 ps-3">
        <Card className="mb-0 h-100">
          <Card.Body>
            <Row className="align-items-start gx-0 h-100">
              <Col>
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">tags</h6>
                <TagBadges tags={tags} />
              </Col>
              <Col className="col-auto d-flex align-items-center h-100 ">
                {isProcessingTagUpdate ? (
                  <Loader size={1} />
                ) : (
                  <TagDropdown
                    singleSim
                    tags={allTags}
                    simTags={tags || []}
                    page={[]}
                    iccid={iccid}
                    handleApply={handleApplyTags}
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default HeaderCards;
