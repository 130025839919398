import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";

import { Card } from "react-bootstrap";
import { toast } from "react-toastify";

import { useCreateWebhookMutation, useGetWebhooksQuery } from "../integrations-api-slice";

import { Loader } from "../../../../components";
import { Webhook } from "../index";

const Webhooks = () => {
  // CreateWebhook
  const [createWebhook] = useCreateWebhookMutation();

  // GetWebhooks
  const getWebhookResponse = useGetWebhooksQuery();
  const { refetch: refetchWebhooks } = getWebhookResponse;
  const webhooks = getWebhookResponse.data || [];

  const isProcessing = useIsProcessing([getWebhookResponse.isFetching, getWebhookResponse.isLoading]);

  const handleCreateWebhook = async ({ url, type }) => {
    const response = await createWebhook({ url, type });
    if ("data" in response && "errorMessage" in response.data) {
      toast.error(response.data.errorMessage as string);
    } else {
      await refetchWebhooks();
      toast.success("Success");
    }
  };

  return (
    <div>
      {isProcessing && <Loader />}
      {!isProcessing &&
        webhooks.map((hooks, index) => (
          <Card key={index}>
            <Card.Body>
              <h2 className="mb-5">{hooks.title}</h2>
              {hooks.webhooks.map(({ webhook_type, description, url }, index) => (
                <Webhook
                  //  name={field_name}
                  type={webhook_type}
                  url={url}
                  key={webhook_type}
                  title={description}
                  isLastHook={index === webhooks.length + 1}
                  handleClick={handleCreateWebhook}
                />
              ))}
            </Card.Body>
          </Card>
        ))}
    </div>
  );
};

export default Webhooks;
