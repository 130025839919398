import { useCustomerIo } from "src/lib/utils/hooks/useCustomerIo";

import { useEffect } from "react";

const CustomerIOTracker: React.FC = () => {
  useCustomerIo(); // booting and page tracking
  useEffect(() => {
    const _cio = (window as any)._cio || [];
    (window as any)._cio = _cio;

    let c: number;

    const a: (f: string) => (...args: any[]) => void = function (f) {
      return function (...args: any[]) {
        _cio.push([f].concat(args));
      };
    };

    const b: string[] = ["load", "identify", "sidentify", "track", "page", "on", "off"];
    for (c = 0; c < b.length; c++) {
      _cio[b[c]] = a(b[c]);
    }

    const t = document.createElement("script"),
      s = document.getElementsByTagName("script")[0];
    t.async = true;
    t.id = "cio-tracker";
    t.setAttribute("data-site-id", "df0c859a2636c965a8ff");
    t.setAttribute("data-use-array-params", "true");
    t.setAttribute("data-use-in-app", "true");
    t.src = "https://assets.customer.io/assets/track-eu.js";
    s.parentNode.insertBefore(t, s);
  }, []);

  return null;
};

export default CustomerIOTracker;
