import { ToggleButton } from "@components/ToggleButton";
import { IMEI_LOCK_STATE, PUBLIC_IP_STATE } from "@core/constants";
import {
  useLazyGetActivityTimelineQuery,
  useLazyGetNetworkRegistrationQuery,
  useLazyGetSimQuery,
  useResetNetworkMutation,
} from "@modules/dashboard/simCard/simcards-api-slice";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";
import { ActivityTimeline, InfoCard, NetworkRegistrationModal } from "../index";
import HeaderCards from "./HeaderCards";
import MapBox from "./MapBox";
import PublicIPModal from "./PublicIPModal";
import TrafficPolicyModal from "./TrafficPolicyModal";

export const Overview = ({
  renderTrigger,
  device_name,
  sim_state,
  tags,
  iccid,
  apn,
  imei,
  throttle,
  usage_data,
  country,
  radio,
  hardware,
  public_ip_enabled,
  ip,
  carrier,
  last_update,
  lat,
  lon,
  allTags,
  trafficPolicies,
  policy,
  changeSimName,
  imei_lock,
  imeiLockProcessing,
  updateImeiLock,
  public_ip_firewall,
  in_session,
  network_id = null,
}) => {
  // GetSim
  const [getSim, getSimResponse] = useLazyGetSimQuery();

  renderTrigger;
  // ResetNetwork
  const [resetNetwork, resetNetworkResponse] = useResetNetworkMutation();

  // GetNetworkRegistration
  const [getNetworkRegistration, getNetworkRegistrationResponse] = useLazyGetNetworkRegistrationQuery();
  const networkRegistration = getNetworkRegistrationResponse.data?.networkRegistration ?? [];
  const [showNetworkRegistrationModal, setShowNetworkRegistrationModal] = useState(false);

  const networkRegistrationIsProcessing = useIsProcessing([
    getNetworkRegistrationResponse.isLoading,
    getNetworkRegistrationResponse.isFetching,
  ]);

  const [isTrafficPolicyOpen, setIsTrafficPolicyOpen] = useState(false);
  const [isPublicIPOpen, setIsPublicIPOpen] = useState(false);
  const navigate = useNavigate();

  const [getActivityTimeline, getActivityTimelineResponse] = useLazyGetActivityTimelineQuery();
  const logs = getActivityTimelineResponse.data?.logs ?? [];

  useEffect(() => {
    if (iccid) {
      getActivityTimeline({ iccid });
    }
  }, [getActivityTimeline, iccid]);
  const primaryInfo = [
    {
      key: "Connection status",
      value: (
        <>
          <span className={`me-1 text-${in_session && last_update !== "No session" ? "success" : "danger"}`}>●</span>
          <span>{last_update}</span>
        </>
      ),
    },
    { key: "Usage this month", value: usage_data },
    { key: "Country", value: country },
    { key: "Network", value: carrier },
    { key: "Radio Access Network", value: radio },
  ];

  const secondaryInfo = [
    { key: "APN", value: apn },
    { key: "IMEI", value: imei },
    { key: "Hardware", value: hardware },
    {
      key: "IP",
      value: ip,
      actionButton: (
        <>
          {!network_id &&
            ip !== PUBLIC_IP_STATE.TERMINATING_PUBLIC_IP &&
            ip !== PUBLIC_IP_STATE.DEPLOYING_PUBLIC_ID && (
              <Button className="btn-primary" size="sm" onClick={() => setIsPublicIPOpen(true)}>
                {public_ip_enabled ? "Manage Fixed IP" : "Assign Fixed IP"}
              </Button>
            )}
          {network_id && (
            <Button
              className="btn-primary"
              size="sm"
              onClick={() => {
                navigate(`/dashboard/private-network/${network_id}`); //#TODO: check that the url works after migration
              }}
            >
              See Network Details
            </Button>
          )}
        </>
      ),
    },
    { key: "Throttle Speed (kbps)", value: throttle },
  ];

  const handleNetworkReset = () => {
    resetNetwork({ iccid });
    toast.success("Network reset!");
  };

  const handleUpdateImeiLock = async () => {
    await updateImeiLock({
      iccids: [iccid],
      imei_lock: imei_lock === IMEI_LOCK_STATE.OFF ? IMEI_LOCK_STATE.ON : IMEI_LOCK_STATE.OFF,
    });
    await getSim({ iccid });
  };

  const handleRegistrationEvents = () => {
    getNetworkRegistration({ iccid });
    setShowNetworkRegistrationModal(true);
  };

  const isImeiLockProcessing = useIsProcessing([imeiLockProcessing, getSimResponse.isFetching]);
  return (
    <div className="d-flex flex-column overview">
      <HeaderCards
        tags={tags}
        iccid={iccid}
        allTags={allTags}
        sim_state={sim_state}
        device_name={device_name}
        changeSimName={changeSimName}
      />
      <div className="d-flex py-4">
        <div className="w-100 pe-4">
          <InfoCard infoItems={primaryInfo} />
        </div>
        <div className="w-100 pe-4">
          <InfoCard infoItems={secondaryInfo} />
        </div>
        <div className="w-100">
          <MapBox countryName={country} coords={[lon, lat]} isEmpty={isNaN(lon) || isNaN(lat)} />{" "}
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100">
          <ActivityTimeline activities={logs} />
        </div>
        <div className="d-flex flex-column ms-4 w-100">
          <div className="d-flex h-100">
            <Card className="mb-3 w-100">
              <Card.Body>
                <div className="d-flex justify-content-between h-100">
                  <div className="d-flex">
                    <div className="d-flex align-items-center pe-4">
                      <i className="fe fe-radio" />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h4 className="mb-1 network-status">Network Status</h4>
                      <p className="card-text small text-muted mb-1">
                        Check your connection and reset to retry connecting to the mobile network.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center ps-2">
                    <div className="w-100">
                      <Button
                        className="w-100 mb-1 text-nowrap network-buttons"
                        variant="primary"
                        size="sm"
                        onClick={handleNetworkReset}
                      >
                        {resetNetworkResponse.isLoading ? (
                          <span>
                            <span className="spinner-border spinner-border-sm me-2" />
                            Loading...
                          </span>
                        ) : (
                          "Reset connection"
                        )}
                      </Button>
                    </div>
                    <div className="w-100">
                      <Button
                        className="text-nowrap w-100 network-buttons"
                        variant="light"
                        size="sm"
                        onClick={handleRegistrationEvents}
                      >
                        {networkRegistrationIsProcessing ? (
                          <span>
                            <span className="spinner-border spinner-border-sm me-2" />
                            Loading...
                          </span>
                        ) : (
                          "Registration events"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="d-flex h-100">
            <Card className="mb-3 w-100">
              <Card.Body>
                <div className="d-flex justify-content-between h-100">
                  <div className="d-flex">
                    <div className="d-flex align-items-center pe-4">
                      <i className="fe fe-lock" />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h4 className="mb-1 theft-protection">Theft Protection (IMEI)</h4>
                      <p className="card-text small text-muted mb-1">
                        Automatically disable the SIM when used in different device.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <ToggleButton
                      disabled={isImeiLockProcessing}
                      value={imei_lock === IMEI_LOCK_STATE.ON}
                      handleChange={handleUpdateImeiLock}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="d-flex h-100">
            <Card className="mb-0 w-100">
              <Card.Body>
                <div className="d-flex justify-content-between h-100">
                  <div className="d-flex">
                    <div className="d-flex align-items-center pe-4">
                      <i className="fe fe-cloud-drizzle" />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h4 className="mb-1 traffic-policy">Traffic Policy</h4>
                      <p className="card-text small text-muted mb-1">
                        Throttle the data speed of SIM after usage exceed monthly defined quota.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Button
                      className="me-2 text-nowrap"
                      variant="primary"
                      size="sm"
                      onClick={() => setIsTrafficPolicyOpen(true)}
                    >
                      Set traffic policy
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <TrafficPolicyModal
        options={trafficPolicies}
        isOpen={isTrafficPolicyOpen}
        iccid={iccid}
        handleClose={() => setIsTrafficPolicyOpen(false)}
        currentPolicy={policy}
      />
      <PublicIPModal
        isOpen={isPublicIPOpen}
        handleClose={() => setIsPublicIPOpen(false)}
        iccid={iccid}
        isManage={public_ip_enabled}
        ruleFirewall={public_ip_firewall}
      />
      <NetworkRegistrationModal
        isOpen={showNetworkRegistrationModal}
        handleClose={() => setShowNetworkRegistrationModal(false)}
        data={networkRegistration}
      />
    </div>
  );
};

export default Overview;
