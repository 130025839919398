import { useState } from "react";
import { useGetSelfQuery, useUpdateSelfMutation } from "../account-api-slice";
import CommunicationTable from "./CommunicationTable";

const Notifications = () => {
  const [updateSelf] = useUpdateSelfMutation();

  const [isUpdating, setIsUpdating] = useState(false);

  const getSelfResponse = useGetSelfQuery();
  const { refetch: getSelf } = getSelfResponse;
  const { data: self } = getSelfResponse ?? {};

  const communication = self?.communication || [];

  return (
    <div className="d-flex flex-column">
      {communication.map(({ title, fields }) => (
        <div key={title} className="mt-4">
          <CommunicationTable
            title={title}
            fields={fields}
            updateSelf={updateSelf}
            getSelf={getSelf}
            communication={self?.communication}
            setIsUpdating={setIsUpdating}
            isUpdating={isUpdating}
          />
        </div>
      ))}
    </div>
  );
};

export default Notifications;
