import { Button, Modal } from 'react-bootstrap';
import smallCc from '@assets/img/small-cc.png';
import { useNavigate } from 'react-router-dom';

const BalanceModal = ({ isOpen, handleClose, balance }) => {
  const navigate = useNavigate();

  return (
    <Modal centered dialogClassName='w-30' show={isOpen} onHide={handleClose}>
      <Modal.Body className='pt-3'>
        <div className='d-flex align-items-center flex-column justify-content-center pb-4'>
          <div className='d-flex justify-content-end w-100'>
            <span
              onClick={handleClose}
              className='fe fs-24 fe-x cursor-pointer'
            />
          </div>
          <img src={smallCc} alt='credit card' width={97} height={82} />
          <div className='fw-700 mt-4'>Current balance</div>
          <div>
            <div className='display-3 mb-0 text-success mt-8px'>{`${balance?.currency_symbol} ${balance?.balance}`}</div>
          </div>
          <div className='text-center'>
            Make sure you have enough balance to keep your devices connected
          </div>
        </div>
        <div className='d-flex justify-content-between w-100'>
          <Button size='sm' variant='light' onClick={handleClose}>
            Continue to SIMs
          </Button>
          <Button
            size='sm'
            variant='primary'
            onClick={() => navigate('billing')}
          >
            + Add balance
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BalanceModal;
