import { Modal, ModalBody } from 'react-bootstrap';
import SimpleTable from '../../components/SimpleTable';

const NetworkRegistrationModal = ({ isOpen, handleClose, data }) => {
  const columns = [
    {
      Header: 'COUNTRY',
      accessor: 'country',
    },
    {
      Header: 'NETWORK',
      accessor: 'carrier',
    },
    {
      Header: 'TIME',
      accessor: 'timestamp',
    },
    {
      Header: 'CONNECTION STATUS',
      accessor: 'result_code',
      Cell: ({ value }) => (
        <span
          className={`me-1 ${
            value === 'Allowed' ? 'text-success' : 'text-danger'
          }`}
        >
          {`● ${value}`}
        </span>
      ),
    },
  ];

  return (
    <Modal centered show={isOpen} onHide={handleClose}>
      <ModalBody>
        <SimpleTable
          title='Network events'
          titleFontWeight='500'
          data={data}
          columns={columns}
          hasPerPage={false}
          hasExport={false}
          initialPageSize={10}
        />
      </ModalBody>
    </Modal>
  );
};

export default NetworkRegistrationModal;
