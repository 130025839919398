import { SIM_STATE } from "@core/constants";
import { coreApi } from "@core/rtk-api";
import { Simcard } from "src/types/types";

export const simcardsApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getSim: builder.query<Simcard, { iccid: string; silently?: boolean }>({
        query: ({ iccid, silently = false }) => `/simcards/${iccid}${silently ? "?silently=true" : ""}`,
        providesTags: (_, __, arg) => [{ type: "Simcard", id: arg.iccid }],
        transformResponse(response: Simcard, __, arg) {
          return { ...response, processing: !arg.silently };
        },
      }),
      getDashboardParameters: builder.query<any, void>({
        query: () => ({
          url: "/dashboard_parameters",
        }),
        transformResponse: (response: any) => {
          return {
            filters: response.filters,
            trafficPolicies: response.traffic_policies,
            radio: response.radio,
            regions: response.regions,
            months: response.months,
            currencies: response.currencies,
            publicIp: response.public_ip,
          };
        },
      }),
      getSims: builder.query<{ sims: Simcard[]; pageCount: number; totalSims: number; page: number }, any | void>({
        query: (params?: { search; page; pageSize; state; coverage; tags; sort; dir; network_id }) => {
          return "/simcards?" + new URLSearchParams(params).toString();
        },
        providesTags: ["Simcards"],
        transformResponse(response: { sims: Simcard[]; total_pages: number; total_records: number; page: number }) {
          return {
            sims: response.sims,
            pageCount: response.total_pages,
            totalSims: response.total_records,
            page: response.page,
          };
        },
      }),
      getTags: builder.query<any, void>({
        query: () => "/tags",
        providesTags: ["Tags"],
      }),
      updateSimStatus: builder.mutation<
        { iccids: string[]; sim_state: SIM_STATE },
        { iccids: string[]; sim_state: SIM_STATE }
      >({
        query: ({ iccids, sim_state }) => ({
          url: `/simcards/status`,
          method: "PATCH",
          body: { iccids, sim_state },
        }),
        transformResponse(_, __, arg) {
          return { iccids: arg.iccids, sim_state: arg.sim_state };
        },
        invalidatesTags: (_, __, { iccids }): { type: "Simcards" | "Simcard"; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: "Simcard" as const,
              id: iccid,
            })),
            { type: "Simcards" },
          ];
        },
      }),
      getNetworkRegistration: builder.query<any, { iccid: string }>({
        query: ({ iccid }) => ({
          url: `/simcards/${iccid}/lu`,
        }),
        transformResponse: (response: { record?: []; records?: [] }) => {
          return { networkRegistration: response?.records || response?.record };
        },
      }),
      resetNetwork: builder.mutation<any, { iccid: string }>({
        query: ({ iccid }) => ({ url: `/simcards/${iccid}/reset`, method: "POST" }),
      }),
      getSimsExport: builder.query<any, any>({
        query: (params: { search; state; coverage; tags; sort; dir }) => {
          return `/simcards/csv?` + new URLSearchParams(params).toString();
        },
      }),
      changeSimName: builder.mutation<any, { deviceName: string; iccid: string }>({
        query: ({ deviceName, iccid }) => ({
          url: `/simcards/${iccid}/name`,
          method: "PATCH",
          body: { device_name: deviceName },
        }),
        transformResponse: (_, __, arg) => ({ device_name: arg.deviceName }),
      }),
      getPlan: builder.query<any, { plan_id: string }>({
        query: ({ plan_id }) => `/rateplans/${plan_id}`,
        transformResponse: (data: any) => ({ simPlan: data }),
      }),
      getIpPricing: builder.query<any, void>({
        query: () => `/pricing`,
        transformResponse: (data: any) => ({ ipPricing: data }),
      }),
      getSms: builder.query<any, { iccid: string }>({
        query: ({ iccid }) => `simcards/${iccid}/sms`,
        transformResponse: (data: any) => ({ sms: data.messages }),
      }),
      getSmsUsage: builder.query<any, { iccid: string }>({
        query: ({ iccid }) => `simcards/${iccid}/usage/sms`,
        transformResponse: (data: any) => ({ smsUsage: data.cdrs }),
      }),
      createTag: builder.mutation<any, { tag: string }>({
        query: ({ tag }) => ({
          url: `/tags`,
          method: "POST",
          body: { tag },
        }),
        invalidatesTags: ["Tags"],
      }),
      deleteTag: builder.mutation<any, { tag: string }>({
        query: ({ tag }) => ({
          url: `/tags`,
          method: "DELETE",
          body: { tag },
        }),
        invalidatesTags: ["Tags"],
      }),
      addTagToSims: builder.mutation<any, { tags: string[]; iccids: string[] }>({
        query: ({ tags, iccids }) => ({
          url: "/simcards/tags",
          method: "PATCH",
          body: { tags, iccids },
        }),
        async onQueryStarted({ tags, iccids }, { dispatch, queryFulfilled }) {
          iccids.map(async (iccid) => {
            const patchResult = dispatch(
              simcardsApi.util.updateQueryData("getSim", { iccid }, (draft) => {
                Object.assign(draft, { tags: [...(draft.tags || []), ...tags] });
              }),
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          });
        },
        invalidatesTags: (_, __, { iccids }): { type: "Simcards" | "Simcard"; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: "Simcard" as const,
              id: iccid,
            })),
            { type: "Simcards" },
          ];
        },
      }),
      removeTagToSims: builder.mutation<any, { tags: string[]; iccids: string[] }>({
        query: ({ tags, iccids }) => ({
          url: "/simcards/tags",
          method: "DELETE",
          body: { tags, iccids },
        }),
        async onQueryStarted({ tags, iccids }, { dispatch, queryFulfilled }) {
          iccids.map(async (iccid) => {
            const patchResult = dispatch(
              simcardsApi.util.updateQueryData("getSim", { iccid }, (draft) => {
                draft.tags = draft.tags.filter((tag) => !tags.includes(tag));
              }),
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          });
        },
        invalidatesTags: (_, __, { iccids }): { type: "Simcards" | "Simcard"; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: "Simcard" as const,
              id: iccid,
            })),
            { type: "Simcards" },
          ];
        },
      }),
      sendSms: builder.mutation<any, { iccid: string; sender?: string; message: string }>({
        query: ({ iccid, sender, message }) => ({
          url: `/simcards/${iccid}/sms`,
          method: "POST",
          body: { sender, message },
        }),
      }),
      sendBulkSms: builder.mutation<any, { iccids: string[]; message: string }>({
        query: ({ iccids, message }) => ({
          url: `/simcards/sms`,
          method: "POST",
          body: { iccids, message },
        }),
      }),
      getUsageGraph: builder.query<any, { iccid: string; type: string }>({
        query: ({ iccid, type }) => `/simcards/${iccid}/usage/${type}`,
        transformResponse: (data: any) => ({ usageGraph: data }),
      }),
      getDataUsage: builder.query<any, { iccid: string }>({
        query: ({ iccid }) => `/simcards/${iccid}/usage/table`,
        transformResponse: (data: any) => ({ dataUsage: data.cdrs }),
      }),
      getActivityTimeline: builder.query<any, { iccid: string }>({
        query: ({ iccid }) => `/simcards/${iccid}/audit`,
      }),
      updateImeiLock: builder.mutation<any, { iccids: string[]; imei_lock: boolean }>({
        query: ({ iccids, imei_lock }) => ({
          url: `/simcards/imeilock`,
          method: "PATCH",
          body: { iccids, imei_lock },
        }),
      }),
      setTrafficPolicy: builder.mutation<any, { iccids: string[]; policy: string }>({
        query: ({ iccids, policy }) => ({
          url: `/simcards/policy`,
          method: "PATCH",
          body: { iccids, policy },
        }),
      }),
      changePlan: builder.mutation<any, { iccids: string[]; plan_id: string }>({
        query: ({ iccids, plan_id }) => ({
          url: `/simcards/rateplan`,
          method: "PATCH",
          body: { iccids, plan_id },
        }),
      }),
      deployPublicIp: builder.mutation<any, { iccid: string; data: any }>({
        query: ({ iccid, data }) => ({
          url: `/simcards/${iccid}/publicip`,
          method: "POST",
          body: data,
        }),
      }),
      deletePublicIp: builder.mutation<any, { iccid: string }>({
        query: ({ iccid }) => ({
          url: `/simcards/${iccid}/publicip`,
          method: "DELETE",
        }),
      }),
    };
  },
});

export const {
  useUpdateSimStatusMutation,
  useGetTagsQuery,
  useLazyGetTagsQuery,
  useGetSimsQuery,
  useLazyGetSimsQuery,
  useLazyGetSimQuery,
  useGetSimQuery,
  useGetDashboardParametersQuery,
  useLazyGetDashboardParametersQuery,
  useLazyGetNetworkRegistrationQuery,
  useResetNetworkMutation,
  useLazyGetSimsExportQuery,
  useChangeSimNameMutation,
  useLazyGetPlanQuery,
  useLazyGetIpPricingQuery,
  useGetSmsQuery,
  useLazyGetSmsQuery,
  useLazyGetSmsUsageQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useAddTagToSimsMutation,
  useRemoveTagToSimsMutation,
  useSendSmsMutation,
  useSendBulkSmsMutation,
  useLazyGetUsageGraphQuery,
  useLazyGetDataUsageQuery,
  useLazyGetActivityTimelineQuery,
  useUpdateImeiLockMutation,
  useSetTrafficPolicyMutation,
  useChangePlanMutation,
  useDeployPublicIpMutation,
  useDeletePublicIpMutation,
} = simcardsApi;
