import { Auth } from 'aws-amplify';
import { saveAs } from 'file-saver';

export const downloadFile = async (url, fetchProps) => {
  try {
    const token = (await Auth.currentAuthenticatedUser()).signInUserSession
      .idToken.jwtToken;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...fetchProps,
    });

    if (!response.ok) {
      throw new Error(response);
    }

    // Extract filename from header
    const filename = response.headers
      .get('content-disposition')
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .replaceAll('"', '')
      .trim();
    const blob = await response.blob();

    // Download the file
    saveAs(blob, filename);
  } catch (error) {
    console.error('Failed to download file', error);
    throw new Error(error);
  }
};
