import React from 'react';

export const ToggleButton = ({
  label = null,
  value = true,
  handleChange = () => {},
  disabled = false,
}) => {
  return (
    <div className='form-check form-switch'>
      <input
        className='form-check-input'
        type='checkbox'
        id='switchTwo'
        checked={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <label className='form-check-label'>{label}</label>
    </div>
  );
};

export default ToggleButton;
