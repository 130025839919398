import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";

import { useState } from "react";
import { toast } from "react-toastify";

import { useCreateCustomRequestMutation, useGetSubscriptionsQuery } from "../account-api-slice";

import { Loader } from "../../../../components";
import { SUBSCRIPTION_PLAN_TYPE } from "../../../../core/constants";
import { AddOn, Plan, SubscriptionDetails, SubscriptionModal } from "../index";

const Subscriptions = () => {
  // CreateCustomRequest
  const [createCustomRequest, createCustomRequestResponse] = useCreateCustomRequestMutation();

  // GetSubscription
  const getSubscriptionsResponse = useGetSubscriptionsQuery();
  const subscriptions = getSubscriptionsResponse?.data ?? [];
  const isSubscriptionsProcessing = useIsProcessing([
    getSubscriptionsResponse.isLoading,
    getSubscriptionsResponse.isFetching,
  ]);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [activeType, setActiveType] = useState(SUBSCRIPTION_PLAN_TYPE.CONNECTIVITY);
  const [requestModalData, setRequestModalData] = useState({
    isOpen: false,
  });

  const handlePlanClick = (type) => {
    setActiveType(type);
    setIsDetailsModalOpen(true);
  };

  const handleRequestModalOpen = (_, data) => {
    setRequestModalData({ isOpen: true, ...data });
  };

  const handleRequestModalClose = () => {
    setRequestModalData({ isOpen: false });
  };

  const handleRequestModalSubmit = async (id, message) => {
    await createCustomRequest({
      product: id,
      message,
    });
    if (createCustomRequestResponse?.data?.Message) {
      toast.success(createCustomRequestResponse?.data?.Message);
    }
    handleRequestModalClose();
  };

  if (isSubscriptionsProcessing) {
    return <Loader />;
  }

  return (
    <div>
      <div className="d-flex">
        <div className="d-flex flex-grow-1 gap-4">
          {subscriptions?.connectivity_plans?.products
            ?.filter(({ active }) => active)
            .map((plan) => (
              <Plan
                key={plan.id}
                title="Connectivity plan"
                currency={subscriptions?.currency_symbol}
                handleClick={() => handlePlanClick(SUBSCRIPTION_PLAN_TYPE.CONNECTIVITY)}
                type={SUBSCRIPTION_PLAN_TYPE.CONNECTIVITY}
                buttonTitle="Change Plan"
                isButtonAlwaysActive
                {...plan}
              />
            ))}
          {subscriptions?.support_plans?.products
            ?.filter(({ active }) => active)
            .map((plan) => (
              <Plan
                key={plan.id}
                title="Support plan"
                type={SUBSCRIPTION_PLAN_TYPE.SUPPORT}
                handleClick={() => handlePlanClick(SUBSCRIPTION_PLAN_TYPE.SUPPORT)}
                currency={subscriptions?.currency_symbol}
                isButtonAlwaysActive
                buttonTitle="Change Plan"
                {...plan}
              />
            ))}
        </div>
      </div>
      {subscriptions?.addons?.products.length > 0 && (
        <>
          <hr />
          <div>
            <h2>{subscriptions?.addons?.title}</h2>
            <div className="d-flex flex-wrap gap-4">
              {subscriptions?.addons?.products.map((addon) => (
                <div className="addonContainer" key={addon.id}>
                  <AddOn handleClick={() => handleRequestModalOpen(addon.id, addon.popup)} {...addon} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <SubscriptionDetails
        subscriptions={subscriptions}
        isOpen={isDetailsModalOpen}
        handleClose={() => setIsDetailsModalOpen(false)}
        // createCustomRequest={createCustomRequest} // unused
        type={activeType}
        handleClick={handleRequestModalOpen}
      />

      <SubscriptionModal // ts-ignore
        handleCloseModal={handleRequestModalClose}
        handleSubmit={handleRequestModalSubmit}
        isOpen={requestModalData.isOpen}
        title="Custom Inquiry"
        message="Tell us more about your project to help you decide what fits best"
      />
    </div>
  );
};

export default Subscriptions;
