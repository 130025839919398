import { Loader } from "@components/Loader";
import { useEditCustomerMutation, useGetCustomerQuery } from "@modules/dashboard/account/account-api-slice";
import { Formik } from "formik";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useGetCountriesQuery } from "../billing-api-slice";
import BillingDetailsForm from "./BillingDetailsForm";

const Settings = () => {
  // EditCustomer
  const [editCustomer] = useEditCustomerMutation();

  // GetCountries
  const getCountriesResponse = useGetCountriesQuery();
  const countries = getCountriesResponse?.data ?? [];

  // GetCustomer
  const getCustomerResponse = useGetCustomerQuery();
  const details = getCustomerResponse?.data?.customerDetails ?? {};

  const handleSubmit = async (
    {
      companyName,
      purchaseOrder,
      country,
      attentionTo,
      primaryAddress,
      secondaryAddress,
      zipCode,
      state,
      city,
      vat,
      email_invoice,
    },
    { setSubmitting },
  ) => {
    await editCustomer({
      company_name: companyName,
      address: {
        zip: zipCode,
        country,
        street1: primaryAddress,
        street2: secondaryAddress,
        state,
        city,
      },
      tax: {
        tax_number: vat,
      },
      invoice: {
        invoice_ref: purchaseOrder,
        invoice_attendee: attentionTo,
      },
      email: {
        email_invoice,
      },
    });
    toast.success("Billing details updated!");
    setSubmitting(false);
  };

  if (getCustomerResponse.isFetching) {
    return <Loader />;
  }

  const { company_name, address, invoice, tax, email } = details;

  const { zip, state, city, country, street1, street2 } = address || {};
  const { invoice_ref, invoice_attendee } = invoice || {};
  const { tax_number } = tax || {};
  const { email_invoice } = email || {};

  return (
    <Formik
      initialValues={{
        companyName: company_name,
        purchaseOrder: invoice_ref,
        country: country,
        attentionTo: invoice_attendee,
        primaryAddress: street1,
        secondaryAddress: street2,
        zipCode: zip,
        state: state,
        city: city,
        vat: tax_number,
        email_invoice,
      }}
      onSubmit={handleSubmit}
      validate={(values) => {
        interface Errors {
          attentionTo?: string;
          companyName?: string;
          purchaseOrder?: string;
        }
        const errors: Errors = {};
        if (!values.attentionTo) {
          errors.attentionTo = "Required";
        }
        return errors;
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="w-65">
          <h3 className="fw-500 mb-2">General</h3>
          <Form.Group className="mb-3">
            <Form.Label>Company name</Form.Label>
            <InputGroup className="p-0">
              <FormControl
                value={values.companyName}
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                name="companyName"
              />
            </InputGroup>
            <span className="text-danger">
              {errors.companyName && touched.companyName && (errors.companyName as string)}
            </span>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Purchase Order (PO) Number</Form.Label>
            <small className="form-text text-muted">
              Purchase order (PO) number shows on the invoice and will go into effect immediately.
            </small>
            <InputGroup className="p-0">
              <FormControl
                value={values.purchaseOrder}
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                name="purchaseOrder"
              />
            </InputGroup>
            <span className="text-danger">
              {errors.purchaseOrder && touched.purchaseOrder && (errors.purchaseOrder as string)}
            </span>
          </Form.Group>
          <hr className="my-5" />
          <BillingDetailsForm
            errors={errors}
            handleBlur={handleBlur}
            countries={countries}
            handleChange={handleChange}
            touched={touched}
            setFieldValue={setFieldValue}
            values={values}
          />
          <Button className="mt-3 lift" variant="primary" type="submit" disabled={isSubmitting}>
            Save changes
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Settings;
