import { Auth } from "aws-amplify";

// interface CustomerIoIdentify {
//   email: string;
// }

export const customerIoIdentify = async () => {
  if (typeof window !== "undefined") {
    const _cio = (window as any)._cio || [];
    if (_cio.identify) {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        _cio.identify({ id: user.attributes.email as string });
      } catch (err) {
        console.log(err);
      }
    }
  }
};

export const customerIoTrack = (eventData: { name: string; data: any }) => {
  const _cio = (window as any)._cio || [];
  _cio.track(eventData.name, eventData.data);
};

export const customerIoResetIdentify = () => {
  const _cio = (window as any)._cio || [];
  _cio.reset();
};

export const customerIoTrackPage = (pageName: string): void => {
  const _cio = (window as any)._cio || [];
  _cio.page(pageName);
};
