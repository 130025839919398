import { useContext } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Row,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";

const ACCORDION_EVENT_KEY = {
  ANNOUNCEMENTS: "ANNOUNCEMENTS",
};

const ToggleButton = ({
  eventKey,
}: {
  eventKey: string;
  children?: string;
}) => {
  const accordionCtx = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <Button className="pe-0" variant="link" onClick={decoratedOnClick}>
      {accordionCtx.activeEventKey === ACCORDION_EVENT_KEY.ANNOUNCEMENTS
        ? "Hide"
        : "Show"}
    </Button>
  );
};

const Announcements = ({ announcements = [] }) => {
  return (
    <Accordion defaultActiveKey={ACCORDION_EVENT_KEY.ANNOUNCEMENTS}>
      <Card className="w-100 h-100">
        <Card.Header>
          <h4 className="card-header-title text-capitalize">Announcements</h4>
          <ToggleButton eventKey={ACCORDION_EVENT_KEY.ANNOUNCEMENTS}>
            Click me!
          </ToggleButton>
        </Card.Header>
        <Accordion.Collapse eventKey={ACCORDION_EVENT_KEY.ANNOUNCEMENTS}>
          <Card.Body>
            <div className="list-group list-group-flush list-group-activity my-n3">
              {announcements.map(({ title, url, timestamp, icon }, index) => (
                <div className="list-group-item" key={index}>
                  <Row>
                    <Col className="col-auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                          <i className={`fe fe-${icon}`} />
                        </div>
                      </div>
                    </Col>
                    <Col className="ms-n2 d-flex justify-content-between">
                      <div>
                        <h5 className="mb-1">{title}</h5>
                        <small className="text-muted">
                          <i className="fe fe-clock me-1" />
                          {timestamp}
                        </small>
                      </div>
                      <div>
                        <Button
                          size="sm"
                          variant="white"
                          href={url}
                          target="_blank"
                        >
                          Read
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Announcements;
