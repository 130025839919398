import { Auth } from "aws-amplify";
import { customerIoResetIdentify } from "src/lib/utils/CustomerIo/customerIoUtils";
import { intercomShutdown } from "src/lib/utils/Intercom/intercomUtils";
import { removeItem } from "src/lib/utils/localStorage";
import { checkPermission } from "src/lib/utils/permissions";

import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import logo from "@assets/img/logo.png";
import { PERMISSIONS } from "@core/constants";
import { resetGlobalState } from "@core/rtk-store";

const MENU = [
  {
    icon: "home",
    name: "home",
    title: "Home",
    url: "home",
    permission: PERMISSIONS.VIEW_HOME,
  },
  {
    icon: "box",
    name: "sim-cards",
    title: "SIM cards",
    url: "sim-cards",
    permission: PERMISSIONS.VIEW_SIMCARDS,
  },
  {
    icon: "shield",
    name: "private-network",
    title: "Private Network",
    url: "private-network",
  },
  {
    icon: "credit-card",
    name: "billing",
    title: "Billing",
    url: "billing",
    permission: PERMISSIONS.VIEW_BILLING,
  },
  {
    icon: "link",
    name: "integrations",
    title: "Integrations",
    url: "integrations",
  },
  {
    icon: "settings",
    name: "settings",
    title: "Settings",
    url: "profile",
  },
];

const BASE_URL = "/dashboard";

export const SideBar = () => {
  const navigate = useNavigate();
  const ListItem = ({ item: { icon, title, url }, state }) => {
    return (
      <NavLink
        end
        to={`${BASE_URL}/${url}`}
        state={state}
        className="nav-link" // FIXME: This is causing the active class to not be applied
      >
        <span className="d-flex align-items-center" id={url}>
          <i className={`fe fe-${icon} me-3`} />
          {title}
        </span>
      </NavLink>
    );
  };

  const handleSignOut = async () => {
    try {
      navigate("/auth");
      removeItem("simModalOpen");
      await Auth.signOut();
      resetGlobalState();
      customerIoResetIdentify();
      intercomShutdown();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <div className="navbar-light d-flex flex-column justify-content-between h-100">
      <div>
        <div className="py-4">
          <img src={logo} height="auto" width="100%" alt="logo" />
        </div>
        <ul className="navbar-nav">
          {MENU.filter(({ permission }) => !permission || checkPermission(permission)).map((item) => (
            <li className="nav-item" key={item.name}>
              <ListItem
                item={item}
                state={{
                  tab: item.url === "profile" && "organization",
                }}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex justify-content-around py-3 border-top py-4">
        <div
          className="align-self-center pe-4"
          onClick={() => navigate("/dashboard/profile", { state: { tab: "profile" } })}
        >
          <i className="fe fe-user sidebar-footer-icons fs-1" />
        </div>
        <div className="align-self-center " onClick={handleSignOut}>
          <i className="fe fe-power sidebar-footer-icons fs-1" />
        </div>
      </div>
    </div>
  );
};
