import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";

import { useParams } from "react-router-dom";

import { networkApi, useConfigureIpsecMutation, useGetIpsecQuery } from "../network-api-slice";

import icon from "../../../../assets/img/ipSimCardIcon.png";
import ConfigForm from "./ConfigForm";

const ConfigureIPSec = () => {
  const { id } = useParams();

  // ConfigureIpsec
  const [configureIpsec] = useConfigureIpsecMutation();

  const getPricingResponse = networkApi.endpoints.getPricing.useQueryState();
  const pricing = getPricingResponse.data ?? {};
  const { currency_symbol = "", ipsec = {} } = pricing;

  // GetIpsecOptions
  const getIpsecOptionsResponse = useGetIpsecQuery({ id });
  const { data: ipsecOptions } = getIpsecOptionsResponse;

  const isProcessing = useIsProcessing([getIpsecOptionsResponse.isLoading, getPricingResponse.isFetching]);

  return (
    <div className="d-flex flex-column align-items-center mt-5">
      <small className="text-gray-700">IPSEC</small>
      <h1>Configure your IPSec VPN</h1>
      <small className="text-gray-700 w-30 text-center">
        The IPSec tunnel configuration allow you to authenticate and/or encrypt the data (IP packet) as it traverses the
        tunnel.
      </small>
      <div className="d-flex flex-column w-50 mt-4">
        <hr />
        <div className="d-flex">
          <div>
            <img src={icon} alt="icon" height={40} />
          </div>
          <div className="d-flex flex-column">
            <h4 className="mb-1">Add an IPsec tunnel to your private network </h4>
            <small className="text-muted">{`${currency_symbol}${ipsec.rate} per ${ipsec.unit}. Cancel Anytime`}</small>
          </div>
        </div>
        <hr />
        <ConfigForm ipsecOptions={ipsecOptions} loading={isProcessing} configureIpsec={configureIpsec} id={id} />
      </div>
    </div>
  );
};

export default ConfigureIPSec;
