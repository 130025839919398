import { ConfirmationModal } from "@components/ConfirmationModal";
import { LoadingWrapper } from "@components/LoadingWrapper";
import { PAGE_SIZE_OPTIONS, SIM_STATE } from "@core/constants";
import { useGetBillingBalanceQuery } from "@modules/dashboard/billing/billing-api-slice";
import { Checkbox, IndeterminateCheckbox, Radio } from "@modules/dashboard/components";
import {
  useAssignSimToNetworkMutation,
  useGetAllNetworksQuery,
  useRemoveSimFromNetworkMutation,
} from "@modules/dashboard/network/network-api-slice";
import { useGetDashboardParametersQuery } from "@modules/dashboard/simCard/simcards-api-slice";
import * as Sentry from "@sentry/react";
import { ExportToCsv } from "export-to-csv";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import { useDebounce } from "src/lib/utils/hooks";
import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";
import { getItem, setItem } from "src/lib/utils/localStorage";
import AssignNetworkModal from "../../network/components/AssignNetworkModal";
import {
  ActionBar,
  BalanceModal,
  NoSimsModal,
  OverviewHeader,
  Pagination,
  Select,
  SendSmsModal,
  SimCardTable,
  TransparentInput,
} from "../index";
import {
  useAddTagToSimsMutation,
  useGetTagsQuery,
  useLazyGetSimsExportQuery,
  useLazyGetSimsQuery,
  useRemoveTagToSimsMutation,
  useSendBulkSmsMutation,
  useSendSmsMutation,
  useUpdateSimStatusMutation,
} from "../simcards-api-slice";

const SimCards = () => {
  // RemoveSimsFromNetwork
  const [removeSimFromNetwork] = useRemoveSimFromNetworkMutation();

  // AssignSimToNetwork
  const [assignSimToNetwork] = useAssignSimToNetworkMutation();

  // GetAllNetworks
  const getAllNetworkResponse = useGetAllNetworksQuery();
  const networks = useMemo(() => getAllNetworkResponse.data || [], [getAllNetworkResponse.data]);

  const location = useLocation();
  const navigate = useNavigate();

  const getDashboardParamsResponse = useGetDashboardParametersQuery();
  const filters = getDashboardParamsResponse?.data?.filters ?? {};
  const { coverage } = filters;

  // GetSimsExport
  const [getSimsExport] = useLazyGetSimsExportQuery();

  // AddTagToSims
  const [addTagToSims] = useAddTagToSimsMutation();
  const [removeTagToSims] = useRemoveTagToSimsMutation();
  const [sendSms] = useSendSmsMutation();
  const [sendBulkSms] = useSendBulkSmsMutation();

  // GetDashboardParameters
  useGetDashboardParametersQuery();

  // UpdateSimStatus
  const [updateSimStatus, updateSimStatusResponse] = useUpdateSimStatusMutation();

  // GetTags
  // const [getTags] = useLazyGetTagsQuery();
  const tagsResponse = useGetTagsQuery();
  const { tags } = tagsResponse.data ?? [];

  // GetSims
  const [getSims, getSimsResponse] = useLazyGetSimsQuery({ refetchOnFocus: true });

  const { totalSims, pageCount } = getSimsResponse.data ?? { totalSims: 0 };
  const areSimsLoading = useIsProcessing([
    getSimsResponse.isUninitialized,
    getSimsResponse.isFetching,
    getSimsResponse.isLoading,
  ]);

  const [filter, setFilter] = useState<any | Record<string, unknown>>({});
  const [pageIndexTable, setPageIndex] = useState<number>(0);
  const [isNoSimsModalOpen, setIsNoSimsModalOpen] = useState<boolean>(false);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState<boolean | null>(location.state?.registerSimsModalOpen);
  const [search, setSearch] = useState<string>("");
  const [_, setSort] = useState<[]>([]);

  const [selectedSim, setSelectedSim] = useState(null);
  const debouncedSearch = useDebounce<string>(search);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState<boolean>(false);
  const [isDeleteSimModalOpen, setIsDeleteSimModalOpen] = useState<boolean>(false);
  const [isAssignNetworkModalOpen, setIsAssignNetworkModalOpen] = useState<boolean>(false);

  const columns = useMemo(
    () => [
      {
        Header: "ICCID",
        accessor: "iccid",
      },
      {
        Header: "DEVICE NAME",
        accessor: "device_name",
      },
      {
        Header: "STATE",
        accessor: "sim_state",
        Cell: ({ value }) => <span className="text-capitalize">{value}</span>,
      },
      {
        Header: "LAST EVENT",
        accessor: "last_update",
      },
      {
        Header: "COVERAGE",
        accessor: "coverage",
      },
      {
        Header: "IP ADDRESS",
        accessor: "ip_address",
      },
      {
        Header: "USAGE DATA",
        accessor: "usage_data",
      },
      {
        Header: "USAGE SMS",
        accessor: "usage_sms",
        Cell: ({ row }) => {
          const { usage_sms_mo, usage_sms_mt } = row.original;
          return (
            <span className="text-capitalize">
              <i className="fe fe-arrow-up" />
              <span>{usage_sms_mo}</span>
              &nbsp;
              <i className="fe fe-arrow-down" />
              <span>{usage_sms_mt}</span>
            </span>
          );
        },
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          const tags = row.original.tags;
          if (tags) {
            return (
              <div className="align-middle flex">
                <Stack direction="horizontal" gap={1}>
                  {tags.map((tag: string) => (
                    <span key={tag} className="bg-light rounded px-2">
                      <span className="flex align-items-center h-100">{tag}</span>
                    </span>
                  ))}
                </Stack>
              </div>
            );
          }
        },
      },
    ],
    [],
  );

  const data = useMemo(() => getSimsResponse.data?.sims ?? [], [getSimsResponse.data?.sims]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    toggleAllRowsSelected,
    state: { pageSize, selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: PAGE_SIZE_OPTIONS[2].value,
      },
      manualPagination: true,

      pageCount,
      autoResetPage: true,
      autoResetSortBy: false,
      manualSortBy: true,
      disableMultiSort: true,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  const fetchSims = async () => {
    const { id: sort, desc } = sortBy?.[0] || {};

    // Process private network filter here since it should be remapped with network id
    const filterNetworkId = [];
    if (filter.network) {
      const networkNames = filter.network.split(",");
      networkNames.forEach((name) => {
        const found = networks.find((network) => network.network_name === name);
        if (found) {
          filterNetworkId.push(found.network_id);
        }
      });
    }

    await getSims(
      {
        search,
        page: pageIndexTable + 1,
        pagesize: pageSize,
        sort,
        dir: desc ? "ASC" : "DESC",
        ...filter,
        ...(filterNetworkId.length && { network_id: filterNetworkId.join() }),
      },
      false,
    );
  };

  useEffect(() => {
    // This waits for the response of the sim_state mutation to refresh the table rows
    // if (updateSimStatusResponse.isUninitialized || updateSimStatusResponse.isSuccess) {
    (async () => await fetchSims())();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearch,
    pageIndexTable,
    pageSize,
    filter,
    sortBy,
    updateSimStatusResponse.isSuccess,
    isBalanceModalOpen,
  ]);

  useEffect(() => {
    setSort(sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    if (Object.keys(filter).length === 0 && !debouncedSearch && totalSims === 0 && !areSimsLoading) {
      if (getItem("simModalOpen") !== "opened") {
        setIsNoSimsModalOpen(true);
      }
    } else if (totalSims > 0) {
      setItem("simModalOpen", "opened");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areSimsLoading, totalSims, filter]); //

  useEffect(() => {
    if (location.state?.networkId && networks.length > 0) {
      setFilter({
        network: networks.find(({ network_id }) => network_id === location.state?.networkId)?.network_name,
      });
    }
  }, [location.state, networks]);

  const { data: billingBalance } = useGetBillingBalanceQuery();

  const handleSimStateChange = async (iccid, sim_state) => {
    const iccids = [];
    page.forEach(({ isSelected, values: { iccid } }) => isSelected && iccids.push(iccid));

    if (iccids.length) {
      await updateSimStatus({
        iccids: iccids.length ? iccids : [iccid],
        sim_state,
      });
    }

    if (!iccids.length) {
      Sentry.captureMessage("An empty iccids array was sent to the updateSimStatus mutation");
    }
  };

  const handleSendSms = (e, message) => {
    e.preventDefault();
    if (Object.keys(selectedRowIds).length) {
      const iccids = [];
      Object.keys(selectedRowIds).forEach((key) => {
        iccids.push(page[key].original.iccid);
      });
      sendBulkSms({ message, iccids });
    } else {
      sendSms({ message, iccid: selectedSim });
    }
    setSelectedSim(null);
    setIsSmsModalOpen(false);
  };

  const handleApplyTags = async (tagsToAdd, tagsToRemove, iccids) => {
    if (tagsToAdd.length) {
      await addTagToSims({ iccids, tags: tagsToAdd });
    }
    if (tagsToRemove.length) {
      await removeTagToSims({ iccids, tags: tagsToRemove });
    }
    // getTags();
  };

  const handleDeleteSim = async () => {
    let iccids = [];
    if (Object.keys(selectedRowIds).length) {
      Object.keys(selectedRowIds).forEach((key) => {
        iccids.push(page[key].original.iccid);
      });
    } else {
      iccids = [selectedSim];
    }
    await updateSimStatus({
      iccids,
      sim_state: SIM_STATE.DELETED,
    });
    await fetchSims();
    toast.success("Sim/s deleted!");
    setIsDeleteSimModalOpen(false);
  };

  // This works but it ended up not being needed
  // useEffect(() => {
  //   if (Object.values(selectedRowIds).length) {
  //     const iccids = [];
  //     Object.keys(selectedRowIds).forEach((key) => {
  //       iccids.push(page[key].original.iccid);
  //     });
  //     setSelectedSim(iccids);
  //   }
  // }, [selectedRowIds]);

  const handleFilterChange = (label, type) => {
    // First deselect all rows to prevent bugs
    toggleAllRowsSelected(false);
    const array = filter?.[type]?.split(",") || [];

    const index = array.findIndex((elem) => elem === label);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(label);
    }

    const filterObject = { ...filter };
    if (!array.length) {
      delete filterObject[type];
    } else {
      filterObject[type] = array.join(",");
    }

    setPageIndex(0);
    setFilter(filterObject);
  };

  const handleFilterStateChange = (type) => {
    toggleAllRowsSelected(false);
    if (filter.state === type) {
      const newFilter = { ...filter };
      delete newFilter.state;
      setFilter(newFilter);
    } else {
      setFilter({ ...filter, state: type });
    }
    setPageIndex(0);
  };

  const handleExportClick = async () => {
    const { id: sortId, desc } = sortBy?.[0] || {};

    const params = {
      search,
      coverage,
      tags: tags.map((tag) => tag.tag),
      ...filter,
    };
    if (sortId) {
      params.sortId = sortId;
      params.dir = desc ? "ASC" : "DESC";
    }

    const response = await getSimsExport(params);
    const data = response?.data;
    if (data) {
      const csvExporter = new ExportToCsv({
        filename: data.file_name.replace(".csv", ""),
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      });
      if (data.sims?.length) {
        csvExporter.generateCsv(data.sims);
      }
    }
  };

  const handleResetFilterAndTags = () => {
    setSearch("");
    setFilter({});
    setPageIndex(0);
  };

  const handlePageSizeChange = ({ value }) => {
    toggleAllRowsSelected(false);
    setPageSize(value);
  };

  return (
    <div className="d-flex flex-column h-100">
      <OverviewHeader />
      <div className="d-flex flex-grow-1 mb-2">
        <Card className="mb-0 me-3 table-filter-card">
          <Card.Body className="d-flex flex-column filter-bar-sticky">
            <div className="filter-bar-sticky d-flex flex-column">
              <div>
                <h1 className="header-title pb-16px">{`All ${totalSims} SIMs`}</h1>
                <div className="text-decoration-underline cursor-pointer mb-24px" onClick={handleResetFilterAndTags}>
                  Reset filters and tags
                </div>
                <div className="mb-16px">State</div>
                <div className="mb-24px">
                  <div className="mb-8px">
                    <Radio
                      label="Enabled"
                      onClick={() => handleFilterStateChange(SIM_STATE.ENABLED)}
                      checked={filter?.state === SIM_STATE.ENABLED}
                    />
                  </div>
                  <div className="mb-8px">
                    <Radio
                      label="Disabled"
                      onClick={() => handleFilterStateChange(SIM_STATE.DISABLED)}
                      checked={filter?.state === SIM_STATE.DISABLED}
                    />
                  </div>
                </div>
                <div className="mb-16px">Coverage</div>
                <div className="mb-32px">
                  {getDashboardParamsResponse.isSuccess &&
                    coverage.map((label) => (
                      <div key={label} className="mb-8px">
                        <Checkbox
                          label={label} // FIXME: figure out what happens there with the forwardRef
                          checked={filter?.coverage?.split(",").includes(label)}
                          onClick={(label) => handleFilterChange(label, "coverage")}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="border-top">
                {networks.length > 0 && (
                  <>
                    <div className="mb-16px mt-4">Private Network</div>
                    <div className="mb-32px">
                      {networks
                        .filter(({ network_state }) => network_state !== "Terminated")
                        .map(({ network_name: label }) => (
                          <div key={`network-name-${label}`} className="mb-8px">
                            <Checkbox
                              charsLimit={14} // FIXME: figure out what happens there with the forwardRef
                              label={label}
                              checked={filter?.network?.split(",").includes(label)}
                              onClick={(label) => handleFilterChange(label, "network")}
                            />
                          </div>
                        ))}
                    </div>
                  </>
                )}
                {tags && tags.length > 0 && <div className="mt-32px mb-16px">Tags</div>}
                <div className="mb-32px">
                  <LoadingWrapper isLoading={tagsResponse.isFetching}>
                    {tags &&
                      tags.map(({ tag }, i) => (
                        <div key={i} className="mb-8px">
                          <Checkbox
                            label={tag}
                            checked={filter?.tags?.split(",").includes(tag)}
                            onClick={(label) => handleFilterChange(label, "tags")}
                          />
                        </div>
                      ))}
                  </LoadingWrapper>
                </div>
              </div>
              <div>
                <Button variant="outline-primary" onClick={handleExportClick}>
                  <i className="fe fe-download" /> {`Export ${totalSims} SIMs`}
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="flex-grow-1 simcard-table">
          <Card className="w-100 mb-0 h-100">
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1">
                  <TransparentInput
                    placeholder="Search Device Name, ICCID and more"
                    value={search}
                    onChange={(e) => {
                      toggleAllRowsSelected(false);
                      setSearch(e.target.value);
                    }}
                    icon="search"
                  />
                </div>
                <div>
                  <Select
                    options={PAGE_SIZE_OPTIONS}
                    selected={PAGE_SIZE_OPTIONS.find(({ value }) => value === pageSize)}
                    onClick={handlePageSizeChange}
                  />
                </div>
              </div>
            </Card.Header>
            <ActionBar
              page={page}
              selectedRowIds={selectedRowIds}
              simcards={data.filter((_, index) => {
                // This function sends all the info of the selected sims to the action bar and
                // subsequently to the TagDropDown in order to display the tags of the selected sims correctly
                if (Object.keys(selectedRowIds).includes(index.toString())) {
                  return true;
                }
              })}
              setIsSmsModalOpen={setIsSmsModalOpen}
              setIsAssignNetworkModalOpen={setIsAssignNetworkModalOpen}
              setIsDeleteSimModalOpen={setIsDeleteSimModalOpen}
              showAssignToPrivateNetwork={networks.length > 0}
              handleSimStateChange={handleSimStateChange}
              handleApplyTags={handleApplyTags}
              tags={tags}
              show={Object.keys(selectedRowIds).length > 0}
            />
            <div
              className={`border-bottom d-flex flex-column justify-content-between flex-grow-1 ${
                page.length <= 3 ? "table-responsive" : ""
              }`}
            >
              <SimCardTable
                handleSimStateChange={handleSimStateChange}
                processing={getSimsResponse.isLoading}
                setIsSmsModalOpen={setIsSmsModalOpen}
                setSelectedSim={setSelectedSim}
                totalSims={totalSims}
                headerGroups={headerGroups}
                page={page}
                selectedRowIds={selectedRowIds}
                prepareRow={prepareRow}
                setIsDeleteSimModalOpen={setIsDeleteSimModalOpen}
                setIsAssignNetworkModalOpen={setIsAssignNetworkModalOpen}
                getTableProps={getTableProps}
                filter={filter}
                getTableBodyProps={getTableBodyProps}
              />
              <Pagination
                pageIndex={pageIndexTable}
                setPageIndex={(index) => {
                  toggleAllRowsSelected(false);
                  setPageIndex(index);
                }}
                pageCount={pageCount}
              />
            </div>
          </Card>
        </div>
      </div>
      <SendSmsModal
        isOpen={isSmsModalOpen}
        handleSendMessage={handleSendSms}
        handleClose={() => setIsSmsModalOpen(false)}
      />
      <ConfirmationModal
        subtitle={`Are you sure you want to delete the SIM Card? This action can not be undone.`}
        isOpen={isDeleteSimModalOpen}
        handleCancel={() => setIsDeleteSimModalOpen(false)}
        handleConfirm={handleDeleteSim}
        title="Delete SIM Card"
        rightButtonText="Delete"
      />
      <NoSimsModal isOpen={isNoSimsModalOpen} handleClose={() => setIsNoSimsModalOpen(false)} />
      <BalanceModal
        balance={billingBalance}
        handleClose={() => {
          setIsBalanceModalOpen(false);
          navigate(location.pathname, { replace: true });
          fetchSims();
        }}
        isOpen={isBalanceModalOpen}
      />
      <AssignNetworkModal
        networks={networks.filter(({ network_state }) => network_state !== "Terminated")}
        isOpen={isAssignNetworkModalOpen}
        handleClose={() => setIsAssignNetworkModalOpen(false)}
        assignSimToNetwork={assignSimToNetwork}
        removeSimFromNetwork={removeSimFromNetwork}
        selectedSim={selectedSim}
        iccids={Object.keys(selectedRowIds).reduce((prev, curr) => {
          try {
            prev.push(page[curr]?.original?.iccid);
            return prev;
          } catch {
            Sentry.captureMessage(`curr: ${curr} -- page: ${page}`);
          }
        }, [])}
        fetchSims={fetchSims}
      />
    </div>
  );
};

export default SimCards;
