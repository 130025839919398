import { useEffect } from "react";
import { Badge, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SimpleTable from "../../components/SimpleTable";
import { networkApi, useLazyGetAllNetworksQuery } from "../network-api-slice";

const NetworksTable = () => {
  // This is just used to keep Intercom up to date on active networks
  // This shows up on user attributes
  const [getAllNetworks] = useLazyGetAllNetworksQuery();
  useEffect(() => {
    (async () => getAllNetworks)();
  }, []);

  const navigate = useNavigate();
  const { data: networks } = networkApi.endpoints.getAllNetworks.useQueryState();

  const goToDetails = (id) => navigate(`/dashboard/private-network/${id}/`);

  const columns = [
    {
      Header: "ID",
      accessor: "network_id",
      Cell: ({ value, row: { original } }) => {
        if (original.network_state === "Terminated") {
          return value;
        }
        return (
          <span
            className="cursor-pointer btn-link"
            onClick={() => {
              goToDetails(original.network_id);
            }}
          >
            {value}
          </span>
        );
      },
    },
    {
      Header: "NAME",
      accessor: "network_name",
    },
    {
      Header: "Subnet",
      accessor: "subnet",
    },
    {
      Header: "Size",
      accessor: "hosts",
    },
    {
      Header: "Internet",
      accessor: "internet_access",
    },
    {
      Header: "State",
      accessor: "network_state",
      Cell: ({ value }) => {
        let color = "warning";
        if (value === "Terminated") {
          color = "danger";
        } else if (value === "Deployed") {
          color = "success";
        }
        return (
          <Badge className="d-flex align-items-center justify-content-center" bg={color}>
            {value}
          </Badge>
        );
      },
    },
  ];

  const handleNewPrivateNetworkClick = () => {
    navigate("/dashboard/private-network/create");
  };

  return (
    <div>
      <SimpleTable
        title="Private Networks"
        hasPagination={false}
        data={networks}
        columns={columns}
        hasPerPage={false}
        hasExport={false}
        customActionButton={
          <Button className="text-nowrap" variant="primary" size="sm" onClick={handleNewPrivateNetworkClick}>
            Create New Private Network
          </Button>
        }
      />
    </div>
  );
};

export default NetworksTable;
