import { useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";

const SendSmsModal = ({ isOpen, handleClose, handleSendMessage }) => {
  const [message, setMessage] = useState("");

  const onMessageSend = (e) => {
    handleSendMessage(e, message);
    setMessage(() => "");
  };

  return (
    <Modal
      dialogClassName="w-30"
      centered
      show={isOpen}
      onHide={() => {
        handleClose();
        setMessage("");
      }}
    >
      <Modal.Body>
        <Form onSubmit={onMessageSend}>
          <h3 className="header-title pb-3">SMS</h3>
          <h4 className="header-subtitle pb-2">Send sms message</h4>
          <Form.Group className="mb-3" onClick={(e) => e.stopPropagation()}>
            <InputGroup className="p-0">
              <FormControl value={message} onChange={(e) => setMessage(e.target.value)} />
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="me-2"
              variant="white"
              onClick={() => {
                setMessage("");
                handleClose(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="light-primary" type="submit" onClick={onMessageSend}>
              Send
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SendSmsModal;
