import { coreApi } from "@core/rtk-api";
import { intercomUpdate } from "src/lib/utils/Intercom/intercomUtils";
import { Network } from "src/types/types";

export const networkApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getPricing: builder.query<any, void>({
        query: () => `/pricing`,
      }),
      getAllNetworks: builder.query<any, void>({
        query: () => `/network`,
        transformResponse: (data: any) => {
          // Hack to keep the user attribute on Intercom up to date
          if (data.filter((network) => network.network_state === "Deployed").length === 0) {
            intercomUpdate({ private_network_active: "false" }, { private_network_active: "false" });
          } else {
            intercomUpdate({ private_network_active: "true" }, { private_network_active: "false" });
          }
          return data;
        },
      }),
      getNetwork: builder.query<any, { id: string }>({
        query: ({ id }) => `/network/${id}`,
      }),
      createNetwork: builder.mutation<Network, Network>({
        query: (data) => ({
          url: `/network`,
          method: "POST",
          body: data,
        }),
      }),
      editNetwork: builder.mutation<any, { id: string; name: string }>({
        query: ({ id, name }) => ({
          url: `/network/${id}`,
          method: "PATCH",
          body: { network_name: name },
        }),
      }),
      terminateNetwork: builder.mutation<any, { id: string }>({
        query: ({ id }) => ({
          url: `/network/${id}`,
          method: "DELETE",
        }),
      }),
      getOpenVpn: builder.query<any, { id: string }>({
        query: ({ id }) => `/network/${id}/server`,
      }),
      getIpsec: builder.query<any, { id: string }>({
        query: ({ id }) => `/network/${id}/ipsec`,
      }),
      assignSimToNetwork: builder.mutation<any, { id: string; iccids: string[] }>({
        query: ({ id, iccids }) => ({
          url: `/network/${id}/simcards`,
          method: "POST",
          body: { iccids },
        }),
        invalidatesTags: (_, __, { iccids }): { type: "Simcards" | "Simcard"; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: "Simcard" as const,
              id: iccid,
            })),
            { type: "Simcards" },
          ];
        },
      }),
      removeSimFromNetwork: builder.mutation<any, { iccids: string[] }>({
        query: ({ iccids }) => ({
          url: `/network/simcards`,
          method: "DELETE",
          body: { iccids },
        }),
        invalidatesTags: (_, __, { iccids }): { type: "Simcards" | "Simcard"; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: "Simcard" as const,
              id: iccid,
            })),
            { type: "Simcards" },
          ];
        },
      }),
      configureIpsec: builder.mutation<any, { id: string; data: any }>({
        query: ({ id, data }) => ({
          url: `/network/${id}/ipsec`,
          method: "POST",
          body: data,
        }),
      }),
    };
  },
});

export const {
  useConfigureIpsecMutation,
  useRemoveSimFromNetworkMutation,
  useAssignSimToNetworkMutation,
  useGetIpsecQuery,
  useGetOpenVpnQuery,
  useTerminateNetworkMutation,
  useEditNetworkMutation,
  useCreateNetworkMutation,
  useGetPricingQuery,
  useGetAllNetworksQuery,
  useLazyGetAllNetworksQuery,
  useGetNetworkQuery,
  useLazyGetNetworkQuery,
} = networkApi;
