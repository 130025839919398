import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";
import { nanoid } from '@reduxjs/toolkit'

import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";

import { useGetSmsQuery, useLazyGetSmsQuery, useLazyGetSmsUsageQuery, useSendSmsMutation } from "../simcards-api-slice";

import { LoadingWrapper } from "@components/LoadingWrapper";
import { SMS_INBOX_POLL_TIME } from "@core/constants";

import SimpleTable from "../../components/SimpleTable";
import { SimpleInfoCard } from "../index";
import HeaderCards from "./HeaderCards";

const SmsRow = ({ message, timestamp, sender, direction }) => {
  return (
    <Container className="comment mb-3">
      <Row>
        <Col className={`ms-n2 ${direction === "out" ? "d-flex justify-content-end" : ""}`}>
          <div className={`comment-body ${direction === "out" ? "bg-light-green" : ""}`}>
            <Row>
              <Col>
                <h5 className="comment-title">{sender}</h5>
              </Col>
              <Col className="col-auto">
                <time className="comment-time">{timestamp}</time>
              </Col>
            </Row>
            <p className="comment-text">{message}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const NewSms = ({ iccid, sender }) => {
  //sendSms,
  const [message, setMessage] = useState("");
  const [sendSms, sendSmsResponse] = useSendSmsMutation();
  const { isLoading: isSendSmsProcessing } = sendSmsResponse;
  const [getSms] = useLazyGetSmsQuery();

  const handleSendSms = async () => {
    await sendSms({ iccid, sender, message });
    getSms({ iccid });
    setMessage("");
  };

  return (
    <Row className="border-top w-100 pt-2">
      <Col className="ms-n2">
        <Form className="mt-1">
          <Form.Group>
            <Form.Control
              placeholder="Send an SMS"
              className="form-control form-control-flush"
              as="textarea"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={1}
            />
          </Form.Group>
        </Form>
      </Col>
      <Col xs="auto" className="align-self-center">
        <div className="text-muted">
          <LoadingWrapper isLoading={isSendSmsProcessing} size={1}>
            <i className="fe fe-mail cursor-pointer" onClick={handleSendSms} />
          </LoadingWrapper>
        </div>
      </Col>
    </Row>
  );
};

const Sms = ({
  tags,
  iccid,
  // processing,
  allTags,
  sim_state,
  device_name,
  // updateSimStatus,
  changeSimName,
  msisdn,
  sms_sent,
  sms_received,
  // sms_platform,
  // getSms,
  // sms,
  // smsUsage,
  // getSmsUsage,
  // sendSms,
  // smsUsageProcessing,
}) => {
  const columns = [
    {
      Header: "DIRECTION",
      accessor: "direction",
    },
    {
      Header: "EVENT DATE",
      accessor: "timestamp",
    },
    {
      Header: "MOBILE NETWORK OPERATOR",
      accessor: "carrier",
    },
    {
      Header: "LOCATION",
      accessor: "location",
    },
  ];

  // GetSms
  const getSmsResponse = useGetSmsQuery({ iccid }, { pollingInterval: SMS_INBOX_POLL_TIME });
  const sms = getSmsResponse?.data?.sms || [];

  // GetSmsUsage
  const [getSmsUsage, getSmsUsageResponse] = useLazyGetSmsUsageQuery();
  const smsUsage = getSmsUsageResponse?.data?.smsUsage || [];
  const isSmsUsageProcessing = useIsProcessing([getSmsUsageResponse.isFetching, getSmsUsageResponse.isLoading]);

  useEffect(() => {
    getSmsUsage({ iccid });
  }, [iccid]);

  return (
    <div className="d-flex flex-column overview h-100">
      <HeaderCards
        tags={tags}
        iccid={iccid}
        allTags={allTags}
        sim_state={sim_state}
        device_name={device_name}
        changeSimName={changeSimName}
      />
      <div className="d-flex py-3">
        <div style={{ flexGrow: 11 }}>
          <Card className="w-100 h-100 mb-0 info-card">
            <Card.Body className="py-3">
              <div className="d-flex flex-column h-100">
                <div className="d-flex justify-content-between border-bottom pb-3 pt-2">
                  <h4 className="card-header-title text-capitalize align-items-center d-flex">SMS Inbox</h4>
                </div>
                <div
                  style={{ maxHeight: 260, overflow: "auto" }}
                  className="d-flex flex-column justify-content-between py-3"
                >
                  {sms.map(({ message, sender, timestamp, direction }) => (
                    <SmsRow
                      key={nanoid()}
                      message={message}
                      sender={sender}
                      timestamp={timestamp}
                      direction={direction}
                    />
                  ))}
                </div>
                <div className="d-flex pt-2 flex-grow-1 align-items-end">
                  <NewSms iccid={iccid} sender={"test"} />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex flex-column ps-3" style={{ flexGrow: 5 }}>
          <div className="w-100 h-100 pb-2">
            <SimpleInfoCard label="sms to device this month" title={sms_received} icon="arrow-down-left" />
          </div>
          <div className="w-100 h-100 py-2">
            <SimpleInfoCard label="sms from device this month" title={sms_sent} icon="arrow-up-right" />
          </div>
          <div className="w-100 h-100 pt-2">
            <SimpleInfoCard label="msisdn" title={msisdn} icon="message-circle" />
          </div>
        </div>
      </div>
      <div className="h-100">
        <LoadingWrapper isLoading={isSmsUsageProcessing}>
          <SimpleTable
            title="SMS Usage records"
            data={smsUsage}
            columns={columns}
            processing={isSmsUsageProcessing}
            noDataText="No SMS sent or received yet"
          />
        </LoadingWrapper>
      </div>
    </div>
  );
};
export default Sms;
