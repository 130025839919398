import { ConfirmationModal } from "@components/ConfirmationModal";
import ErrorMessage from "@components/ErrorMessage";
import { Loader } from "@components/Loader";
import { useGetCustomerQuery } from "@modules/dashboard/account/account-api-slice";
import { useEffect, useState } from "react";
import { Button, Card, Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";
import { CustomDropdownToggle } from "../../../components";
import { useGetCountriesQuery } from "../../billing-api-slice";
import { usePaymentMethods } from "../../hooks/usePaymentMethods";
import { PaymentIntent, SetupIntent, SetupIntentSuccess } from "../../index";
import PaymentSuccess from "../PaymentSuccess";
import AccountBalanceCard from "./AccountBalanceCard";
import AutoRechargeCard from "./AutoRechargeCard";
import BillingSettingsCard from "./BillingSettingsCard";
import RechargeByInvoiceCard from "./RechargeByInvoiceCard";

interface ConfirmationModalInitialPropTypes {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  handleConfirm?: () => void;
}

const confirmationModalInitialProps: ConfirmationModalInitialPropTypes = {
  isOpen: false,
};

const Overview = ({ handleEditBillingDetails }: any) => {
  const [confirmationModal, setConfirmationModal] = useState(confirmationModalInitialProps);
  const { isPaymentMethodsFetchSuccess, paymentMethods, handleUpdatePaymentMethod, handleDeletePaymentMethod } =
    usePaymentMethods({
      onClose: setConfirmationModal,
    });

  const countriesResponse = useGetCountriesQuery();

  const [isBillingModalOpen, setIsBillingModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSetupIntentModalOpen, setIsSetupIntentModalOpen] = useState<boolean>(false);
  const [isPaymentSuccessOpen, setIsPaymentSuccessOpen] = useState<boolean>(false);
  const [isSetupIntentSuccessOpen, setIsSetupIntentSuccessOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams: URLSearchParams = new URLSearchParams(location.search);
  const query: URLSearchParams | null = searchParams.size > 0 ? searchParams : null;

  useEffect(() => {
    if (query?.get("redirect_status") === "succeeded") {
      if (query?.get("setup_intent")) {
        setIsSetupIntentSuccessOpen(true);
      } else {
        setIsPaymentSuccessOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [query]);

  const customerResponse = useGetCustomerQuery();
  const { data: details } = customerResponse; // details is the legacy name

  const isProcessing = useIsProcessing([customerResponse.isFetching, countriesResponse.isFetching]);

  const handleClosePaymentSuccessModal = () => {
    setIsPaymentSuccessOpen(false);
    setIsSetupIntentSuccessOpen(false);
    navigate({ search: "" }, { replace: true });
  };

  if (isProcessing) return <Loader />;

  return (
    <div className="d-flex flex-column ">
      <div className="d-flex justify-content-between w-100">
        <AccountBalanceCard onConfirm={setIsBillingModalOpen} />
        <BillingSettingsCard handleEditBillingDetails={handleEditBillingDetails} />
      </div>
      <div className="d-flex justify-content-between w-100">
        <AutoRechargeCard />
        <RechargeByInvoiceCard />
      </div>
      <div className="d-flex w-100">
        <Card className="w-100">
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="card-header-title text-capitalize">Payment methods</h4>
              <div>
                <Button onClick={() => setIsSetupIntentModalOpen(true)} size="sm">
                  Add payment method
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {isPaymentMethodsFetchSuccess && (
              <>
                {paymentMethods.length === 0 && <div>You don't have any payment methods</div>}
                {paymentMethods.map(({ card, card_id, preferred, expiration_date, logo }) => (
                  <div key={card_id} className="d-flex justify-content-between">
                    <div className="d-flex">
                      <img src={logo} height={64} width={64} alt="visa" />
                      <div className="d-flex flex-column justify-content-center">
                        <strong>{card}</strong>
                        <div className="text-muted">{expiration_date}</div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      {preferred && (
                        <div className="me-3">
                          <Button disabled size="sm" variant="light">
                            Default
                          </Button>
                        </div>
                      )}
                      <Dropdown className="ellipsis-menu-height">
                        <Dropdown.Toggle as={CustomDropdownToggle}>
                          <div className="dropdown-ellipses dropdown-toggle" role="button">
                            <div className="ellipsis">
                              <i className="fe fe-more-vertical" />
                            </div>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            className="py-1"
                            onClick={() => {
                              setConfirmationModal({
                                isOpen: true,
                                subtitle: `Are you sure you want to set ${card} as default?`,
                                handleConfirm: () => {
                                  handleUpdatePaymentMethod(card_id);
                                },
                              });
                            }}
                          >
                            <span>Set as default</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="py-1"
                            onClick={() => {
                              setConfirmationModal({
                                isOpen: true,
                                title: "Delete Payment Method",
                                subtitle: `Are you sure you want to delete your ${card}?`,
                                handleConfirm: () => handleDeletePaymentMethod(card_id),
                              });
                            }}
                          >
                            <span>Delete</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Card.Body>
        </Card>
      </div>
      <ErrorMessage className="my-0" message={errorMessage} dismissible onClose={() => setErrorMessage("")} />
      <PaymentIntent
        isOpen={isBillingModalOpen}
        closeModal={() => setIsBillingModalOpen(false)}
        initialVat={1 + details?.tax?.tax || 1}
      />
      <PaymentSuccess isOpen={isPaymentSuccessOpen} handleClose={handleClosePaymentSuccessModal} />
      <SetupIntentSuccess isOpen={isSetupIntentSuccessOpen} handleClose={handleClosePaymentSuccessModal} />
      <SetupIntent isOpen={isSetupIntentModalOpen} handleCloseModal={() => setIsSetupIntentModalOpen(false)} />
      <ConfirmationModal
        handleCancel={() => setConfirmationModal(confirmationModalInitialProps)}
        {...confirmationModal}
      />
    </div>
  );
};

export default Overview;
