import { Button, Card } from 'react-bootstrap';

const Help = ({ data }) => {
  return (
    <Card>
      <Card.Body className='p-3'>
        <div className='p-3 d-flex justify-content-between align-items-center'>
          <div className='d-flex flex-column'>
            <h4 className='mb-3'>Help</h4>
            {data.map(({ icon, title, url }, index) => (
              <div
                key={index}
                className='d-flex mb-2 link-primary cursor-pointer'
              >
                <Button
                  className='p-0'
                  variant='link'
                  href={url}
                  target='_blank'
                >
                  <i className={`fe fe-${icon} text-dark`} />
                  &nbsp; {title}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Help;
