import { Button, Card, Col, Row } from "react-bootstrap";

const AddOn = ({ button_caption, button_active, button_highlight, image, terms, price, name, handleClick }) => {
  return (
    <Card
      style={{
        height: "calc(100% - 1.5rem)",
      }}
    >
      <img className="card-img-top" src={image} alt="..." />
      <Card.Body>
        <Row className="align-items-stretch gap-2 h-100 ">
          <Col className="d-flex justify-content-between flex-column">
            <div className="d-flex flex-column">
              <h4 className="mb-2">{name}</h4>
              <div className="d-flex gap-2">
                <span className="badge bg-success-soft text-wrap">{terms}</span>
                <small className="text-muted text-nowrap">{price}</small>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Button
                size="sm"
                disabled={!button_active}
                variant={button_highlight ? "white" : "light"}
                onClick={handleClick}
              >
                {button_caption}
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AddOn;
