import { useEffect, useState } from "react";
import { Button, Dropdown, Form, FormControl, InputGroup, Modal } from "react-bootstrap";

import { useCreateTagMutation, useDeleteTagMutation, useLazyGetTagsQuery } from "../simcards-api-slice";

import { SimcardOverview } from "../../../../types/types";
import { CustomDropdownToggle } from "../../components";
import { Checkbox } from "../../components";
import ConfirmModal from "../../components/ConfirmModal";

const TAG_ACTION_TYPE = {
  ADD: "add",
  REMOVE: "remove",
};

interface TagDropdownProps {
  simcards?: SimcardOverview[];
  tags?: [];
  page?: { [key: string]: any };
  selectedRowIds?: number[];
  singleSim?: boolean;
  simTags?: string[];
  iccid?: string;
  handleApply?: (tagsToAdd: any, tagsToRemove: any, iccids: any) => void;
  border?: boolean;
  customDropdownButton?: any;
}

const TagDropdown = ({
  simcards = [],
  tags: allTags = [],
  page,
  selectedRowIds = [],
  singleSim,
  simTags = [],
  iccid,
  handleApply,
  border = false,
  customDropdownButton,
}: TagDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [tagToDelete, setTagToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkMarks, setCheckMarks] = useState([]);
  const [search, setSearch] = useState("");
  const [newTag, setNewTag] = useState("");

  const [createTag] = useCreateTagMutation();
  const [getTags] = useLazyGetTagsQuery();
  const [deleteTag] = useDeleteTagMutation();

  const getCheckMarks = () => {
    const marks = [];

    allTags.forEach(({ tag: allTag }) => {
      let checked = false;
      let indeterminate = false;

      if (!singleSim) {
        if (simcards.every((simcard) => simcard.tags?.includes(allTag))) {
          checked = true;
        } else if (simcards.some((simcard) => simcard.tags?.includes(allTag))) {
          indeterminate = true;
        }
        marks.push({
          checked,
          indeterminate,
          isInitialIndeterminate: indeterminate,
          tag: allTag,
          action: "",
        });
      }

      if (singleSim) {
        marks.push({
          indeterminate: false,
          checked: simTags.includes(allTag),
          tag: allTag,
          action: "",
        });
      }
    });

    setCheckMarks(marks);
  };

  const applyTags = async () => {
    setSearch("");
    setIsOpen(false);

    const iccids = [];
    if (singleSim) {
      iccids.push(iccid);
    } else {
      Object.keys(selectedRowIds).forEach((key) => {
        iccids.push(page[key].original.iccid);
      });
    }

    const tagsToAdd = [];
    const tagsToRemove = [];
    checkMarks.forEach(({ action, tag }) => {
      if (action === TAG_ACTION_TYPE.ADD) {
        tagsToAdd.push(tag);
      } else if (action === TAG_ACTION_TYPE.REMOVE) {
        tagsToRemove.push(tag);
      }
    });

    handleApply(tagsToAdd, tagsToRemove, iccids);
  };

  useEffect(() => {
    getCheckMarks();
    // eslint-disable-next-line
  }, [allTags]);
  const handleClick = (_, i) => {
    const newCheckMarks = checkMarks.map((check, index) => {
      let action;
      if (check.action === TAG_ACTION_TYPE.ADD || check.action === TAG_ACTION_TYPE.REMOVE) {
        if (check.isInitialIndeterminate) {
          action = check.action === TAG_ACTION_TYPE.ADD ? TAG_ACTION_TYPE.REMOVE : TAG_ACTION_TYPE.ADD;
        } else {
          action = "";
        }
      } else if (check.checked) {
        action = TAG_ACTION_TYPE.REMOVE;
      } else {
        action = TAG_ACTION_TYPE.ADD;
      }

      return i === index
        ? {
            ...check,
            checked: !check.checked,
            indeterminate: false,
            action,
          }
        : check;
    });
    setCheckMarks(newCheckMarks);
  };

  const handleDropdownClose = (isOpen) => {
    // Reset tags
    if (!isOpen) {
      setIsOpen(false);
      getCheckMarks();
    }
  };

  const handleModalClose = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  const handleCreateTag = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await createTag({ tag: newTag });
    await getTags();
    setNewTag("");
    setIsModalOpen(false);
  };

  const handleDeleteTag = async () => {
    await deleteTag({ tag: tagToDelete });
    await getTags();
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <Dropdown show={isOpen} onToggle={handleDropdownClose} autoClose="outside">
        <Dropdown.Toggle as={CustomDropdownToggle}>
          {customDropdownButton && customDropdownButton(() => setIsOpen(!isOpen))}
          {!customDropdownButton && singleSim && (
            <div className="dropdown-ellipses dropdown-toggle" role="button" onClick={() => setIsOpen(!isOpen)}>
              <div className="ellipsis">
                <i className="fe fe-more-vertical" />
              </div>
            </div>
          )}
          {!customDropdownButton && !singleSim && (
            <Button variant="light" className="me-2 p-2" onClick={() => setIsOpen(!isOpen)}>
              <div className="d-flex align-items-center">Assign tags</div>
            </Button>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: 180 }}>
          <Dropdown.Item className="px-3">
            <InputGroup className="p-0">
              <FormControl placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>
          </Dropdown.Item>
          <div className="px-3 py-2">
            {allTags.map(
              ({ tag }: { tag: string }, i) =>
                (tag.includes(search) || search === "") && (
                  <Checkbox
                    key={i}
                    label={tag}
                    checked={checkMarks[i]?.checked}
                    indeterminate={checkMarks[i]?.indeterminate}
                    onClick={(label) => handleClick(label, i)}
                    hasAction
                    actionIcon={<i className="fe fe-trash-2 text-muted" />}
                    handleAction={(e) => {
                      e.stopPropagation();
                      setIsConfirmModalOpen(true);
                      setTagToDelete(tag); // FIXME: Doesn't work
                    }}
                  />
                ),
            )}
          </div>
          <Dropdown.Divider className="mx-3" />
          <Dropdown.Item className="py-1" onClick={() => setIsModalOpen(true)}>
            <span>Create new</span>
          </Dropdown.Item>
          <Dropdown.Item className="py-1" onClick={applyTags}>
            <span>Apply</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal dialogClassName="w-30" centered show={isModalOpen}>
        {/* originally in modal. couldnt figure out why but was too scared to delete it - Alessandro:   onHide={() => {}} */}
        <Modal.Body className={border ? "border rounded" : ""}>
          <Form onSubmit={handleCreateTag}>
            <h3 className="header-title pb-3">New tag</h3>
            <h4 className="header-subtitle pb-2">Enter new tag name</h4>
            <Form.Group className="mb-3" controlId="formBasicEmail" onClick={(e) => e.stopPropagation()}>
              <InputGroup className="p-0">
                <FormControl value={newTag} onChange={(e) => setNewTag(e.target.value)} />
              </InputGroup>
            </Form.Group>
            <div className="d-flex justify-content-end mt-4">
              <Button className="me-2" variant="white" onClick={handleModalClose}>
                Cancel
              </Button>
              <Button variant="light-primary" type="submit" onClick={handleCreateTag}>
                Create
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handleConfirm={handleDeleteTag}
        handleCancel={() => setIsConfirmModalOpen(false)}
        subtitle={`Are you sure you want to delete ${tagToDelete}?`}
      />
    </>
  );
};

export default TagDropdown;
