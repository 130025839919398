import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";

import { useState } from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";

import { useCreateApiKeyMutation, useDeleteApiKeyMutation, useGetApiKeysQuery } from "../integrations-api-slice";

import image from "@assets/img/simbase-API.png";
import ErrorMessage from "@components/ErrorMessage";
import { Loader } from "@components/Loader";

import SimpleTable from "../../components/SimpleTable";
import { NewApiKey } from "../index";

const Api = () => {
  // DeleteApiKey
  const [deleteApiKey] = useDeleteApiKeyMutation();

  // GetApiKeys
  const getApiKeysResponse = useGetApiKeysQuery();
  const apiKeys = getApiKeysResponse.data?.keys || [];
  const { refetch: refetchApiKeys } = getApiKeysResponse;

  const isProcessing = useIsProcessing([getApiKeysResponse.isFetching, getApiKeysResponse.isLoading]);

  // CreateApiKey
  const [createApiKey] = useCreateApiKeyMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [createApiKeyProcessing, setCreateApiKeyProcessing] = useState(false);
  const [visibleApiKeys, setVisibleApiKeys] = useState([]);

  const columns = [
    {
      Header: "NAME",
      accessor: "key_name",
    },
    {
      Header: "API KEY",
      accessor: "apikey",
      Cell: ({ value, row: { id } }) => (
        <div className="d-flex justify-content-between">
          <p className="text-muted m-0">{visibleApiKeys.includes(id) ? value : "●".repeat(value.length)}</p>
          <i
            onClick={() => {
              if (visibleApiKeys.includes(id)) {
                setVisibleApiKeys(visibleApiKeys.filter((apiKey) => apiKey !== id));
              } else {
                setVisibleApiKeys([...visibleApiKeys, id]);
              }
            }}
            className={`ms-2 cursor-pointer fe fe-eye${!visibleApiKeys.includes(id) ? "-off" : ""} text-muted`}
          />
        </div>
      ),
    },
    {
      Header: "KEY TYPE",
      accessor: "read_only",
      Cell: ({ value }) => (
        <Badge className="d-flex align-items-center justify-content-center" bg={value ? "warning-soft" : "success"}>
          {value ? "Read only" : "Read/Write"}
        </Badge>
      ),
    },
    {
      Header: "LAST API CALL",
      accessor: "last_used",
    },
    {
      Header: "ALLOWED IPS",
      accessor: "allowed_ips",
    },
  ];

  const handleCreateApiKey = async (params) => {
    setCreateApiKeyProcessing(true);
    const response = await createApiKey(params);
    await refetchApiKeys();
    if ("errorMessage" in response) {
      setErrorMessage(response.errorMessage as string);
    }
    setCreateApiKeyProcessing(false);
    setIsModalOpen(false);
  };

  const handleDeleteApiKey = async (apiKey) => {
    await deleteApiKey({ apiKey });
    await refetchApiKeys();
  };

  if (isProcessing) {
    return <Loader />;
  }

  return (
    <div>
      {apiKeys.length && !isProcessing ? (
        <SimpleTable
          title="API keys"
          hasPagination={false}
          initialPageSize={1000}
          data={apiKeys}
          columns={columns}
          hasPerPage={false}
          hasExport={false}
          customActionButton={
            <Button className="text-nowrap" variant="primary" size="sm" onClick={() => setIsModalOpen(true)}>
              Create new API key
            </Button>
          }
          hasActions
          actionItems={[
            {
              title: "Delete",
              handleClick: (row) => handleDeleteApiKey(row.values.apikey),
            },
          ]}
        />
      ) : (
        <Card>
          <Card.Body className="py-0">
            <Row className="align-items-center">
              <Col sm={12} className="col-lg-4 order-lg-2">
                <div className="text-center">
                  <img
                    alt="..."
                    src={image}
                    className="img-fluid"
                    style={{
                      maxWidth: 272,
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} className="col-lg-8 px-4 py-3 order-lg-1">
                <h2>You have no API Keys.</h2>
                <p className="text-muted">
                  API Keys are revocable credentials for the Simbase API. You can use API Keys to authenticate to the
                  REST API using basic auth.
                </p>
                <Button className="text-nowrap" variant="primary" onClick={() => setIsModalOpen(true)}>
                  Create new API key
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      <NewApiKey
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleCreate={handleCreateApiKey}
        processing={createApiKeyProcessing}
      />
      <ErrorMessage message={errorMessage} onClose={() => setErrorMessage("")} />
    </div>
  );
};

export default Api;
