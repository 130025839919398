import { useState } from "react";
import { Nav, NavItem, NavLink, Spinner } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";

import { OverviewHeader } from "../../components";
import OpenVpn from "./OpenVpn";
import Overview from "./Overview";

const tabs = [
  { key: "overview", label: "Overview" },
  { key: "sims", label: "SIMs" },
  { key: "openVpn", label: "OpenVPN" },
  { key: "ipsec", label: "IPsec" },
];

const NetworkDetails = ({ processing }: { processing?: boolean }) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <div className="d-flex flex-column h-100">
      <OverviewHeader title="Private Network" />
      <div className="px-3">
        <Nav variant="tabs" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
          {tabs.map(({ key, label }) => (
            <NavItem key={key}>
              <NavLink eventKey={key}>{label}</NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <div className="pt-4 px-2 h-100">
        {processing ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <>
            {activeTab === "overview" && <Overview id={id} onSeeDetailsClick={() => setActiveTab(tabs[2].key)} />}
            {activeTab === "sims" && (
              <Navigate
                replace
                to={{
                  pathname: "/dashboard/sim-cards",
                }}
                state={{
                  showNetworkFilter: true,
                  networkId: id,
                }}
              />
            )}
            {activeTab === "openVpn" && <OpenVpn id={id} />}
            {activeTab === "ipsec" && (
              <Navigate
                replace
                to={{
                  pathname: "ipsec-configure",
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NetworkDetails;
