import { coreApi } from "@core/rtk-api";

export const homeApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getHome: builder.query<any, void>({ query: () => "/home" }),
    };
  },
});

export const { useGetHomeQuery } = homeApi;
