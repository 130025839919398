import { ListPageOptions } from '../index';

const Pagination = ({ pageIndex, setPageIndex, pageCount }) => {
  return (
    <div className='card-footer d-flex justify-content-between'>
      <ul
        className='list-pagination-prev pagination pagination-tabs card-pagination'
        onClick={() => pageIndex - 1 >= 0 && setPageIndex(pageIndex - 1)}
      >
        <li className='page-item'>
          <button className='page-link ps-0 pe-4 border-end d-flex'>
            <i className='fe fe-arrow-left me-1' /> Prev
          </button>
        </li>
      </ul>
      <ListPageOptions
        currentPageIndex={pageIndex}
        pageCount={pageCount}
        gotoPage={setPageIndex}
      />
      <ul
        className='list-pagination-next pagination pagination-tabs card-pagination'
        onClick={() => pageIndex + 1 < pageCount && setPageIndex(pageIndex + 1)}
      >
        <li className='page-item'>
          <button className='page-link ps-4 pe-0 border-start d-flex'>
            Next <i className='fe fe-arrow-right ms-1' />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
