import { Formik } from "formik";
import { Button, Form, FormControl, InputGroup, Modal, ModalBody } from "react-bootstrap";
import Flag from "react-country-flag";
import { SelectBox } from "../../components";

const SetCurrencyModal = ({ isOpen, handleClose, currencies, updateBillingBalance, editCustomer }) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    await Promise.all([
      await editCustomer({ company_name: values.organizationName }),
      await updateBillingBalance({ currency: values.currency.iso }),
    ]);
    setSubmitting(false);
    handleClose();
  };

  const formatOptionLabel = ({ label, flag }) => {
    return (
      <div className="d-flex px-2 py-2">
        <div className="d-flex justify-content-center align-items-center">
          <Flag
            countryCode={flag}
            style={{
              fontSize: "1.75rem",
            }}
          />
        </div>
        <span className="ps-2 d-flex justify-content-center align-items-center">{label}</span>
      </div>
    );
  };

  return (
    <Modal show={isOpen} centered>
      {" "}
      {/* onHide={() => {}} */}
      <ModalBody>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h2 className="display-5">Finish setting up your account.</h2>
          <p className="text-muted">Tell us your organization's name, preferred currency and get started.</p>
        </div>
        <div>
          <Formik
            initialValues={{
              organizationName: "",
              currency: {
                iso: "EUR",
                name: "Euro",
                flag: "eu",
              },
            }}
            onSubmit={handleSubmit}
            validate={({ organizationName }) => {
              type IError = {
                organizationName?: string;
              };
              const errors: IError = {};
              if (!organizationName) {
                errors.organizationName = "Required";
              }
              return errors;
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Organization name</Form.Label>
                  <InputGroup className="p-0">
                    <FormControl
                      value={values.organizationName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="string"
                      name="organizationName"
                    />
                  </InputGroup>
                  <small className="form-text">
                    Your organization name can be different from your company name and can be changed at anytime.
                  </small>
                  <span className="text-danger">
                    <>{errors.organizationName || touched.organizationName}</>
                  </span>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Billing currency</Form.Label>
                  <InputGroup className="p-0">
                    <SelectBox
                      formatOptionLabel={formatOptionLabel}
                      onChange={({ flag, label, value }) =>
                        setFieldValue("currency", {
                          flag,
                          iso: value,
                          name: label,
                        })
                      }
                      options={
                        currencies &&
                        currencies.map(({ name, iso, flag }) => ({
                          label: name,
                          value: iso,
                          flag: flag,
                        }))
                      }
                      name="currency"
                      value={{
                        value: values.currency.iso,
                        label: values.currency.name,
                        ...values.currency,
                      }}
                    />
                    <small className="form-text">
                      Please choose carefully. Billing currency cannot be changed once the account has been set up.
                    </small>
                  </InputGroup>
                </Form.Group>
                <Button className="mt-3 lift" variant="primary" type="submit" disabled={isSubmitting}>
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SetCurrencyModal;
