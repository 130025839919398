import { Button, Col, Container, Modal, Row } from "react-bootstrap";

import { SUBSCRIPTION_PLAN_TYPE } from "../../../../core/constants";
import Plan from "./Plan";

// buttom_caption is backend typo, leave it as is
const FooterBlock = ({ buttom_caption, description, title, handleClick }) => {
  return (
    <div className="col-12 col-md-6">
      <div className="card card-inactive">
        <div className="card-body">
          <h3 className="text-center">{title}</h3>

          <p className="text-muted text-center">{description}</p>

          <div className="text-center">
            <Button onClick={handleClick} variant="outline-secondary">
              {buttom_caption}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionDetails = ({ subscriptions, isOpen = false, handleClose, type, handleClick }) => {
  const {
    title = "",
    footer = [],
    products = [],
    headline = "",
  } = subscriptions[type === SUBSCRIPTION_PLAN_TYPE.CONNECTIVITY ? "connectivity_plans" : "support_plans"] || {};

  return (
    <Modal fullscreen show={isOpen} animation={true} dialogClassName="h-auto">
      <div className="d-flex justify-content-end close-button position-absolute end-0">
        <span onClick={handleClose} className="fe fe-x cursor-pointer text-white" />
      </div>
      <div className="pt-7 pb-8 bg-dark bg-ellipses">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={6}>
              <h1 className="display-3 text-center text-white">{title}</h1>
              <p className="lead fw-400 text-center text-gray-600">{headline}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid className="px-5">
        <Row className="mt-n7 d-flex justify-content-center">
          {products.map((plan) => (
            <Col sm={12} lg={4} key={plan.id}>
              <Plan
                title={plan.name}
                currency={subscriptions?.currency_symbol}
                handleClick={() => handleClick(plan.id, plan.popup)}
                showLearnMore
                {...plan}
              />
            </Col>
          ))}
        </Row>
        <Row>
          {footer.map((block, index) => (
            <FooterBlock key={index} handleClick={() => handleClick(null, block.popup)} {...block} />
          ))}
        </Row>
      </Container>
    </Modal>
  );
};

export default SubscriptionDetails;
