import dayjs from "dayjs";
import { getCurrentYearMonth } from "src/lib/utils/getCurrentYearMonth";
import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";

import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import { useLazyGetCostsQuery } from "../billing-api-slice";
import { useGetDashboardParametersQuery } from "@modules/dashboard/simCard/simcards-api-slice";

import { Loader } from "@components/Loader";

import { SelectBox } from "../../components";
import SimpleTable from "../../components/SimpleTable";
import { CostDetails } from "../index";

const CostReports = () => {
  // GetDashboardParams
  const getDashboardParamsResponse = useGetDashboardParametersQuery();
  const { months } = getDashboardParamsResponse.data ?? [];

  // GetCosts
  const [getCosts, costsResponse] = useLazyGetCostsQuery();
  const costs = costsResponse?.data?.sims ?? [];

  // LoadingState
  const isProcessing = useIsProcessing([getDashboardParamsResponse.isFetching, getDashboardParamsResponse.isLoading]);

  const currentYearMonth = getCurrentYearMonth();
  const [selectedMonth, setSelectedMonth] = useState<string>(currentYearMonth);

  const [selectedIccid, setSelectedIccid] = useState(null);

  const columns = [
    {
      Header: "ICCID",
      accessor: "iccid",
      Cell: ({ value }) => (
        <span
          className="cursor-pointer btn-link"
          onClick={() => {
            setSelectedIccid(value);
          }}
        >
          {value}
        </span>
      ),
    },
    { Header: "TOTAL COST", accessor: "cost" },
    { Header: "SIM SUBSCRIPTION", accessor: "cost_month" },
    { Header: "DATA", accessor: "cost_data" },
    { Header: "SMS", accessor: "cost_sms" },
    { Header: "NETWORKING", accessor: "cost_netw" },
  ];

  useEffect(() => {
    getCosts(selectedMonth);
  }, [getCosts, selectedMonth]);

  if (selectedIccid) {
    return <CostDetails month={selectedMonth} iccid={selectedIccid} handleGoBack={() => setSelectedIccid(null)} />;
  }

  if (isProcessing) return <Loader />;

  return (
    <div>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h4 className="card-header-title text-capitalize">SIM report</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <small className="form-text text-muted">
            Costs listed here are individual SIM related usage costs and may exclude taxes and certain service costs.
          </small>
          <SelectBox
            defaultValue={{
              label: "This month",
              value: currentYearMonth,
            }}
            onChange={({ value }) => setSelectedMonth(value)}
            options={months.map((value) => ({
              label: value === currentYearMonth ? "This month" : dayjs(value).format("MMMM YYYY"),
              value,
            }))}
          />
        </Card.Body>
      </Card>
      <div>
        <SimpleTable hasSearch columns={columns} data={costs} processing={costsResponse.isFetching} />
      </div>
    </div>
  );
};

export default CostReports;
