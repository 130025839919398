import { Button, Card, Col, Row } from "react-bootstrap";

const InfoRow = ({ color, icon, title }) => {
  return (
    <li className="list-group-item d-flex align-items-center justify-content-between px-0">
      <small>{title}</small>
      <i className={`fe text-${color} ${icon}`}></i>
    </li>
  );
};

const Plan = ({
  button_active,
  button_caption,
  button_highlight,
  usps,
  price,
  currency,
  title,
  handleClick,
  isButtonAlwaysActive = false,
  buttonTitle,
  read_more = "",
  name,
  showLearnMore = false,
}) => {
  let amount = price;
  let billingTime = null;
  if (price.includes("/")) {
    // This means that price string is made of amount and billing time
    [amount, billingTime] = price.split("/");
  }

  const parseAmount = (price) => {
    if (!billingTime) {
      return price;
    }
    return price.replace(/[^\d.]*/g, "");
  };

  return (
    <Card className="w-100">
      <Card.Body>
        <h6 className="text-uppercase text-center text-muted my-4">{title}</h6>
        <Row className="g-0 align-items-center justify-content-center">
          {billingTime && (
            <Col sm="auto">
              <div className="h2 mb-0">{currency}</div>
            </Col>
          )}
          <Col sm="auto">
            <div className="display-2 mb-0">{parseAmount(amount)}</div>
          </Col>
        </Row>
        <div className="h6 text-uppercase text-center text-muted mb-5 subscription-plan-subtext">
          {!!billingTime && `/ ${billingTime}`}
        </div>
        <div className="mb-3">
          <ul className="list-group list-group-flush">
            {usps.map((usp) => (
              <InfoRow key={usp.title} {...usp} />
            ))}
          </ul>
        </div>
        <Button
          onClick={handleClick}
          disabled={!button_active && !isButtonAlwaysActive}
          className="w-100"
          variant={button_highlight ? "primary" : "light"}
        >
          {buttonTitle || button_caption}
        </Button>
        {showLearnMore && (
          <div className="d-flex justify-content-center">
            <a href={read_more} target="_blank" rel="noreferrer">
              <small className="fs-6 text-muted text-uppercase fw-500">{`learn more about the ${name} plan`}</small>
            </a>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
export default Plan;
