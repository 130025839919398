import { OtherPlan, Plan } from "../index";

const Plans = ({ plans, handleComplete, currentPlan = "" }) => {
  return (
    <>
      {plans.length > 1 && (
        <div className="px-3 w-100">
          <Plan {...plans[0]} handleComplete={handleComplete} isSelected={plans[0].plan_id === currentPlan} />
        </div>
      )}
      {plans.length > 2 && (
        <div className="px-3 w-100">
          <Plan {...plans[1]} handleComplete={handleComplete} isSelected={plans[1].plan_id === currentPlan} />
        </div>
      )}
      {plans.length > 3 && (
        <div className="px-3 w-100">
          <OtherPlan plans={plans.slice(2)} handleComplete={handleComplete} />
        </div>
      )}
    </>
  );
};

export default Plans;
