import { STATUSPAGE_URL } from "@core/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Status } from "src/types/types";

export const statusApi = createApi({
  reducerPath: "statuspage-rtk",
  baseQuery: fetchBaseQuery({
    baseUrl: STATUSPAGE_URL,
  }),
  endpoints: (builder) => {
    return {
      getStatus: builder.query<any, void>({
        query: () => "/components.json",
        transformResponse: (data: {
          components: { group: boolean; name: string }[];
          page: { url: string };
        }): Status => {
          const response: Status = {
            components: [
              ...data.components.filter((component) => component.group === true),
              ...data.components.filter((component) => component.name === "API"),
            ],
            url: data.page.url,
          };
          return response;
        },
      }),
    };
  },
});

export const { useGetStatusQuery } = statusApi;
