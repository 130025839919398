import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "src/lib/utils/prepareHeaders";
import { BASE_URL } from "./constants";

export const coreApi = createApi({
  reducerPath: "coreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: prepareHeaders,
  }),
  keepUnusedDataFor: 30,
  tagTypes: ["Tags", "Simcard", "Simcards", "BillingBalance"],
  endpoints: () => ({}),
});
