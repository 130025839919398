export const Radio = ({
  label,
  labelClassname = "",
  checked = false,
  onClick = () => {},
}) => {
  return (
    <div className="form-check" onClick={onClick}>
      <input
        className="form-check-input"
        type="radio"
        checked={checked}
        onChange={onClick}
      />
      <label className={`form-check-label ${labelClassname}`}>{label}</label>
    </div>
  );
};

export default Radio;
