import { Button, Card, Col, Row } from 'react-bootstrap';

const Item = ({ title, body, url, urlText }) => {
  return (
    <div className='border rounded p-3 h-100'>
      <div className='d-flex flex-column h-100 justify-content-between'>
        <p className='mb-1 fw-700'>{title}</p>
        <p className='small text-gray-700 mb-0'>{body}</p>
        <div className='mt-3'>
          <Button className='ps-0' variant='link' href={url} target='_blank'>
            {urlText}
            <i className='fe fe-arrow-right ms-1' />
          </Button>
        </div>
      </div>
    </div>
  );
};

const GetStarted = ({ items = [] }) => {
  return (
    <Card>
      <Card.Body className='p-3'>
        <div className='display-5 mb-3 text-capitalize'>
          Get started with Simbase
        </div>
        <Row>
          {items.map(({ title, body, url, url_text }, index) => (
            <Col
              className={`${
                index < items.length - 2 ? 'mb-3' : 'mb-0'
              } last-child-mb-0`}
              sm={6}
              key={index}
            >
              <Item title={title} body={body} url={url} urlText={url_text} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GetStarted;
