import { Auth } from "aws-amplify";
import { Formik } from "formik";

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { FormField } from "../../index";

const ConfirmAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const email = location?.state?.email || "";

  const resendConfirmationCode = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      await Auth.resendSignUp(email);
      toast.success("Code sent!");
      setResendTimer(60);
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };

  const handleCodeSubmit = async ({ code, email }, { setSubmitting }) => {
    setErrorMessage("");
    try {
      await Auth.confirmSignUp(email, code);
      setSubmitting(false);
      navigate("/auth/signin");
    } catch (error) {
      setSubmitting(false);
      setErrorMessage(error?.message);
    }
  };

  useEffect(() => {
    const timer = resendTimer > 0 && setInterval(() => setResendTimer(resendTimer - 1), 1000);
    return () => clearInterval(timer);
  }, [resendTimer]);

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col sm={12} md={5} lg={6} xl={4} className="px-lg-6 my-5 align-self-center">
          <h1 className="display-4 text-center mb-3">Create a free account</h1>
          <p className="text-muted text-center mb-5">Start connecting your devices.</p>
          <Formik
            initialValues={{ code: "", email }}
            onSubmit={handleCodeSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.code) {
                errors.code = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                {/* TODO Handle message when no email*/}
                <div className="pb-4">
                  {`We have sent a code by email to ${email}. Enter it
                        below to confirm your account.`}
                </div>
                {!email && (
                  <FormField
                    value={values.email}
                    handleChange={handleChange}
                    touched={touched.email}
                    handleBlur={handleBlur}
                    error={errors.email}
                    placeholder="name@address.com"
                    name="email"
                    type="email"
                    label="Email Address"
                  />
                )}
                <FormField
                  value={values.code}
                  handleChange={handleChange}
                  touched={touched.code}
                  handleBlur={handleBlur}
                  error={errors.code}
                  placeholder="Code"
                  name="code"
                  label="Verification Code"
                />
                <Button type="submit" variant="primary" className="w-100 mb-3 mt-3 lift" disabled={isSubmitting}>
                  Confirm account
                </Button>

                {resendTimer ? (
                  <div className="text-center">
                    <small className="text-muted text-center">
                      <span>
                        You need to wait {resendTimer} seconds before resending code
                        <br />
                      </span>
                    </small>
                  </div>
                ) : (
                  <div className="text-center">
                    <small className="text-muted text-center">
                      <span className="cursor-pointer btn-link" onClick={resendConfirmationCode}>
                        Resend it
                      </span>
                      .
                    </small>
                  </div>
                )}
              </Form>
            )}
          </Formik>
          <span className="text-danger">{errorMessage}</span>
        </Col>
        <Col sm={12} md={7} lg={6} xl={8} className="d-none d-lg-block px-0">
          <div className="bg-cover h-100 min-vh-100 bg-sign-in" />
        </Col>
      </Row>
      <ToastContainer autoClose={4000} />
    </Container>
  );
};

export default ConfirmAccount;
