import { useEffect, useState } from "react";
import { ErrorMessage, Loader } from "../../../../components";
import SimpleTable from "../../components/SimpleTable";
import { useLazyGetPaymentsQuery } from "../billing-api-slice";

const columns = [
  {
    Header: "INVOICE ID",
    accessor: "invoice_id",
    isVisible: false,
  },
  {
    Header: "EVENT DATE",
    accessor: "timestamp",
  },
  {
    Header: "AMOUNT",
    accessor: "amount",
  },
  {
    Header: "PAYMENT METHOD",
    accessor: "type",
  },
];

const Payments = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const [getPayments, getPaymentsResponse] = useLazyGetPaymentsQuery();
  // const { payments } = getPaymentsResponse.data ?? [];
  const [payments, setPayments] = useState(null);
  // if not null can unblock UI
  // if null = still loading
  // if empty array = no payment lines

  useEffect(() => {
    getPayments({ count: 20 });
  }, []);

  useEffect(() => {
    if (getPaymentsResponse.isSuccess) {
      setPayments((state) => [...(state ?? []), ...(getPaymentsResponse.data?.payments ?? [])]);
      const cursor = getPaymentsResponse.data?.cursor ?? null;
      if (cursor) getPayments({ count: 20, cursor });
    }
    if (getPaymentsResponse.isError) setErrorMessage(() => "Error fetching payments");
  }, [getPaymentsResponse.isSuccess]); // eslint-disable-line
 
  if (payments === null) {
    return <Loader />;
  }

  return (
    <div>
      <SimpleTable title="Payments" data={payments} columns={columns} hasPerPage={false} hasExport={false} />
      <ErrorMessage message={errorMessage} onClose={() => setErrorMessage("")} />
    </div>
  );
};

export default Payments;
