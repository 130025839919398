import "./init";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "@assets/scss/theme.scss";
import { store as rtkstore } from "@core/rtk-store.ts";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";

if (import.meta.env.MODE === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-WWNKBT8",
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={rtkstore}>
      <App />
    </Provider>
  </React.StrictMode>,
);
