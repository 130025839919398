import dayjs from "dayjs";

import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import { useLazyGetVasOverviewQuery, useLazyGetVasQuery } from "../billing-api-slice";
import { useLazyGetDashboardParametersQuery } from "@modules/dashboard/simCard/simcards-api-slice";

import { ErrorMessage, Loader } from "../../../../components";
import { SelectBox } from "../../components";
import SimpleTable from "../../components/SimpleTable";
import SubscriptionDetails from "./SubscriptionDetails";

const currentYearMonth = dayjs().format("YYYY-MM");

const columns = [
  {
    Header: "ID",
    accessor: "vas_id",
  },
  {
    Header: "STATE",
    accessor: "state",
  },
  {
    Header: "DESCRIPTION",
    accessor: "product",
  },
  {
    Header: "CREATED",
    accessor: "created",
  },
  {
    Header: "BILLABLE",
    accessor: "billable",
  },
  {
    Header: "DAILY FEE",
    accessor: "daily_fee",
  },
];

const overviewColumns = [
  {
    Header: "ID",
    accessor: "vas_id",
    Cell: ({ value, setSelectedId }) => (
      <span
        className="cursor-pointer btn-link"
        onClick={() => {
          setSelectedId(value);
        }}
      >
        {value}
      </span>
    ),
  },
  {
    Header: "DESCRIPTION",
    accessor: "product",
  },
  {
    Header: "DAYS BILLED",
    accessor: "billed_days",
  },
  {
    Header: "TOTAL COSTS",
    accessor: "cost",
  },
];

const Subscriptions = () => {
  const [getDashboardParams, getDashboardParamsResponse] = useLazyGetDashboardParametersQuery();
  const { months } = getDashboardParamsResponse.data ?? [];

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<string | null>(currentYearMonth);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [getVas, getVasResponse] = useLazyGetVasQuery();
  const vas = getVasResponse?.data ?? {};

  const [getVasOverview, getVasOverviewResponse] = useLazyGetVasOverviewQuery();
  const vasOverview = getVasOverviewResponse?.data ?? {};

  useEffect(() => {
    getVas();
    getDashboardParams();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVasOverview({ params: { month: selectedMonth } });
    // eslint-disable-next-line
  }, [selectedMonth]);

  if (selectedId) {
    return <SubscriptionDetails month={selectedMonth} id={selectedId} handleGoBack={() => setSelectedId(null)} />;
  }

  return (
    <div>
      <div className="d-flex">
        {!getVasResponse.isSuccess ? (
          <Loader />
        ) : (
          <SimpleTable
            title="Subscription Overview"
            data={vas}
            columns={columns}
            hasPerPage={false}
            hasExport={false}
            hasPagination={false}
          />
        )}
      </div>
      <div className="mt-4">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h4 className="card-header-title text-capitalize">Subscription Report</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <small className="form-text text-muted">
              Costs listed here are costs for additional service subscriptions and may exclude taxes and certain service
              costs.
            </small>
            {months && (
              <SelectBox
                defaultValue={{
                  label: "This month",
                  value: currentYearMonth,
                }}
                onChange={({ value }) => setSelectedMonth(value)}
                options={months.map((value) => ({
                  label: value === currentYearMonth ? "This month" : dayjs(value).format("MMMM YYYY"),
                  value,
                }))}
              />
            )}
          </Card.Body>
        </Card>
        <div>
          <SimpleTable
            columns={overviewColumns}
            data={vasOverview?.items || []}
            processing={getVasOverviewResponse.isFetching}
            cellProps={{ setSelectedId }}
            hasPagination={false}
            hasPerPage={false}
          />
        </div>
      </div>
      <ErrorMessage message={errorMessage} onClose={() => setErrorMessage("")} />
    </div>
  );
};

export default Subscriptions;
