import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const OverviewHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="header mb-3">
      <Container fluid>
        <div className="header-body">
          <Row className="align-items-end justify-content-between">
            <Col>
              <h6 className="header-pretitle">Overview</h6>
              <h1 className="header-title">SIM Cards</h1>
            </Col>

            {(location.pathname === "/dashboard/sim-cards" ||
              location.pathname === "/dashboard/sim-cards/") && (
                <Col className="">
                  <Stack direction="horizontal" className="justify-content-end">
                    <Button
                      className="me-2"
                      variant="outline-primary"
                      href="https://store.simbase.com?utm_source=simbase_platform"
                    >
                      Buy SIMs
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/dashboard/activate-sims");
                      }}
                    >
                      Register SIMs
                    </Button>
                  </Stack>
                </Col>
              )}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default OverviewHeader;
