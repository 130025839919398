import { SignIn } from "@modules/auth/signIn";
import { ConfirmAccount, SignUp } from "@modules/auth/signUp";
import { Dashboard } from "@modules/dashboard";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import CustomerIOTracker from "src/lib/utils/CustomerIo/CustomerIoTracker";
import IntercomSnippet from "src/lib/utils/Intercom/intercomSnippet";
import awsConfig from "./aws-exports";
import Version from "./components/Version";
import SentryUserIdentifier from "./lib/utils/Sentry/SentryUserIdentifier";

awsConfig.Auth.oauth.redirectSignIn = `${window.location.origin}/`;
awsConfig.Auth.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsConfig);
Sentry.init({
  environment: import.meta.env.VITE_STAGE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [window.location.origin],
      networkCaptureBodies: true,
    }),
  ],
  tracePropagationTargets: [
    "https://api-dev.simbase.co/*",
    "https://dashboard.simbase.com/*",
    "https://dev.simbase.com/*",
    "https://93uco78518.execute-api.eu-north-1.amazonaws.com/prod/*",
    "https://dev.d1hiq54e6ie404.amplifyapp.com/",
    "https://main.d1hiq54e6ie404.amplifyapp.com/",
  ],
  // Performance Monitoring
  tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function Layout() {
  return (
    <>
      <Outlet />
      <CustomerIOTracker />
      <IntercomSnippet intercomAppId={import.meta.env.VITE_INTERCOM_APP_ID} />
      <SentryUserIdentifier />
      <Version />
    </>
  );
}

const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/auth/*",
        children: [
          {
            path: "signin",
            element: <SignIn />,
          },
          {
            path: "signup",
            element: <SignUp />,
          },
          {
            path: "signup/confirm",
            element: <ConfirmAccount />,
          },
          {
            path: "*",
            element: <Navigate replace to="/auth/signin" />,
          },
        ],
      },
      {
        path: "/dashboard/*",
        element: <Dashboard />,
      },
      {
        path: "*",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
]);

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};
export default App;
