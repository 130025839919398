import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { HOSTNAME, PERMISSIONS } from "@core/constants";

import { checkPermission } from "../../../../lib/utils/permissions";
import { OverviewHeader } from "../../components";
import { Notifications, Organization, Profile, Subscriptions } from "../index";

const tabs = [
  {
    key: "organization",
    label: "Organization",
    permission: PERMISSIONS.VIEW_ACCOUNT_ORGANIZATION,
    showInProd: true,
  },
  {
    key: "subscriptions",
    label: "Subscriptions",
    showInProd: false,
  },
  {
    key: "profile",
    label: "Profile",
    permission: PERMISSIONS.VIEW_ACCOUNT_PROFILE,
    showInProd: true,
  },
  {
    key: "notifications",
    label: "Notifications",
    showInProd: true,
  },
];

export const Account = () => {
  const [activeTab, setActiveTab] = useState(tabs[1].key);
  const location = useLocation();

  useEffect(() => {
    const tab = location?.state?.tab ?? location.pathname.split("/")[2];
    if (tab === tabs[0].key && !checkPermission(PERMISSIONS.VIEW_ACCOUNT_ORGANIZATION)) {
      // Profile tab
      setActiveTab(tabs[2].key);
    } else {
      setActiveTab(tab);
    }
    // eslint-disable-next-line
  }, [location.state]);

  const isTabAllowed = (tab) => {
    const hostname = window?.location?.hostname;

    if (hostname !== HOSTNAME.PRODUCTION) {
      return true;
    }
    return tab.showInProd;
  };

  return (
    <div className="d-flex justify-content-center h-100">
      <div className="d-flex w-75 flex-column mt-5">
        <OverviewHeader title="Account" />
        <div className="px-3">
          <Nav variant="tabs" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
            {tabs
              .filter((tab) => isTabAllowed(tab))
              .filter(({ permission }) => !permission || checkPermission(permission))
              .map(({ key, label }) => (
                <NavItem key={key}>
                  <NavLink eventKey={key}>{label}</NavLink>
                </NavItem>
              ))}
          </Nav>
        </div>
        <div className="py-4 px-2">
          {activeTab === tabs[0].key && isTabAllowed(tabs[0]) && <Organization setTab={setActiveTab} />}
          {activeTab === tabs[1].key && isTabAllowed(tabs[1]) && <Subscriptions />}
          {activeTab === tabs[2].key && isTabAllowed(tabs[2]) && <Profile />}
          {activeTab === tabs[3].key && isTabAllowed(tabs[3]) && <Notifications />}
        </div>
      </div>
    </div>
  );
};

export default Account;
// export default connect(
//   ({ account }) => ({
//     processing: account.processing,
//   }),
//   {}
// )(Account);
