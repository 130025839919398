// import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { PaymentMethod } from "src/types/types";
import {
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  // useUpdateBillingBalanceMutation,
  useUpdatePaymentMethodMutation,
} from "../billing-api-slice";

// Convenience Hook to hide some logic away from the billing overview page

export const usePaymentMethods = ({ onClose }: { onClose?: ({ isOpen }: { isOpen: boolean }) => void } = {}) => {
  // Redux toolkit hooks
  const getPaymentMethodsResponse = useGetPaymentMethodsQuery();
  // const [updateBillingBalance] = useUpdateBillingBalanceMutation();
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();
  const [deletePaymentMethod] = useDeletePaymentMethodMutation();

  // Local State
  const [paymentMethods, setMethods] = useState<PaymentMethod[]>([]);

  //   useEffects
  // useEffect(() => {
  //   /*
  // If there are no more payment methods or no more preferred payment methods, auto_topup is disabled
  // */
  //   (async () => {
  //     if (getPaymentMethodsResponse.isSuccess && paymentMethods.length === 0) {
  //       Sentry.captureMessage(`No payment methods found, disabling auto topup: Payment Methods: ${paymentMethods} -- ${paymentMethods.map((method: PaymentMethod) => method)}`);
  //       await updateBillingBalance({ auto_topup: false });
  //     }
  //   })();
  //   // eslint-disable-next-line
  // }, [paymentMethods]);

  useEffect(() => {
    /*
      This is necessary to re-render the page when the payment methods are pulled from the backend/cache.
      */
    if (getPaymentMethodsResponse?.data?.methods.length > 0) {
      setMethods(() => getPaymentMethodsResponse?.data?.methods ?? []);
    }
  }, [getPaymentMethodsResponse?.data?.methods]);

  const handleUpdatePaymentMethod = async (id: string): Promise<void> => {
    /*
    updatePaymentMethod updates the preferred billing method in the backend. If the update is not successful the operation will fail and no big deal.
    If the operation is successful the id is used to update the preferred payment method showed in the frontend. 
    */
    await updatePaymentMethod({ id });
    setMethods(() =>
      paymentMethods.map((paymentMethod: PaymentMethod) =>
        paymentMethod.card_id === id ? { ...paymentMethod, preferred: true } : { ...paymentMethod, preferred: false },
      ),
    );
    onClose({ isOpen: false });
  };

  const handleDeletePaymentMethod = async (id: string): Promise<void> => {
    await deletePaymentMethod({ id });
    setMethods((paymentMethods: PaymentMethod[]) =>
      paymentMethods.filter((method: PaymentMethod): boolean => method.card_id !== id),
    );
    onClose({ isOpen: false });
  };

  return {
    isPaymentMethodsFetchSuccess: getPaymentMethodsResponse.isSuccess,
    paymentMethods,
    handleUpdatePaymentMethod,
    handleDeletePaymentMethod,
  };
};
