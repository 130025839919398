import { useInterval } from "src/lib/utils/hooks";

import  { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useGetTagsQuery, useLazyGetSimQuery, useUpdateImeiLockMutation } from "../simcards-api-slice";
import {
  useChangeSimNameMutation,
  useGetDashboardParametersQuery,
} from "@modules/dashboard/simCard/simcards-api-slice";

import { GET_SIM_DETAILS_POLL_TIME } from "../../../../core/constants";
import { Overview, PlanAndCoverage, Sms, Usage } from "../index";
import OverviewHeader from "./OverviewHeader";

const tabs = [
  { key: "overview", label: "Overview" },
  { key: "usage", label: "Usage" },
  { key: "sms", label: "SMS" },
  { key: "plan&coverage", label: "Plan & Coverage" },
];

export const Details = () => {
  // UpdateImeiLock
  const [updateImeiLock, updateImeiLockResponse] = useUpdateImeiLockMutation();

  // UpdateSimName
  const [changeSimName] = useChangeSimNameMutation();
  // GetDashboardParams
  const getDashboardParamsResponse = useGetDashboardParametersQuery();
  const { trafficPolicies } = getDashboardParamsResponse.data ?? [];

  const getTagsResponse = useGetTagsQuery();
  const { tags: allTags } = getTagsResponse.data ?? [];

  const [renderTrigger, setRenderTrigger] = useState<number>(0);

  const { iccid } = useParams();

  const [getSim, getSimResponse] = useLazyGetSimQuery();

  const { data: details } = getSimResponse;
  console.log("Details ", details)
  useInterval(() => {
    getSim({ iccid });
  }, GET_SIM_DETAILS_POLL_TIME);

  useEffect(() => {
    if (!getSimResponse.isFetching && getSimResponse.isSuccess) {
      // This will trigger every time a fetch completes successfully
      setRenderTrigger((prev) => prev + 1);
    }
  }, [getSimResponse.isFetching, getSimResponse.isSuccess, getSimResponse]);

  useEffect(() => {
    getSim({ iccid });
  }, []); // getTags //, getSim // getDashboardParameters
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <div className="d-flex flex-column h-100">
      <OverviewHeader />
      <div className="px-3">
        <Nav variant="tabs" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
          {tabs.map(({ key, label }) => (
            <NavItem key={key}>
              <NavLink eventKey={key}>{label}</NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <div className="pt-4 px-2 h-100">
        {getSimResponse.isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <>
            {activeTab === "overview" && (
              <Overview
                allTags={allTags}
                trafficPolicies={trafficPolicies}
                changeSimName={changeSimName}
                imeiLockProcessing={updateImeiLockResponse.isLoading}
                updateImeiLock={updateImeiLock}
                {...details}
                renderTrigger={renderTrigger}
              />
            )}
            {activeTab === "sms" && <Sms allTags={allTags} changeSimName={changeSimName} {...details} iccid={iccid} />}
            {activeTab === "usage" && (
              <Usage allTags={allTags} changeSimName={changeSimName} {...details} iccid={iccid} />
            )}
            {activeTab === "plan&coverage" && (
              <PlanAndCoverage allTags={allTags} changeSimName={changeSimName} {...details} iccid={iccid} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Details;
