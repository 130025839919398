import ErrorMessage from "@components/ErrorMessage";
import { Loader } from "@components/Loader";
import { useLazyGetPlansQuery } from "@modules/dashboard/activateSims/activateSims-api-slice";
import SimpleTable from "@modules/dashboard/components/SimpleTable";
import {
  useChangePlanMutation,
  useGetDashboardParametersQuery,
  useLazyGetPlanQuery,
} from "@modules/dashboard/simCard/simcards-api-slice";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import useIsProcessing from "src/lib/utils/hooks/useIsProcessing";
import { Plan } from "../index";
import ChangePlanModal from "./ChangePlanModal";
import HeaderCards from "./HeaderCards";

interface Carrier {
  carrier: string;
  network: string;
}

interface CountryData {
  carriers: Carrier[];
  continents: string[];
}

const PlanAndCoverage = ({ tags, iccid, allTags, sim_state, device_name, changeSimName, plan_id }) => {
  const getDashboardParamsResponse = useGetDashboardParametersQuery();
  const { radio, regions } = getDashboardParamsResponse.data ?? [];

  // GetPlans
  const [getPlans, getPlansResponse] = useLazyGetPlansQuery();
  const plans = getPlansResponse.data?.plans ?? [];

  // GetPlan
  const [getPlan, getPlanResponse] = useLazyGetPlanQuery();
  const simPlan = getPlanResponse.data?.simPlan ?? {};
  const isSimPlanProcessing = useIsProcessing([
    getPlanResponse.isUninitialized,
    getPlanResponse.isFetching,
    getPlanResponse.isLoading,
  ]);

  // ChangePlan
  const [changePlan] = useChangePlanMutation();

  const [isChangePlanOpen, setIsChangePlanOpen] = useState(false);
  const [changePlanErrorMessage, setChangePlanErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [filters, setFilters] = useState([]);
  const [simPlanProcessing] = useState(true);

  useEffect(() => {
    (() => {
      setFilters([
        {
          name: "Region",
          column: "continents",
          options: regions.map((o) => ({ label: o, value: o })),
          selectedOption: {},
        },
        {
          name: "Radio",
          column: "network",
          options: radio.map((o) => ({ label: o, value: o })),
          selectedOption: {},
        },
      ]);
    })();
  }, [radio, regions]);

  const updateFilter = (key, selectedOption) => {
    setFilters(
      filters.map((filter) => {
        return filter.column === key ? { ...filter, selectedOption } : filter;
      }),
    );
  };

  const resetFilter = () => {
    setFilters(filters.map((filter) => ({ ...filter, selectedOption: {} })));
  };

  const columns = [
    {
      Header: "COUNTRY",
      accessor: "country",
    },
    {
      Header: "REGION",
      accessor: "continents",
      Cell: ({ value }) => <div>{value.join(", ")}</div>,
    },
    {
      Header: "MOBILE NETWORK OPERATOR",
      accessor: "carrier",
    },
    {
      Header: "AVAILABLE RADIO TECHNOLOGIES",
      accessor: "network",
      Cell: ({ value }) => <div>{value.join(", ")}</div>,
    },
  ];

  useEffect(() => {
    (async () => {
      setErrorMessage("");
      await getPlans({ iccid });
      if (plan_id) {
        const response = await getPlan({ plan_id });
        if ("errorMessage" in response) {
          setErrorMessage(response.errorMessage as string);
        }
      }
    })();
  }, [plan_id, getPlans, getPlan, iccid]);
  const handleChangePlan = async ({ plan_id }) => {
    setChangePlanErrorMessage("");
    const response = await changePlan({ iccids: [iccid], plan_id });
    if ("errorMessage" in response) {
      setChangePlanErrorMessage(response.errorMessage as string);
    } else {
      setIsChangePlanOpen(false);
      await getPlan({ plan_id });
    }
  };

  const parseTableData = (data, filters = null) => {
    const result = [];
    const typedData: Record<string, CountryData> = data;
    for (const [country, { carriers, continents }] of Object.entries(typedData)) {
      const continentFilter = filters?.[0]?.selectedOption?.value || null;
      const networkFilter = filters?.[1]?.selectedOption?.value || null;
      if (!continentFilter || continents.includes(continentFilter)) {
        carriers.forEach(({ carrier, network }) => {
          if (!networkFilter || network.includes(networkFilter)) {
            result.push({
              country,
              continents,
              carrier,
              network,
            });
          }
        });
      }
    }
    return result;
  };

  parseTableData(simPlan?.coverage || []);

  return (
    <div className="d-flex flex-column">
      <HeaderCards
        tags={tags}
        iccid={iccid}
        allTags={allTags}
        sim_state={sim_state}
        device_name={device_name}
        changeSimName={changeSimName}
      />
      {!!simPlan && (
        <div className="d-flex">
          <div className="d-flex w-40 pt-4 pe-3">
            <div className="d-flex w-100 h-100">
              {isSimPlanProcessing ? (
                <>
                  <Loader />
                </>
              ) : (
                <Plan handleOpenChangePlan={() => setIsChangePlanOpen(true)} details={simPlan} />
              )}
            </div>
          </div>
          <div className="w-60">
            <div className="d-flex pt-4 ps-3 h-100 ">
              <Card className="w-100">
                <Card.Body>
                  <div className="d-flex h-100">
                    {isSimPlanProcessing ? <Loader /> : <img className="img-fluid" src={simPlan.map} alt="map" />}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      )}
      <div className={`d-flex ${!simPlanProcessing ? "mt-4" : ""}`}>
        <SimpleTable
          hasSearch
          hasExport={false}
          data={parseTableData(simPlan?.coverage || [], filters)}
          columns={columns}
          filters={filters}
          updateFilter={updateFilter}
          resetFilter={resetFilter}
        />
      </div>
      <ChangePlanModal
        plans={plans}
        isOpen={isChangePlanOpen}
        handleClose={() => setIsChangePlanOpen(false)}
        handleComplete={handleChangePlan}
        errorMessage={changePlanErrorMessage}
        currentPlan={simPlan?.plan_id}
      />
      <ErrorMessage message={errorMessage} onClose={() => setErrorMessage("")} />
    </div>
  );
};

export default PlanAndCoverage;
